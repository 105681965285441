<template>
  <div class="sharing-box">
    {{ $t('cs.front.share_on') }}: <br />
    <ShareNetwork
      network="facebook"
      :title="title"
      description=""
      quote=""
      hashtags=""
      :url="url">
      <i class="fa-brands fa-facebook"></i>
    </ShareNetwork>

    <ShareNetwork
      network="twitter"
      :title="title"
      description=""
      quote=""
      hashtags=""
      :url="url">
      <i class="fa-brands fa-twitter"></i>
    </ShareNetwork>

    <ShareNetwork
      network="linkedin"
      :title="title"
      description=""
      quote=""
      hashtags=""
      :url="url">
      <i class="fa-brands fa-linkedin"></i>
    </ShareNetwork>

    <ShareNetwork
      network="telegram"
      :title="title"
      description=""
      quote=""
      hashtags=""
      :url="url">
      <i class="fa-brands fa-telegram"></i>
    </ShareNetwork>

    <ShareNetwork
      network="whatsapp"
      :title="title"
      description=""
      quote=""
      hashtags=""
      :url="url">
      <i class="fa-brands fa-whatsapp"></i>
    </ShareNetwork>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
  computed: {
    url() {
      return document.location.href;
    },
  },
};
</script>

<style>
.sharing-box {
  background-color: white;
  position: fixed;
  bottom: 1px;
  right: 1px;
  padding: 0.5rem;
}
.sharing-box a {
  padding: 0.3rem;
  font-size: 1.7rem;
}
</style>
