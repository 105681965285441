<template>
  <main class="content checkout-page">
    <div class="container">
      <a class="back-link" href="javascript:history.back();">
        <i class="icon icon-arrow-left"></i> {{ $t('cs.front.back') }}
      </a>
      <div v-if="cart && cart.positions" class="row">
        <div class="col-lg-8 mb-4 mb-lg-0">
          <checkout-positions v-if="cart.positions" :positions="cart.positions" />
          <p v-if="!cart.positions.length">{{ $t('cs.front.empty_cart') }}.</p>
        </div>
        <div v-if="cart && cart.positions && cart.positions.length > 0" class="col-lg-4">
          <div class="box summary">
            <checkout-summary :cart="cart" />
            <div class="text-center">
              <router-link
                v-if="auth"
                :to="{ name: 'checkout.final-step' }"
                class="btn btn-green mb-3"
              >
                {{ $t('cs.front.to_checkout') }}
              </router-link>
              <router-link
                v-else
                :to="{ name: 'checkout.anon' }"
                class="btn btn-green mb-3"
              >
                {{ $t('cs.front.to_checkout') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-md-12">
          <p>{{ $t('cs.front.empty_cart') }}.</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CheckoutSummary from './components/CheckoutSummary.vue';
import CheckoutPositions from './components/CheckoutPositions.vue';

export default {
  components: {
    CheckoutSummary,
    CheckoutPositions,
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters({
      me: 'profile/me',
      cart: 'cart/cart',
      shop: 'shop/selected',
      apiToken: 'auth/apiToken',
    }),
    auth() {
      return (this.apiToken && this.me && this.me.id);
    },
  },
  watch: {

  },
  methods: {
    ...mapActions('cart', {
      fetchCart: 'fetch',
    }),
  },
  mounted() {
    if (!this.cart) {
      this.fetchCart();
    }

    this.$store.commit('hideSideCart');
  },
};
</script>
