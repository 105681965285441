<template>
  <div class="search-form" >
    <div class="h4">{{ $t('cs.front.search') }}</div>
    <div
      class="form-group with-icon mb-0"
    >
      <div class="row">
        <div class="col-xs-6 col-md-6">
          <select
            v-if="shop
              && shop.all_products_category_name
              && shop.is_products_displayed
              && shop.first_eo_category_id
            "
            v-model="searchArea"
          >
            <option value="cs">{{ $t('cs.front.club_products') }}</option>
            <option value="eo">{{ $t('cs.front.eo_products') }}</option>
          </select>
        </div>
        <div class="col-xs-6 col-md-6">
          <label for="searchInput">
            <input
              @click="search()"
              @keyup.enter="search()"
              v-model="q"
              id="searchInput"
              type="text"
              :placeholder="$t('cs.front.search_query')"
            />
          </label>

          <button @click="search()" type="submit" class="search-form-button">
            <span class="icon icon-search"></span>
          </button>
        </div>
      </div>
    </div>
    <button class="js-hide-search" id="js-hide-search" type="button">
      <span class="icon icon-close"></span>
    </button>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  components: {

  },
  data() {
    return {
      q: null,
      searchArea: 'cs',
    };
  },
  computed: {
    ...mapGetters({
      shop: 'shop/selected',
    }),
  },
  watch: {

  },
  methods: {
    search() {
      if (this.q) {
        document.getElementById('js-hide-search').click();

        this.$router.push({ name: 'search', params: { q: this.q, searchArea: this.searchArea } });
      }
    },
  },
  mounted() {

  },
};
</script>
