<template>
  <div>
    <template
      v-for="position in positions"
      :key="position.id"
    >
      <div v-if="itemClass === 'checkout-item'" class="checkout-item">
        <router-link
          :to="{ name: 'product', params: { productId: position.product.id }}"
          class="img"
        >
          <img :src="position.product.image" :alt="position.product.number">
        </router-link>
        <div class="checkout-item-inner">
          <div class="desc">
            <router-link
              :to="{ name: 'product', params: { productId: position.product.id }}"
              class="name"
            >
              {{ position.product.name }}
            </router-link>
            <ul class="params">
              <li v-if="position.color">{{ $t('cs.front.color') }}: {{ position.color.name }}</li>
              <li v-if="position.size">{{ $t('cs.front.size') }}: {{ position.size.name }}</li>
            </ul>
          </div>
          <div class="sum">
            <div class="qty-input">
              <label>
                <input
                  v-model="position.quantity"
                  @change="updatePosition(position)"
                  type="number"
                />
                <button
                  @click="positionDecrease(position)"
                  class="qty-minus"
                  type="button"
                >
                  <i class="icon icon-down"></i>
                </button>
                <button
                  @click="positionIncrease(position)"
                  class="qty-plus"
                  type="button"
                >
                  <i class="icon icon-down"></i>
                </button>
              </label>
            </div>
            <button
              @click="deletePosition(position.id)"
              class="btn btn-icon"
              type="button"
            >
              <span class="icon icon-sm icon-remove"></span>
            </button>
            <div class="price">
              <template v-if="position.product.display_rrp && position.price !== position.originalPrice">
                <div class="red">
                  {{ $filters.priceFormat(position.price, this.shop.currency) }}
                </div>
                <div class="crossed">
                  {{ $filters.priceFormat(position.originalPrice, this.shop.currency) }}
                </div>
              </template>
              <template v-else>
                <div class="normal">
                  {{ $filters.priceFormat(position.price, this.shop.currency) }}
                </div>
              </template>
            </div>
          </div>
          <div v-if="position.options.length" class="cart-position-options">
            <div class="positions-title">
              <u>{{ $t('cs.front.cart_position_options_title') }}</u>
            </div>
            <div v-for="option in position.options"
              :key="option.id"
              class="row w-75 position-option-row"
            >
              <div class="col-8 option-name">
                {{ option.name }}
                <span v-if="option.custom_text">({{ option.custom_text }})</span>
              </div>
              <div class="col-4 p-0 text-right option-price">
                <s
                  v-if="option.old_price"
                  style="color: grey;"
                >
                  {{ $filters.priceFormat(option.old_price, this.shop.currency) }}
                </s>
                <span :style="{color: option.old_price ? 'red' : black}">
                  &nbsp; {{ $filters.priceFormat(option.price, this.shop.currency) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="cart-sidebar-item">
        <router-link
          :to="productRoute(position)"
          class="img"
        >
          <img :src="position.product.image" :alt="position.product.number">
        </router-link>
        <div class="desc">
          <router-link
            :to="productRoute(position)"
            class="name"
          >
            {{ position.product.name }}
          </router-link>
          <ul class="params">
            <li v-if="position.color">{{ $t('cs.front.color') }}: {{ position.color.name }}</li>
            <li v-if="position.size">{{ $t('cs.front.size') }}: {{ position.size.name }}</li>
          </ul>
          <div class="sum">
            <div class="qty-input">
              <label>
                <input
                  v-model="position.quantity"
                  @change="updatePosition(position)"
                  type="number"
                />
                <button
                  @click="positionDecrease(position)"
                  class="qty-minus"
                  type="button"
                >
                  <i class="icon icon-down"></i>
                </button>
                <button
                  @click="positionIncrease(position)"
                  class="qty-plus"
                  type="button"
                >
                  <i class="icon icon-down"></i>
                </button>
              </label>
            </div>
            <div class="price">
              <template v-if="position.product.display_rrp && position.price !== position.originalPrice">
                <div class="red">
                  {{ $filters.priceFormat(position.price, this.shop.currency) }}
                </div>
                <div class="crossed">
                  {{ $filters.priceFormat(position.originalPrice, this.shop.currency) }}
                </div>
              </template>
              <template v-else>
                <div class="normal">
                  {{ $filters.priceFormat(position.price, this.shop.currency) }}
                </div>
              </template>
            </div>
          </div>

          <div class="cart-position-options">
            <div
              v-for="imageType in shop.active_image_types"
              :key="imageType.id"
            >
              <template
                v-if="position.options
                  .filter(o => o.product_image_type_id === imageType.id).length">
                <div class="positions-title">
                  <u>{{ $t('cs.labels.product_image_type_' + imageType.name) }}</u>
                </div>
                <div
                  v-for="option in position.options
                  .filter(o => o.product_image_type_id === imageType.id)"
                  :key="option.id"
                  class="row position-option-row"
                >
                  <div class="col-8 option-name">
                    {{ option.name }}
                    <span v-if="option.custom_text">({{ option.custom_text }})</span>
                  </div>
                  <div class="col-4 p-0 text-right option-price">
                    <s
                      v-if="option.old_price"
                      style="color: grey;"
                    >
                      {{ $filters.priceFormat(option.old_price, this.shop.currency) }}
                    </s>
                    <span :style="{color: option.old_price ? 'red' : black}">
                      &nbsp; {{ $filters.priceFormat(option.price, this.shop.currency) }}
                    </span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <button
          @click="deletePositionWrapper(position.id)"
          class="btn btn-icon"
          type="button"
        >
          <span class="icon icon-sm icon-remove"></span>
        </button>

      </div>
    </template>
  </div>
</template>

<style>
.cart-position-options {
  font-size: 12px;
  flex-basis: 100%;
  max-width: 300px;
}
.checkout-item-inner {
  flex-wrap: wrap;
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {

  },
  props: {
    positions: Array,
    itemClass: {
      type: String,
      default: 'checkout-item',
    },
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters({
      shop: 'shop/selected',
    }),
  },
  watch: {

  },
  methods: {
    ...mapActions('cart', {
      deletePosition: 'deletePosition',
      updatePosition: 'updatePosition',
    }),
    positionIncrease(position) {
      this.$store.commit('showLoader');
      // eslint-disable-next-line no-param-reassign
      position.quantity += 1;
      return this.updatePosition(position).finally(() => this.$store.commit('hideLoader'));
    },
    positionDecrease(position) {
      this.$store.commit('showLoader');
      // eslint-disable-next-line no-param-reassign
      position.quantity = (position.quantity > 1) ? position.quantity - 1 : 1;
      return this.updatePosition(position).finally(() => this.$store.commit('hideLoader'));
    },
    deletePositionWrapper(id) {
      this.$store.commit('showLoader');
      return this.deletePosition(id).finally(() => this.$store.commit('hideLoader'));
    },
    productRoute(position) {
      if (position.type === 'CS') {
        return {
          name: 'product',
          params: { productId: position.product.id },
        };
      }

      return {
        name: 'eo-product',
        params: { eoProductId: position.product.id },
      };
    },
  },
  mounted() {

  },
};
</script>
