<template>
  <nav>
    <div v-if="showMobileMenu && isMobile" class="menu-backdrop"></div>
    <ul v-if="showMobileMenu || !isMobile" class="menu">
      <li class="menu-header" v-if="showMobileMenu">
        <router-link v-if="shop" :to="{ name: 'home' }">
          <img
            v-if="shop.club.logo"
            :src="`${staticHost}/${shop.club.logo}`"
            :alt="shop.name"
          >
        </router-link>
        <div>{{ $t('cs.front.menu') }}</div>
        <button @click="showMobileMenu = false" class="menu-toggle" type="button">
          <span class="icon icon-close"></span>
        </button>
      </li>
      <li class="menu-body">
        <ul>
          <li
            v-for="category in categories"
            :key="category.id"
          >
            <router-link
              v-if="category.show_at_header"
              :to="{ name: 'category', params: { categoryId: category.slug }}"
              class="menu-link"
            >
              {{ category.name }}
            </router-link>

            <div v-if="category.show_at_header" class="dropdown-box">
              <div class="dropdown-top">
                <router-link v-if="shop" :to="{ name: 'home' }">
                  <img
                    v-if="shop.club.logo"
                    :src="`${staticHost}/${shop.club.logo}`"
                    :alt="shop.name"
                  >
                </router-link>
                <div>{{ category.name }}</div>
                <button class="dropdown-close" type="button">
                  <span class="icon icon-close"></span>
                </button>
              </div>
            </div>

            <ul
              class="main-menu-subcategories"
              v-if="isMobile && category.subcategories && category.subcategories.length"
            >
              <li v-for="subcat in category.subcategories" :key="subcat.id">
                <router-link
                  :to="{ name: 'category', params: { categoryId: subcat.slug }}"
                >
                  {{ subcat.name }}
                </router-link>
              </li>
            </ul>

          </li>
          <li
            v-if="shop
              && categories
              && categories.length > 0
              && shop.all_products_category_name
              && shop.is_products_displayed
              && shop.first_eo_category_id
            "
          >
            <router-link
              :to="{ name: 'eo-products', params: {eoCategoryId: shop.first_eo_category_id} }"
              class="menu-link"
            >
              {{ shop.all_products_category_name }}
            </router-link>

            <ul
              class="main-menu-subcategories"
              v-if="isMobile && shop.eo_categories && shop.eo_categories.length"
            >
              <li v-for="subcat in shop.eo_categories" :key="subcat.id">
                <router-link
                  :to="{ name: 'eo-products', params: { eoCategoryId: subcat.id }}"
                >
                  {{ subcat.name }}
                </router-link>
              </li>
            </ul>
          </li>
          <li
            v-if="shop
              && shop.custom_menu
              && shop.custom_menu.active
              && shop.custom_menu.link
              && shop.custom_menu.anchor
            "
            style="border-left: 2px solid #ccc; padding-left: 1rem;"
          >
            <a
              :href="shop.custom_menu.link"
              class="menu-link"
              target="_blank"
              style="color: #213321;"
            >
              {{ shop.custom_menu.anchor }}
            </a>
          </li>
        </ul>
      </li>
    </ul>

    <ul class="toolbar">
      <li ref="side_cart_icon">
        <a class="toolbar-item js-show-search" href="#">
          <span class="icon icon-search"></span>
        </a>
      </li>
      <li>
        <router-link
          :to="{ name: 'profile.index' }"
          class="toolbar-item"
        >
          <span class="icon icon-profile"></span>
        </router-link>
      </li>
      <li>
        <a @click="$store.commit('showSideCart')" href="#" class="toolbar-item">
          <span class="icon icon-cart"></span>
          <span
            v-if="cart && cart.positions"
            class="cart-count"
          >
            {{ cart.positions.length }}
          </span>
          <span v-else class="cart-count"> 0 </span>
        </a>
      </li>
      <li>
        <a @click="showMobileMenu = true" class="toolbar-item menu-toggle" href="#">
          <span class="icon icon-menu"></span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {

  },
  data() {
    return {
      showMobileMenu: false,
      isMobile: false,
      screenWidth: 0,
    };
  },
  computed: {
    ...mapGetters({
      shop: 'shop/selected',
      categories: 'category/list',
      cart: 'cart/cart',
      staticHost: 'staticHost',
    }),
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.params.categoryId': function () {
      this.showMobileMenu = false;
    },
    '$route.params.eoCategoryId': function () {
      this.showMobileMenu = false;
    },
  },
  methods: {
    ...mapActions('category', {
      fetchCategories: 'fetch',
    }),
    ...mapActions('cart', {
      fetchCart: 'fetch',
    }),
    setAdaptive() {
      // eslint-disable-next-line no-restricted-globals
      this.screenWidth = window.innerWidth;

      this.isMobile = (this.screenWidth < 992);
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchCart();

    this.setAdaptive();

    window.addEventListener('resize', () => {
      this.setAdaptive();
    });
  },
};
</script>
