import axios from 'axios';

export default {
  fetch(context, filters = {}) {
    return axios.get(
      `${context.rootState.apiHost}/club-shop/app/categories/?fetch_type=TREE`,
      { params: filters },
    ).then((response) => {
      context.commit('setList', response.data);
    });
  },
  select(context, id) {
    if (!id) {
      context.commit('select', null);

      return false;
    }

    return axios.get(`${context.rootState.apiHost}/club-shop/app/categories/${id}/`).then((response) => {
      context.commit('select', response.data);

      return response;
    });
  },
};
