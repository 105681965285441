import axios from 'axios';

export default {
  fetchMe(context) {
    return axios.get(
      `${context.rootState.apiHost}/club-shop/app/profile/`,
    ).then((response) => {
      context.commit('setMyProfile', response.data);
    }).catch(() => {
      context.commit('setMyProfile', null);
    });
  },
  resetMe(context) {
    context.commit('setMyProfile', null);
  },
  saveMe(context, data) {
    return axios.put(
      `${context.rootState.apiHost}/club-shop/app/profile/`,
      data,
    ).then((response) => {
      context.commit('setMyProfile', response.data);

      return response;
    });
  },
  setNewPassword(context, data) {
    return axios.put(
      `${context.rootState.apiHost}/club-shop/app/profile/password/`,
      data,
    ).then((response) => response);
  },
  fetchOrders(context) {
    return axios.get(
      `${context.rootState.apiHost}/club-shop/app/profile/orders`,
    ).then((response) => {
      context.commit('setMyOrders', response.data);
    });
  },
};
