export default {
  setList: (state, list) => {
    state.list = list;
  },
  setModelList: (state, list) => {
    state.modelList = list;
  },
  select: (state, item) => {
    state.selected = item;
  },
};
