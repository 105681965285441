export default {
  selectDetails: (state, item) => {
    state.selectedDetails = item;
  },
  setPaymentStatus: (state, status) => {
    state.paymentStatus = status;
  },
  select: (state, data) => {
    state.selected = data;
  },
};
