<template>
  <main class="content checkout-page">
    <div class="container">
      <div
        v-if="shop && transactionDetails && transactionDetails.qr && !showLoader"
        class="checkout-payment-process"
        style="text-align: center;"
      >
        <p>
          <span style="font-size: 22px;">🛈</span>
          {{ $t('cs.front.scan_qr_using_app_and_wait_up_to_1_min') }}
        </p>
        <img
          :src="transactionDetails.qr"
          alt="QR"
          class="fade-in"
          style="width: 90px;"
        />
        <p>{{ $t('cs.front.payment_token') }}: {{ transactionDetails.token }}</p>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { useRoute } from 'vue-router';
import * as paymentMethodTypes from '../../../consts/payment_method';

export default {
  components: {

  },
  data() {
    return {
      paymentMethodTypes,
      checkInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      shop: 'shop/selected',
    }),
    ...mapGetters({
      transactionDetails: 'transaction/selectedDetails',
      paymentStatus: 'transaction/paymentStatus',
    }),
    ...mapGetters({
      showLoader: 'showLoader',
    }),
  },
  watch: {

  },
  methods: {
    ...mapActions('transaction', {
      fetchPaymentTransactionDetails: 'fetchTxDetails',
      processPayment: 'processPayment',
    }),
  },
  mounted() {
    const route = useRoute();

    this.processPayment(route.params.txId).then(() => {
      if (this.paymentStatus) {
        this.$router.push({ name: 'checkout.thanks' });
      }
    });

    this.$store.commit('showLoader');
    if (route.params.txId) {
      this.fetchPaymentTransactionDetails(route.params.txId).then(() => {
        this.checkInterval = setInterval(() => {
          if (route.params.txId) {
            this.processPayment(route.params.txId).then(() => {
              if (this.paymentStatus) {
                clearInterval(this.checkInterval);
                this.$router.push({ name: 'checkout.thanks' });
              }
            });
          }
        }, 15000);
      }).finally(() => {
        this.$store.commit('hideLoader');
      });
    }

    this.$store.commit('hideSideCart');
  },
  beforeUnmount() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  },
};
</script>
