import axios from 'axios';

export default {
  select(context, id) {
    if (!id) {
      context.commit('select', null);

      return false;
    }

    return axios.get(`${context.rootState.apiHost}/club-shop/app/transactions/${id}/`).then((response) => {
      context.commit('select', response.data);

      return response;
    });
  },
  fetchTxDetails(context, id) {
    return axios.get(`${context.rootState.apiHost}/club-shop/app/cart/payment/transaction-details/${id}`)
      .then((response) => {
        context.commit('selectDetails', response.data);

        return response;
      });
  },
  processPayment(context, id) {
    return axios.get(`${context.rootState.apiHost}/club-shop/app/cart/payment/process-payment/${id}`)
      .then((response) => {
        context.commit('setPaymentStatus', response.data.success);

        return response;
      });
  },
};
