export const STATUS_NEW = 1;
export const STATUS_IN_PROCESS = 2;
export const STATUS_PICKING = 3;
export const STATUS_SENT_OUT = 4;
export const STATUS_SMU_PROCESS = 5;
export const STATUS_READY_FOR_PICKUP = 6;
export const STATUS_DONE = 7;
export const STATUS_CANCELED = 8;
export const STATUS_SHIPPED = 9;

export const statuses = [
  { value: STATUS_NEW, name: 'STATUS_NEW', cssClass: 'red' },
  { value: STATUS_IN_PROCESS, name: 'STATUS_IN_PROCESS', cssClass: 'blue' },
  { value: STATUS_PICKING, name: 'STATUS_PICKING', cssClass: 'blue' },
  { value: STATUS_SENT_OUT, name: 'STATUS_SENT_OUT', cssClass: 'green-light' },
  { value: STATUS_SMU_PROCESS, name: 'STATUS_SMU_PROCESS', cssClass: 'green-light' },
  { value: STATUS_READY_FOR_PICKUP, name: 'STATUS_READY_FOR_PICKUP', cssClass: 'green-light' },
  { value: STATUS_DONE, name: 'STATUS_DONE', cssClass: 'green' },
  { value: STATUS_CANCELED, name: 'STATUS_CANCELED', cssClass: '' },
  { value: STATUS_SHIPPED, name: 'STATUS_SHIPPED', cssClass: 'green-light' },
];
