export default {
  setList: (state, list) => {
    state.list = list;
  },
  select: (state, item) => {
    state.selected = item;
  },
  setPreUploadedFile: (state, preUploadedFile) => {
    state.preUploadedFile = preUploadedFile;
  },
};
