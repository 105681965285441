import { createStore } from 'vuex';
import product from './modules/product/index';
import eoProduct from './modules/eoProduct/index';
import category from './modules/category/index';
import eoCategory from './modules/eoCategory/index';
import footerBlock from './modules/footerBlock/index';
import auth from './modules/auth/index';
import profile from './modules/profile/index';
import address from './modules/address/index';
import shop from './modules/shop/index';
import contentBlock from './modules/contentBlock/index';
import cart from './modules/cart/index';
import page from './modules/page/index';
import order from './modules/order/index';
import orderFile from './modules/orderFile/index';
import transaction from './modules/transaction/index';

export default createStore({
  state: {
    apiHost: (localStorage.getItem('api-host')) ? localStorage.getItem('api-host') : process.env.VUE_APP_API_URL,
    staticHost: process.env.VUE_APP_STATIC_URL,
    shopId: 1,
    showSideCart: false,
    showLoader: false,
  },
  getters: {
    apiHost: (state) => state.apiHost,
    staticHost: (state) => state.staticHost,
    showSideCart: (state) => state.showSideCart,
    showLoader: (state) => state.showLoader,
  },
  mutations: {
    showSideCart: (state) => {
      setTimeout(() => {
        state.showSideCart = true;
      }, 100);
    },
    hideSideCart: (state) => {
      state.showSideCart = false;
    },
    showLoader: (state) => {
      state.showLoader = true;
    },
    hideLoader: (state) => {
      state.showLoader = false;
    },
    setApiHost: (state, value) => {
      localStorage.setItem('api-host', value);
      state.apiHost = value;
    },
  },
  actions: {},
  modules: {
    category,
    eoCategory,
    product,
    eoProduct,
    footerBlock,
    profile,
    auth,
    address,
    shop,
    contentBlock,
    cart,
    page,
    order,
    orderFile,
    transaction,
  },
});
