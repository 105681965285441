<template>
  <main class="content profile-page">
    <div v-if="profile" class="container">
      <div class="row">
        <div class="col-md-4 col-lg-3">
          <profile-side-menu
            :user-email="profile.email"
            :user-full-name="profile.name+' '+profile.surname"
          />
        </div>
        <div class="col-md-8 col-lg-9">
          <div class="profile-content">
            <div class="row">
              <div class="col-xl-4">
                <div class="box box-personal">
                  <div class="box-header">
                    <i class="icon icon-address"></i>
                    {{ $t('cs.front.personal_data') }}
                  </div>
                  <div class="box-body">
                    <div class="row">
                      <div class="col-md-2 col-xl-12">
                        <div class="form-group">
                          <label for="profile-salutation">
                            <select v-model="profile.salutation" id="profile-salutation">
                              <option value="M">{{ $t('cs.front.salutation_mr') }}</option>
                              <option value="W">{{ $t('cs.front.salutation_miss') }}</option>
                              <option value="NA">{{ $t('cs.front.salutation_na') }}</option>
                            </select>
                          </label>
                        </div>
                      </div>
                      <div class="col-md-5 col-xl-12">
                        <div class="form-group">
                          <label for="profile-name-1">
                            <input
                              v-model="profile.name"
                              id="profile-name-1"
                              type="text"
                              :placeholder="$t('cs.front.first_name') + ' * '"
                            >
                          </label>
                        </div>
                      </div>
                      <div class="col-md-5 col-xl-12">
                        <div class="form-group">
                          <label for="profile-name-2">
                            <input
                              v-model="profile.surname"
                              id="profile-name-2"
                              type="text"
                              :placeholder="$t('cs.front.surname') + ' * '"
                            >
                          </label>
                        </div>
                      </div>
                      <div v-if="profile.type && profile.type.toUpperCase() === 'C'"
                          class="col-md-12 col-xl-12"
                          >
                        <div class="form-group">
                          <label for="profile-company-name">
                            <input
                              v-model="profile.company_name"
                              id="profile-company-name"
                              type="text"
                              :placeholder="$t('cs.front.company_name')"
                            >
                          </label>
                        </div>
                      </div>
                      <div class="col-md-6 col-xl-12">
                        <div class="form-group">
                          <label for="profile-company-name">
                            <input
                              v-model="profile.phone"
                              id="profile-phone"
                              type="text"
                              :placeholder="$t('cs.front.phone')"
                            >
                          </label>
                          <div class="helptext">* {{ $t('cs.front.this_field_is_required') }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="text-xl-center">
                      <button
                        @click="saveProfile()"
                        class="btn btn-small"
                        type="button"
                      >
                        {{ $t('cs.front.save') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-8">
                <div class="box">
                  <div class="box-header">
                    <i class="icon icon-email"></i> {{ $t('cs.front.email') }}
                  </div>
                  <div class="box-body">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="profile-email">
                            <input
                              v-model="profile.email"
                              id="profile-email"
                              type="text"
                              :placeholder="$t('cs.front.new_email')"
                            >
                          </label>
                          <div class="helptext">* {{ $t('cs.front.this_field_is_required') }}</div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="profile-email-2">
                            <input
                              v-model="emailRepeat"
                              id="profile-email-2"
                              type="text"
                              :placeholder="$t('cs.front.email_repeat')"
                            >
                          </label>
                        </div>
                      </div>
                    </div>
                    <button
                      @click="saveEmail()"
                      class="btn btn-small"
                      type="button"
                    >
                      {{ $t('cs.front.save') }}
                    </button>
                  </div>
                </div>
                <div class="box">
                  <div class="box-header">
                    <i class="icon icon-password"></i> {{ $t('cs.front.password') }}
                  </div>
                  <div class="box-body">
                    <div class="row">
                      <div class="col-lg-12">
                        <label for="current-password">
                          {{ $t('cs.front.current_password') }}
                          <input
                            v-model="currentPassword"
                            id="current-password"
                            type="password"
                            :placeholder="$t('cs.front.current_password') + ' *'"
                          >
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="profile-password">
                            <input v-model="newPassword"
                                   id="profile-password"
                                   type="password"
                                   :placeholder="$t('cs.front.password_new') + ' *'"
                            >
                          </label>
                          <div
                            class="helptext"
                            style="position: absolute; width: 300px;"
                          >
                            * {{ $t('cs.front.this_field_is_required') }}
                          </div>
                          <br /><br />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="profile-password-2">
                            <input
                              v-model="newPasswordRepeat"
                              id="profile-password-2"
                              type="password"
                             :placeholder="$t('cs.front.password_required') + ' *'"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <button @click="changePassword()" class="btn btn-small" type="button">
                      {{ $t('cs.front.save') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProfileSideMenu from './components/ProfileSideMenu.vue';
import * as pageTypes from '../../../consts/page';

export default {
  components: {
    ProfileSideMenu,
  },
  data() {
    return {
      profile: null,
      emailRepeat: null,
      currentPassword: null,
      newPassword: '',
      newPasswordRepeat: null,
      pageTypes,
    };
  },
  computed: {
    ...mapGetters({
      me: 'profile/me',
    }),
  },
  watch: {
    me(newVal) {
      this.profile = newVal;
    },
  },
  methods: {
    ...mapActions('profile', {
      fetchMe: 'fetchMe',
      saveMe: 'saveMe',
      setNewPassword: 'setNewPassword',
    }),
    saveEmail() {
      if (this.emailRepeat !== this.profile.email) {
        this.$toast.error(this.$t('cs.front.emails_are_not_matched'));
        return false;
      }

      return this.saveMe(this.profile).then(() => {
        this.$toast.success(this.$t('cs.front.saved_alert'));
        this.modalPAopened = false;
      });
    },
    saveProfile() {
      return this.saveMe(this.profile).then(() => {
        this.$toast.success(this.$t('cs.front.saved_alert'));
        this.modalPAopened = false;
      });
    },
    changePassword() {
      if (this.newPassword !== this.newPasswordRepeat) {
        this.$toast.error(this.$t('cs.front.passwords_are_not_matched'));
        return false;
      }

      if (this.newPassword.length < 8) {
        this.$toast.error(this.$t('cs.front.min_password_symbols'));
        return false;
      }

      return this.setNewPassword({ old: this.currentPassword, new: this.newPassword }).then(() => {
        this.$toast.success(this.$t('cs.front.saved_alert'));
        this.modalPAopened = false;
      });
    },
  },
  mounted() {
    this.profile = this.me;
    this.fetchMe().finally(() => {
      if (!this.me || !this.me.id) {
        this.$router.push({ name: 'auth' });
      }
    });
  },
};
</script>
