<template>
  <main class="content gray-bg login-page">
    <div class="container">
      <a class="back-link" href="javascript:history.back();">
        <i class="icon icon-arrow-left"></i> {{ $t('cs.front.back') }}
      </a>
      <auth-form />
    </div>
  </main>
</template>

<script>
import AuthForm from '@/views/common/AuthForm.vue';

export default {
  components: { AuthForm },
};
</script>
