<template>
  <main class="content container text-page">
    <div class="container" v-if="page">
      <a class="back-link" href="javascript:history.back();">
        <i class="icon icon-sm icon-arrow-left"></i> {{ $t('cs.front.back') }}
      </a>
      <section class="page-view">
        <h1>{{ page.name }}</h1>

        <div v-html="page.content"></div>
      </section>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { useRoute } from 'vue-router';

export default {
  components: {

  },
  data() {
    return {
      url: null,
    };
  },
  computed: {
    ...mapGetters({
      page: 'page/selected',
    }),
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.params.url': function (newUrl) {
      if (newUrl) {
        this.id = parseInt(newUrl, 10);
        this.selectPage(this.id);

        window.scrollTo(0, 0);
      }
    },
  },
  methods: {
    ...mapActions('page', {
      selectPage: 'selectByUrl',
    }),
  },
  mounted() {
    const route = useRoute();
    if (route.params.url) {
      this.url = route.params.url;
      this.selectPage(this.url);

      window.scrollTo(0, 0);
    }
  },
};
</script>
