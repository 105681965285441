import jQuery from 'jquery';

jQuery(document).ready(() => {
  /* Menu */
  jQuery(document).on('click', '.dropdown-close', (e) => {
    e.preventDefault();
    jQuery('.dropdown').removeClass('is-open');
  });

  // Header search
  jQuery(document).on('click', '.js-show-search', () => {
    jQuery('.search').addClass('is-open').find('input').focus();
  });

  jQuery(document).on('click', '.js-hide-search', () => {
    jQuery('.search').removeClass('is-open');
  });

  // Profile menu toggle
  jQuery(document).on('click', '.profile-menu-toggler', () => {
    jQuery(this).toggleClass('is-open');
    jQuery('.profile-menu-item:not(.is-active)').slideToggle();
  });

  jQuery(document).on('click', '.footer-title', function () {
    jQuery(this).toggleClass('is-open');
    jQuery(this).next('.footer-submenu').slideToggle();
  });
});
