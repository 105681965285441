import axios from 'axios';

export default {
  preUpload(context, data) {
    const formData = new FormData();
    Object.keys(data).forEach((a) => a === 'fileToUpload'
      || (data[a] && formData.append(a, JSON.stringify(data[a]))));
    formData.append('file', data.fileToUpload);

    return axios.post(
      `${context.rootState.apiHost}/club-shop/app/cart/file-preupload/`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    ).then((response) => {
      context.commit('setPreUploadedFile', response.data);

      localStorage.setItem('preUploadedOrderFile', JSON.stringify(response.data));

      return response;
    });
  },
  clearPreUploadedFile(context) {
    context.commit('setPreUploadedFile', null);
    localStorage.removeItem('preUploadedOrderFile');
  },
  setPreUploadFile(context, data) {
    context.commit('setPreUploadedFile', data);

    localStorage.setItem('preUploadedOrderFile', JSON.stringify(data));
  },
};
