<template>
  <main class="content profile-page" v-if="profile">
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-lg-3">
          <profile-side-menu v-if="me"
              :user-email="profile.email"
              :user-full-name="profile.name+' '+profile.surname" />
        </div>
        <div class="col-md-8 col-lg-9">
          <div class="profile-content">
            <div class="d-none d-md-block">
              <p class="profile-title">
                {{ $t('cs.front.wellcome') }},
                {{ profile.name }} {{ profile.surname }}
              </p>
              <p class="profile-text">
                {{ $t('cs.front.profile_index_note') }}
              </p>
            </div>
            <div class="row">
              <div class="col-lg-6 col-xl-4">
                <div class="box">
                  <div class="box-header">
                    <i class="icon icon-profile-edit"></i> {{ $t('cs.front.personal_data') }}
                  </div>
                  <div class="box-text">
                    <div>{{ profile.name }} {{ profile.surname }}</div>
                    <div class="grey">{{ profile.email }}</div>
                  </div>
                  <router-link
                    :to="{ name: 'profile.settings' }"
                    class="btn btn-small"
                  >
                    {{ $t('cs.front.change') }}
                  </router-link>
                </div>
              </div>
              <div class="col-lg-6 col-xl-8">
                <div class="box">
                  <div class="box-header">
                    <i class="icon icon-orders"></i> {{ $t('cs.front.newsletter') }}
                  </div>
                  <div class="checkbox">
                    <input
                      v-model="profile.has_newsletter"
                      @change="saveProfile()"
                      value="1"
                      id="newsletter"
                      type="checkbox"
                    >
                    <label class="profile-newsletter" for="newsletter">
                      {{ $t('cs.front.newsletter_note') }}
                      <br>
                      <router-link
                        :to="{ name: 'info', params: { type: pageTypes.TYPE_PRIVACY }}"
                        class="small under blue"
                      >
                        {{ $t('cs.front.privacy_policy') }}
                      </router-link>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-xl-4">
                <div class="box">
                  <div class="box-header">
                    <i class="icon icon-address"></i> {{ $t('cs.front.primary_billing_address') }}
                  </div>
                  <div class="box-text" v-if="profile.billing_address">
                    <template v-if="profile.type && profile.type.toUpperCase() === 'C'">
                      {{ profile.billing_address.company_name }}<br>
                    </template>
                    {{ profile.billing_address.name }} {{ profile.billing_address.surname }}<br>
                    {{ profile.billing_address.address }}<br>
                    {{ profile.billing_address.zip }} {{ profile.billing_address.city }}<br />
                    {{ profile.billing_address.country }}
                  </div>
                  <div class="box-btns">
                    <button
                      @click="modalBAopened=true"
                      class="btn btn-small"
                      type="button"
                    >
                      {{ $t('cs.front.edit') }}
                    </button>
                    <router-link
                      :to="{ name: 'profile.addresses' }"
                      class="btn btn-small btn-black px-3"
                    >
                      {{ $t('cs.front.change') }}
                    </router-link>
                  </div>
                </div>
              </div>
              <div v-if="shop.last_order_address" class="col-lg-6 col-xl-4">
                <div class="box">
                  <div class="box-header">
                    <i class="icon icon-address"></i>
                    <template
                      v-if="!shop.last_order_address.type
                      || shop.last_order_address.type === 'pickup'"
                    >
                      {{ $t('cs.front.pickup_address') }}
                    </template>
                    <template v-else>
                      {{ $t('cs.front.shipping_address') }}
                    </template>
                  </div>
                  <div class="box-text" v-if="shop && shop.last_order_address">
                    {{ shop.last_order_address.name_1 }}<br>
                    {{ shop.last_order_address.name_2 }}<br>
                    {{ shop.last_order_address.address }}<br>
                    {{ shop.last_order_address.zip }} {{ shop.last_order_address.city }}<br>
                    {{ shop.last_order_address.country }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-xl-4">
                <div class="box">
                  <div class="box-header">
                    <i class="icon icon-alert"></i> {{ $t('cs.front.payment_method') }}
                  </div>
                  <div class="box-tex">
                    <template v-if="shop && shop.last_payment_method">
                      <span v-if="shop.last_payment_method.name_i18n">
                        {{ $t(shop.last_payment_method.name_i18n) }}
                      </span>
                      <span v-else>{{ shop.last_payment_method.name }}</span>
                    </template>
                    <template v-else>
                      {{ $t('cs.front.payment_method_cash') }}
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

  <div
    v-if="me"
    class="popups-layout"
  >
    <div v-if="modalBAopened"
         class="modal show in" id="modalPrimaryAddress" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <button
            @click="modalBAopened = false"
            type="button"
            class="close"
            data-dismiss="modal"
          >
            <i class="icon icon-close"></i>
          </button>
          <div class="modal-body px-lg-5">
            <div class="h3">{{ $t('cs.front.billing_address') }}</div>
            <template v-if="profile.type && profile.type.toUpperCase() === 'C'">
                <p class="h5">{{ $t('cs.front.company_name') }}</p>
                <div class="form-group">
                  <input
                    v-model="me.billing_address.company_name"
                    type="text"
                  />
                </div>
            </template>
            <p class="h5">{{ $t('cs.front.first_name') }}</p>
            <div class="form-group">
              <input
                v-model="me.billing_address.name"
                type="text"
              />
            </div>

            <p class="h5">{{ $t('cs.front.surname') }}</p>
            <div class="form-group">
              <input
                v-model="me.billing_address.surname"
                type="text"
              />
            </div>

            <p class="h5">{{ $t('cs.front.street_and_apartment') }}</p>

            <div class="form-group">
              <input
                v-model="me.billing_address.address"
                type="text"
              />
            </div>

            <p class="h5">{{ $t('cs.front.zip') }}*</p>
            <div class="form-group">
              <input
                v-model="me.billing_address.zip"
                type="text"
              />
            </div>

            <p class="h5">{{ $t('cs.front.city') }}*</p>
            <div class="form-group">
              <input
                v-model="me.billing_address.city"
                type="text"
              />
            </div>

            <button
              @click="saveBillingAddress()"
              class="btn btn-small"
              type="button"
            >
              {{ $t('cs.front.save') }}
            </button>
          </div>
        </div>
        <div class="col-lg-7">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProfileSideMenu from './components/ProfileSideMenu.vue';
import * as pageTypes from '../../../consts/page';

export default {
  components: {
    ProfileSideMenu,
  },
  data() {
    return {
      modalBAopened: false,
      profile: null,
      pageTypes,
    };
  },
  computed: {
    ...mapGetters({
      me: 'profile/me',
      shop: 'shop/selected',
    }),
  },
  watch: {
    me(newVal) {
      this.profile = newVal;
    },
  },
  methods: {
    ...mapActions('profile', {
      fetchMe: 'fetchMe',
      saveMe: 'saveMe',
    }),
    ...mapActions('address', {
      saveAddress: 'save',
    }),
    saveProfile() {
      return this.saveMe(this.profile).then(() => {
        this.$toast.success(this.$t('cs.front.saved_alert'));
        this.modalBAopened = false;
      });
    },
    saveBillingAddress() {
      return this.saveAddress(this.profile.billing_address).then(() => {
        this.$toast.success(this.$t('cs.front.saved_alert'));
        this.fetchMe();
        this.modalBAopened = false;
      });
    },
  },
  mounted() {
    this.profile = this.me;
    this.fetchMe().finally(() => {
      if (!this.me || !this.me.id) {
        this.$router.push({ name: 'auth' });
      }
    });
  },
};
</script>
