import axios from 'axios';

export default {
  select(context, type) {
    if (!type) {
      context.commit('select', null);

      return false;
    }

    return axios.get(`${context.rootState.apiHost}/club-shop/app/page/${type}/`).then((response) => {
      context.commit('select', response.data);
    });
  },
  selectByType(context, type) {
    if (!type) {
      context.commit('select', null);

      return false;
    }

    return axios.get(`${context.rootState.apiHost}/club-shop/app/page/type/${type}/`).then((response) => {
      context.commit('select', response.data);
    });
  },
  selectByUrl(context, url) {
    if (!url) {
      context.commit('select', null);

      return false;
    }

    return axios.get(`${context.rootState.apiHost}/club-shop/app/page/url/${url}/`).then((response) => {
      context.commit('select', response.data);
    });
  },
};
