<template>
  <div class="cart-sidebar">
    <div v-if="cart" class="cart-sidebar-inner">
      <div class="cart-sidebar-header">
        <button @click="$store.commit('hideSideCart')" class="cart-sidebar-close" type="button">
          <i class="icon icon-close"></i>
        </button>
        <p class="h4">{{ $t('cs.front.cart') }}</p>
        <div class="cart-sidebar-count">
          {{ $t('cs.front.products_number') }}: {{ cart.positions.length }}
        </div>
      </div>
      <div class="cart-sidebar-body">
        <div class="alert alert-success" style="display: none;">
          <i class="icon icon-check-circle"></i>
          1
        </div>
        <checkout-positions :positions="cart.positions" itemClass="cart-sidebar-item" />
      </div>

      <div v-if="cart && cart.positions.length > 0" class="cart-sidebar-bottom">
        <div class="cart-sidebar-total">
          <div>{{ $t('cs.front.total') }}:</div>
          <div class="green">{{ $filters.priceFormat(cart.totalPrice, shop.currency) }}</div>
        </div>

        <router-link
          v-if="auth"
          :to="{ name: 'checkout.final-step' }"
          class="btn btn-green mb-3"
        >
          {{ $t('cs.front.to_checkout') }}
        </router-link>
        <router-link
          v-else
          :to="{ name: 'checkout.anon' }"
          class="btn btn-green mb-3"
        >
          {{ $t('cs.front.to_checkout') }}
        </router-link>
        <router-link
          :to="{ name: 'checkout' }"
          class="btn"
        >
          {{ $t('cs.front.edit_shopping_cart') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CheckoutPositions from '@/views/screens/checkout/components/CheckoutPositions.vue';

export default {
  components: {
    CheckoutPositions,
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters({
      me: 'profile/me',
      cart: 'cart/cart',
      shop: 'shop/selected',
      showSideCart: 'showSideCart',
      apiToken: 'auth/apiToken',
    }),
    auth() {
      return (this.apiToken && this.me && this.me.id);
    },
  },
  watch: {

  },
  methods: {
    ...mapActions('cart', {
      fetchCart: 'fetch',
    }),
  },
  mounted() {
    if (!this.cart) {
      this.fetchCart();
    }
  },
  created() {
    window.addEventListener('click', (e) => {
      if (this.showSideCart) {
        if (!this.$el.contains(e.target)) {
          this.$store.commit('hideSideCart');
        }
      }
    });
  },
};
</script>
