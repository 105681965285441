<template>
  <main class="content profile-page">
    <div v-if="profile" class="container">
      <div class="row">
        <div class="col-md-4 col-lg-3">
          <profile-side-menu
            v-if="profile"
            :user-email="profile.email"
            :user-full-name="profile.name+' '+profile.surname"
          />
        </div>
        <div class="col-md-8 col-lg-9">
          <div class="profile-content">
            <div class="row mt-4">
              <div v-if="shop" class="col-lg-12 col-xl-12">
                <div class="box">
                  <div class="box-header">
                    <i class="icon icon-address"></i>
                    {{ $t('cs.front.pickup_address') }}
                  </div>
                  <div class="box-text" v-if="shop && shop.pickup_address">
                    {{ shop.pickup_address.name_1 }}<br>
                    {{ shop.pickup_address.name_2 }}<br>
                    {{ shop.pickup_address.address }}<br>
                    {{ shop.pickup_address.zip }} {{ shop.pickup_address.city }}<br>
                    {{ shop.pickup_address.country }}
                  </div>
                </div>
              </div>
            </div>
            <ul class="nav nav-tabs tabs">
              <li class="nav-item">
                <a href="#" class="nav-link tab active" data-toggle="tab" data-target="#tab1">
                  {{ $t('cs.front.billing_address') }}
                </a>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link tab " data-toggle="tab" data-target="#tab2">
                  {{ $t('cs.front.shipping_address') }}
                </a>
              </li>
            </ul>
            <br />
            <div class="tab-content">
              <div class="tab-pane active show" id="tab1">
                <addresses-list
                  :type="addressTypes.TYPE_BILLING"
                  :isCompany="profile && profile.type.toUpperCase() === 'C'"
                  @changed="fetchMe()"
                />
              </div>
              <div class="tab-pane fade" id="tab2">
                <addresses-list
                  :type="addressTypes.TYPE_SHIPPING"
                  :isCompany="profile && profile.type.toUpperCase() === 'C'"
                  @changed="fetchMe()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProfileSideMenu from './components/ProfileSideMenu.vue';
import AddressesList from './components/AddressesList.vue';
import * as pageTypes from '../../../consts/page';
import * as addressTypes from '../../../consts/address';

export default {
  components: {
    ProfileSideMenu,
    AddressesList,
  },
  data() {
    return {
      profile: null,
      pageTypes,
      addressTypes,
    };
  },
  computed: {
    ...mapGetters({
      me: 'profile/me',
      shop: 'shop/selected',
    }),
  },
  watch: {
    me(newVal) {
      this.profile = newVal;
    },
  },
  methods: {
    ...mapActions('profile', {
      fetchMe: 'fetchMe',
    }),
  },
  mounted() {
    this.profile = this.me;
    this.fetchMe().finally(() => {
      if (!this.me || !this.me.id) {
        this.$router.push({ name: 'auth' });
      }
    });
  },
};
</script>
