const translations = {
  locale: 'de-DE', // default locale
  messages: {
    'de-DE': {
      'cs.front.phone_is_invalid': 'Bitte prüfen Sie die angegebene Telefonnummer',
      'cs.front.only_text_for_all_positions': 'Den gleichen Text für alle {amount_of_products} Produkte der Größe {size} in Farbe {color} verwenden.',
      'cs.front.different_text_for_all_positions': 'Verschiedene Texte für jedes der {amount_of_products} Produkte der Größe {size} in Farbe {color} verwenden.',

      'cs.front.warnings.options_text_required': 'Ihre Auswahl enthält eine Veredelungsoption mit einem Freitext. Sie müssen den gewünschten Text für diese Option direkt im Menü "Veredelungsoptionen" eingeben, damit dieser auf Ihrem Produkt gedruckt werden kann.',
      'cs.labels.product_image_type_f': 'Vorderansicht',
      'cs.labels.product_image_type_r': 'Rückenansicht',
      'cs.labels.product_image_type_s': 'Seitenansicht',
      'cs.labels.product_image_type_d': 'Detailansicht',

      'cs.labels.available': 'Verfügbare',
      'cs.labels.product_options': 'Veredelungsoptionen',
      'cs.labels.product_customization': 'Veredelung',

      'cs.front.example': 'Beispiel',
      'cs.front.search_country': 'Suche',
      'cs.front.scan_qr_using_app_and_wait_up_to_1_min': 'Bitte scannen Sie den QR Code mit Ihrer Bank/TWINT App und warten Sie ca. 60 Sekunden.',
      'cs.front.payment_token': 'Token',
      'cs.front.payment_method_mollie_paypal': 'Zahlung per Paypal',
      'cs.front.payment_method_mollie_twint': 'Zahlung per Twint',
      'cs.front.payment_method_mollie_ideal': 'Zahlung per iDEAL',
      'cs.front.payment_method_mollie_bankontakt': 'Zahlung per Bancontact',
      'cs.front.payment_method_mollie_credit_and_debit_cards': 'Zahlung per Debit- oder Kreditkarte',
      'cs.front.payment_processing_trouble_in': 'Leider ist bei der Zahlung ein Problem aufgetreten.',
      'cs.front.payment_processing_trouble_note': 'Bitte versuchen Sie es erneut oder wählen Sie eine andere Zahlungsart aus.',

      'cs.front.post': 'Post',
      'cs.front.pickup_at_club_address': '',
      'cs.front.pickup_at_dealer_address': '',
      'cs.front.shipping_method': 'Gewählte Versandart',
      'cs.front.profile.orders.list.order_address': 'Lieferadresse',
      'cs.front.payment_method_paypal': 'Paypal',
      'cs.front.bye': 'Bis bald!',
      'cs.front.shipping_address': 'Lieferadresse',
      'cs.front.shipping_address_the_same_with_billing': 'Die Lieferadresse ist identisch mit der Rechnungsadresse.',
      'cs.front.select_shipping_method': 'Bitte wählen Sie die gewünschte Versandart',
      'cs.front.shipping_cost': 'Versandkosten',
      'cs.front.open_sizes_table': 'Größentabelle öffnen',
      'cs.front.finishing-comment-file-name': 'Veredelungsangaben',
      'cs.front.order_options_text_uploads_selection': 'Ich möchte die erforderlichen Informationen direkt eingeben, anstatt eine Datei hochzuladen',
      'cs.front.order_options_text_uploads_info': 'Bitte geben Sie hier alle nötigen Informationen für die bestellten Veredelungsoptionen an. Dies sind neben Artikelnummer und Größe die benötigten persönlichen Angaben je gewählter Veredelungsoption (z.B. Spielername, Initialien, Spielernummer, …).',
      'cs.front.productoptions_modal_file_upload_info': 'Bitte beachten Sie, dass wir im Bestellprozess genaue Informationen zur individuellen Veredelung benötigen. Hierzu werden wir Sie bitten eine Datei mit diesen Details hochzuladen. Diese Datei sollte neben Artikelnummer und Größe die benötigten persönlichen Angaben je gewählter Veredelungsoption (z.B. Spielername, Initialien, Spielernummer, …) enthalten. Mögliche Formate hierfür sind: doc, docx, xls, xlsx, pdf, txt, csv, odc, odt & zip. Alternativ können Sie diese Informationen auch in ein Textfeld eintragen.',
      'cs.front.colors.black': 'schwarz',
      'cs.front.colors.blue': 'blau',
      'cs.front.colors.brown': 'braun',
      'cs.front.colors.green': 'grün',
      'cs.front.colors.grey': 'grau',
      'cs.front.colors.purple': 'lila',
      'cs.front.colors.red': 'rot',
      'cs.front.colors.orange': 'orange',
      'cs.front.colors.white': 'weiß',
      'cs.front.colors.yellow': 'gelb',
      'cs.front.password_new': 'Neues Passwort',
      'cs.front.filter_apply': 'Filter anwenden',
      'cs.front.sum_options': 'Summe Veredelung',
      'cs.front.add_options': 'Veredelung hinzufügen',
      'cs.front.total_options_price': 'Summe Veredelung',
      'cs.front.productoptions_link_button': 'Veredelungsoptionen wählen',
      'cs.front.productoptions_selected_price_total': 'Gesamtpreis Veredelung',
      'cs.front.club_products': 'Vereinsartikel durchsuchen',
      'cs.front.eo_products': 'ERIMA Sortiment durchsuchen',
      'cs.front.no_products_found': 'Zu diesen Suchkriterien wurden keine Produkte gefunden',
      'cs.front.product_size_in_stock_status_few': 'Größe {size} geringer Lagerbestand. Eventuelle Lieferverzögerung.',
      'cs.front.product_size_in_stock_status_many_soon': 'Größe {size} aktuell kein Lagerbestand. Erwartet am {next_delivery_date}.',
      'cs.front.product_size_in_stock_status_not_available_now': 'Größe {size} aktuell kein Lagerbestand.',
      'cs.front.current_password': 'Newsletter abonnieren',
      'cs.front.subscribe_to_newsletter': 'Newsletter abonnieren',
      'cs.front.cart_position_options_title': 'Gewählte Veredelungsoptionen',
      'cs.front.productoptions_link': 'Veredelungsoptionen',
      'cs.front.productoptions_selected': 'gewählt',
      'cs.front.productoptions_modal_title': 'Verfügbare Veredelungsoptionen',
      'cs.front.productoptions_modal_total': 'Gesamtsumme Veredelung',
      'cs.front.checkout_address_select_modal_title': 'Wählen Sie hier die gewünschte Adresse',
      'cs.front.customer_address': 'Rechnungsadresse',
      'cs.front.make_default': 'Als Standard verwenden',
      'cs.front.enter_your_street_and_apartment': 'Bitte geben Sie hier Ihre Straße und Hausnummer ein',
      'cs.front.empty_cart': 'Ihr Warenkorb ist leer',
      'cs.front.welcome': 'Willkommen!',
      'cs.front.order_options_file_uploads_info': 'Bitte stellen Sie uns hier eine Übersicht über die gewünschten Inhalte für alle gewählten Veredelungsoptionen als Datei zur Verfügung.',
      'cs.front.order_options_file_uploads_button': 'Datei auswählen',
      'cs.front.gender_name_m': 'Herren',
      'cs.front.gender_name_w': 'Damen',
      'cs.front.gender': 'Geschlecht',
      'cs.front.search': 'Suche',
      'cs.front.search_query': 'Suchbegriff eingeben',
      'cs.front.filter': 'Filter anwenden',
      'cs.front.colors': 'Farben',
      'cs.front.in': 'in',
      'cs.front.phone': 'Telefonnummer',
      'cs.front.i_am_already_a_customer': 'Ich bin bereits Kunde',
      'cs.front.email': 'Ihre E-Mail Adresse',
      'cs.front.password': 'Ihr Passwort',
      'cs.front.forget_password': 'Passwort vergessen?',
      'cs.front.login': 'Anmelden',
      'cs.front.forget_password_field_note': 'Wir senden Ihnen eine Best\u00e4tigungs-E-Mail. Klicken Sie auf den darin enthaltenen Link, um Ihr Passwort zu \u00e4ndern.',
      'cs.front.send_email': 'E-Mail senden',
      'cs.front.setting_of_new_password': 'Neues Passwort festlegen',
      'cs.front.token_from_the_letter': 'Sicherheitscode aus E-Mail',
      'cs.front.new_password': 'Neues Passwort',
      'cs.front.set_password': 'Passwort \u00e4ndern',
      'cs.front.create_customer': 'Ich bin Neukunde',
      'cs.front.company': 'Firma \/ Verein',
      'cs.front.private_person': 'Privatkunde',
      'cs.front.company_name': 'Firma \/ Vereinsname',
      'cs.front.enter_your_name': 'Bitte geben Sie hier Ihren Vornamen an',
      'cs.front.enter_company_name': 'Geben Sie hier Ihren Firmen- oder Vereinsnamen ein',
      'cs.front.salutation': 'Anrede',
      'cs.front.salutation_mr': 'Herr',
      'cs.front.salutation_miss': 'Frau',
      'cs.front.salutation_na': 'keine Angabe',
      'cs.front.first_name': 'Vorname',
      'cs.front.surname': 'Nachname',
      'cs.front.enter_your_surname': 'Bitte geben Sie hier Ihren Nachnamen an',
      'cs.front.subscribe_newsletter': 'Ich m\u00f6chte mich zum Newsletter anmelden',
      'cs.front.enter_your_password': 'Bitte geben Sie hier Ihr gew\u00fcnschtes Passwort an',
      'cs.front.password_field_note': 'Ihr Passwort muss mindestens 8 Zeichen umfassen. Ber\u00fccksichtigen Sie Gro\u00df- und Kleinschreibung.',
      'cs.front.address': 'Ihre Adresse',
      'cs.front.street_and_apartment': 'Strasse und Hausnummer',
      'cs.front.zip': 'Postleitzahl',
      'cs.front.enter_your_zip': 'Bitte geben Sie hier Ihre Postleitzahl an',
      'cs.front.city': 'Ort',
      'cs.front.enter_your_city': 'Bitte geben Sie hier Ihren Ort an',
      'cs.front.this_field_is_required': '* hierbei handelt es sich um ein Pflichtfeld',
      'cs.front.privacy': 'Datenschutz',
      'cs.front.note_our_privacy_agreement': 'Bitte beachten Sie unsere',
      'cs.front.menu': 'Men\u00fc',
      'cs.front.back': 'Zur\u00fcck',
      'cs.front.for_individuals': 'Einzelbestellung',
      'cs.front.material': 'Material',
      'cs.front.for_teams': 'Teambestellung',
      'cs.front.color': 'Farbe',
      'cs.front.size': 'Gr\u00f6\u00dfe',
      'cs.front.order_quantity': 'Bestellmenge',
      'cs.front.add_to_cart': 'In den Warenkorb',
      'cs.front.price': 'Preis',
      'cs.front.total_price': 'Gesamtpreis',
      'cs.front.total_quantity': 'Gesamtmenge',
      'cs.front.cancel': 'Abbrechen',
      'cs.front.features': 'Features auf einen Blick',
      'cs.front.description': 'Beschreibung',
      'cs.front.with_this_product_are_often_taken': 'Kunden kauften auch',
      'cs.front.wellcome': 'Willkommen',
      'cs.front.profile_index_note': 'Dies ist Ihr Konto Dashboard, wo Sie die M\u00f6glichkeit haben, Ihre letzten Kontoaktivit\u00e4ten einzusehen',
      'cs.front.personal_data': 'Pers\u00f6nliche Daten',
      'cs.front.change': '\u00c4ndern',
      'cs.front.newsletter': 'Gew\u00e4hlte Newsletter',
      'cs.front.newsletter_note': 'Ja, ich m\u00f6chte den kostenlosen Newsletter erhalten und \u00fcber aktuelle Angebote per E-Mail informiert werden. Eine Abmeldung ist jederzeit m\u00f6glich. Bitte beachten Sie unsere',
      'cs.front.privacy_policy': 'Datenschutzbestimmungen',
      'cs.front.primary_billing_address': 'Prim\u00e4re Rechnungsadresse',
      'cs.front.edit': 'Bearbeiten',
      'cs.front.pickup_address': 'Abholadresse',
      'cs.front.payment_method': 'Gew\u00e4hlte Zahlungsart',
      'cs.front.payment_method_cash': 'Bezahlung vor Ort beim Händler',
      'cs.front.billing_address': 'Rechnungsadresse',
      'cs.front.first_name_and_surname': 'Vor- & Nachname',
      'cs.front.save': 'Speichern',
      'cs.front.saved_alert': '\u00c4nderungen gespeichert',
      'cs.front.date': 'Datum',
      'cs.front.order_status': 'Bestellstatus',
      'cs.front.actions': 'Aktionen',
      'cs.front.order_number': 'Bestellnummer',
      'cs.front.product': 'Artikel',
      'cs.front.quantity': 'Menge',
      'cs.front.profile_menu': 'Men\u00fc',
      'cs.front.overview': '\u00dcbersicht',
      'cs.front.orders': 'Bestellungen',
      'cs.front.addresses': 'Adressen',
      'cs.front.log_out': 'Abmelden',
      'cs.front.clear': 'Filter zur\u00fccksetzen',
      'cs.front.sortiment': 'Sortiment',
      'cs.front.share_on': 'Teilen auf',
      'cs.front.cart': 'Warenkorb',
      'cs.front.products_number': 'Anzahl Artikel',
      'cs.front.total': 'Gesamtsumme',
      'cs.front.to_checkout': 'Zur Kasse',
      'cs.front.edit_shopping_cart': 'Warenkorb bearbeiten',
      'cs.front.checkout_address': 'Ihre Adresse',
      'cs.front.payment_and_shipping': 'Zahlungsart & Versand',
      'cs.front.proof_and_order': 'Pr\u00fcfen & Bestellen',
      'cs.front.create_account_or_login': 'Kundenkonto anlegen oder einloggen',
      'cs.front.do_not_create_customer_account': 'Als Gast bestellen',
      'cs.front.create_account': 'Ich m\u00f6chte als Gast bestellen',
      'cs.front.full_name': 'Ihr Vor- & Nachname',
      'cs.front.city_field_note': '* hierbei handelt es sich um ein Pflichtfeld',
      'cs.front.go_next': 'Weiter',
      'cs.front.i_agree_to_privacy_policy': 'Ich habe die Datenschutzbestimmungen zur Kenntnis genommen.',
      'cs.front.register': 'Weiter',
      'cs.front.payment_on_pickup_note': 'Sie bezahlen Ihre Bestellung bequem bei Abholung bei Ihrem Fachhandelspartner.',
      'cs.front.select_payment_method': 'Zahlungsart w\u00e4hlen',
      'cs.front.main_billing_address': 'Prim\u00e4re Rechnungsadresse',
      'cs.front.new_customer_address': 'Neue Adresse hinzuf\u00fcgen',
      'cs.front.summary_info': 'Zusammenfassung',
      'cs.front.sum': 'Summe',
      'cs.front.delivery_price': 'Versandkosten',
      'cs.front.total_without_tax': 'Gesamtsumme ohne MwSt.',
      'cs.front.including_vat': 'zzgl. MwSt.',
      'cs.front.i_agree_to': 'Hiermit akzeptiere ich die',
      'cs.front.create_order': 'Zahlungspflichtig bestellen',
      'cs.front.thanks_for_order_in': 'Vielen Dank f\u00fcr Ihre Bestellung im Vereinsshop von',
      'cs.front.thank_page_note': 'Wir informieren Sie per E-Mail sobald Ihre Bestellung bearbeitet wurde.',
      'cs.front.to_shop': 'Zur\u00fcck zum Shop',
      'cs.front.view': 'Anzeigen',
      'cs.front.product_number': 'Artikelnummer',
      'cs.front.including': 'zzgl.',
      'cs.front.vat': 'MwSt.',
      'cs.front.email_repeat': 'E-Mail Adresse wiederholen',
      'cs.front.current_passwort': 'Ihr aktuelles Passwort',
      'cs.front.password_required': 'Passwort Wiederholung',
      'cs.front.email_sent': 'E-Mail wurde gesendet',
      'cs.front.order.STATUS_NEW': 'Neu',
      'cs.front.order.STATUS_IN_PROCESS': 'In Bearbeitung',
      'cs.front.order.STATUS_PICKING': 'Wird gepickt',
      'cs.front.order.STATUS_SENT_OUT': 'Versendet',
      'cs.front.order.STATUS_SMU_PROCESS': 'Wird veredelt',
      'cs.front.order.STATUS_READY_FOR_PICKUP': 'Bereit zur Abholung',
      'cs.front.order.STATUS_DONE': 'Abgeschlossen',
      'cs.front.order.STATUS_CANCELED': 'Storniert',
      'cs.front.at': 'ab',
    },
    'ch-DE': {
      'cs.front.phone_is_invalid': 'Bitte prüfen Sie die angegebene Telefonnummer',
      'cs.front.only_text_for_all_positions': 'Den gleichen Text für alle {amount_of_products} Produkte der Größe {size} in Farbe {color} verwenden.',
      'cs.front.different_text_for_all_positions': 'Verschiedene Texte für jedes der {amount_of_products} Produkte der Größe {size} in Farbe {color} verwenden.',

      'cs.front.warnings.options_text_required': 'Ihre Auswahl enthält eine Veredelungsoption mit einem Freitext. Sie müssen den gewünschten Text für diese Option direkt im Menü "Veredelungsoptionen" eingeben, damit dieser auf Ihrem Produkt gedruckt werden kann.',
      'cs.labels.product_image_type_f': 'Vorderansicht',
      'cs.labels.product_image_type_r': 'Rückenansicht',
      'cs.labels.product_image_type_s': 'Seitenansicht',
      'cs.labels.product_image_type_d': 'Detailansicht',

      'cs.labels.available': 'Verfügbare',
      'cs.labels.product_options': 'Veredelungsoptionen',
      'cs.labels.product_customization': 'Veredelung',

      'cs.front.search_country': 'Suche',
      'cs.front.example': 'Beispiel',
      'cs.front.scan_qr_using_app_and_wait_up_to_1_min': 'Bitte scannen Sie den QR Code mit Ihrer Bank/TWINT App und warten Sie ca. 60 Sekunden.',
      'cs.front.payment_token': 'Token',
      'cs.front.payment_method_mollie_paypal': 'Zahlung per Paypal',
      'cs.front.payment_method_mollie_twint': 'Zahlung per Twint',
      'cs.front.payment_method_mollie_ideal': 'Zahlung per iDEAL',
      'cs.front.payment_method_mollie_bankontakt': 'Zahlung per Bancontact',
      'cs.front.payment_method_mollie_credit_and_debit_cards': 'Zahlung per Debit- oder Kreditkarte',
      'cs.front.payment_processing_trouble_in': 'Leider ist bei der Zahlung ein Problem aufgetreten.',
      'cs.front.payment_processing_trouble_note': 'Bitte versuchen Sie es erneut oder wählen Sie eine andere Zahlungsart aus.',

      'cs.front.post': 'Post CH',
      'cs.front.pickup_at_club_address': 'Abholung bei Vereinsadresse',
      'cs.front.pickup_at_dealer_address': 'Abholung beim Händler',
      'cs.front.shipping_method': 'Gewählte Versandart',
      'cs.front.profile.orders.list.order_address': 'Lieferadresse',
      'cs.front.payment_method_paypal': 'Paypal',
      'cs.front.bye': 'Bis bald!',
      'cs.front.shipping_address': 'Lieferadresse',
      'cs.front.shipping_address_the_same_with_billing': 'Die Lieferadresse ist identisch mit der Rechnungsadresse.',
      'cs.front.select_shipping_method': 'Bitte wählen Sie die gewünschte Versandart',
      'cs.front.shipping_cost': 'Versandkosten',
      'cs.front.open_sizes_table': 'Größentabelle öffnen',
      'cs.front.finishing-comment-file-name': 'Veredelungsangaben',
      'cs.front.order_options_text_uploads_selection': 'Ich möchte die erforderlichen Informationen direkt eingeben, anstatt eine Datei hochzuladen',
      'cs.front.order_options_text_uploads_info': 'Bitte geben Sie hier alle nötigen Informationen für die bestellten Veredelungsoptionen an. Dies sind neben Artikelnummer und Größe die benötigten persönlichen Angaben je gewählter Veredelungsoption (z.B. Spielername, Initialien, Spielernummer, …).',
      'cs.front.productoptions_modal_file_upload_info': 'Bitte beachten Sie, dass wir im Bestellprozess genaue Informationen zur individuellen Veredelung benötigen. Hierzu werden wir Sie bitten eine Datei mit diesen Details hochzuladen. Diese Datei sollte neben Artikelnummer und Größe die benötigten persönlichen Angaben je gewählter Veredelungsoption (z.B. Spielername, Initialien, Spielernummer, …) enthalten. Mögliche Formate hierfür sind: doc, docx, xls, xlsx, pdf, txt, csv, odc, odt & zip. Alternativ können Sie diese Informationen auch in ein Textfeld eintragen.',
      'cs.front.colors.black': 'schwarz',
      'cs.front.colors.blue': 'blau',
      'cs.front.colors.brown': 'braun',
      'cs.front.colors.green': 'grün',
      'cs.front.colors.grey': 'grau',
      'cs.front.colors.purple': 'lila',
      'cs.front.colors.red': 'rot',
      'cs.front.colors.orange': 'orange',
      'cs.front.colors.white': 'weiß',
      'cs.front.colors.yellow': 'gelb',
      'cs.front.password_new': 'Neues Passwort',
      'cs.front.filter_apply': 'Filter anwenden',
      'cs.front.sum_options': 'Summe Veredelung',
      'cs.front.add_options': 'Veredelung hinzufügen',
      'cs.front.total_options_price': 'Summe Veredelung',
      'cs.front.productoptions_link_button': 'Veredelungsoptionen wählen',
      'cs.front.productoptions_selected_price_total': 'Gesamtpreis Veredelung',
      'cs.front.club_products': 'Vereinsartikel durchsuchen',
      'cs.front.eo_products': 'ERIMA Sortiment durchsuchen',
      'cs.front.no_products_found': 'Zu diesen Suchkriterien wurden keine Produkte gefunden',
      'cs.front.product_size_in_stock_status_few': 'Größe {size} geringer Lagerbestand. Eventuelle Lieferverzögerung.',
      'cs.front.product_size_in_stock_status_many_soon': 'Größe {size} aktuell kein Lagerbestand. Erwartet am {next_delivery_date}.',
      'cs.front.product_size_in_stock_status_not_available_now': 'Größe {size} aktuell kein Lagerbestand.',
      'cs.front.subscribe_to_newsletter': 'Newsletter abonnieren',
      'cs.front.current_password': 'Newsletter abonnieren',
      'cs.front.cart_position_options_title': 'Gewählte Veredelungsoptionen',
      'cs.front.productoptions_link': 'Veredelungsoptionen',
      'cs.front.productoptions_selected': 'gewählt',
      'cs.front.productoptions_modal_title': 'Verfügbare Veredelungsoptionen',
      'cs.front.productoptions_modal_total': 'Gesamtsumme Veredelung',
      'cs.front.checkout_address_select_modal_title': 'Wählen Sie hier die gewünschte Adresse',
      'cs.front.customer_address': 'Rechnungsadresse',
      'cs.front.make_default': 'Als Standard verwenden',
      'cs.front.enter_your_street_and_apartment': 'Bitte geben Sie hier Ihre Straße und Hausnummer ein',
      'cs.front.empty_cart': 'Ihr Warenkorb ist leer',
      'cs.front.welcome': 'Willkommen!',
      'cs.front.order_options_file_uploads_info': 'Bitte stellen Sie uns hier eine Übersicht über die gewünschten Inhalte für alle gewählten Veredelungsoptionen als Datei zur Verfügung.',
      'cs.front.order_options_file_uploads_button': 'Datei auswählen',
      'cs.front.gender_name_m': 'Herren',
      'cs.front.gender_name_w': 'Damen',
      'cs.front.gender': 'Geschlecht',
      'cs.front.search': 'Suche',
      'cs.front.search_query': 'Suchbegriff eingeben',
      'cs.front.filter': 'Filter anwenden',
      'cs.front.colors': 'Farben',
      'cs.front.in': 'in',
      'cs.front.phone': 'Telefonnummer',
      'cs.front.i_am_already_a_customer': 'Ich bin bereits Kunde',
      'cs.front.email': 'Ihre E-Mail Adresse',
      'cs.front.password': 'Ihr Passwort',
      'cs.front.forget_password': 'Passwort vergessen?',
      'cs.front.login': 'Anmelden',
      'cs.front.forget_password_field_note': 'Wir senden Ihnen eine Best\u00e4tigungs-E-Mail. Klicken Sie auf den darin enthaltenen Link, um Ihr Passwort zu \u00e4ndern.',
      'cs.front.send_email': 'E-Mail senden',
      'cs.front.setting_of_new_password': 'Neues Passwort festlegen',
      'cs.front.token_from_the_letter': 'Sicherheitscode aus E-Mail',
      'cs.front.new_password': 'Neues Passwort',
      'cs.front.set_password': 'Passwort \u00e4ndern',
      'cs.front.create_customer': 'Ich bin Neukunde',
      'cs.front.company': 'Firma \/ Verein',
      'cs.front.private_person': 'Privatkunde',
      'cs.front.company_name': 'Firma \/ Vereinsname',
      'cs.front.enter_your_name': 'Bitte geben Sie hier Ihren Vornamen an',
      'cs.front.enter_company_name': 'Geben Sie hier Ihren Firmen- oder Vereinsnamen ein',
      'cs.front.salutation': 'Anrede',
      'cs.front.salutation_mr': 'Herr',
      'cs.front.salutation_miss': 'Frau',
      'cs.front.salutation_na': 'keine Angabe',
      'cs.front.first_name': 'Vorname',
      'cs.front.surname': 'Nachname',
      'cs.front.enter_your_surname': 'Bitte geben Sie hier Ihren Nachnamen an',
      'cs.front.subscribe_newsletter': 'Ich m\u00f6chte mich zum Newsletter anmelden',
      'cs.front.enter_your_password': 'Bitte geben Sie hier Ihr gew\u00fcnschtes Passwort an',
      'cs.front.password_field_note': 'Ihr Passwort muss mindestens 8 Zeichen umfassen. Ber\u00fccksichtigen Sie Gross- und Kleinschreibung.',
      'cs.front.address': 'Ihre Adresse',
      'cs.front.street_and_apartment': 'Strasse und Hausnummer',
      'cs.front.zip': 'Postleitzahl',
      'cs.front.enter_your_zip': 'Bitte geben Sie hier Ihre Postleitzahl an',
      'cs.front.city': 'Ort',
      'cs.front.enter_your_city': 'Bitte geben Sie hier Ihren Ort an',
      'cs.front.this_field_is_required': '* hierbei handelt es sich um ein Pflichtfeld',
      'cs.front.privacy': 'Datenschutz',
      'cs.front.note_our_privacy_agreement': 'Bitte beachten Sie unsere',
      'cs.front.menu': 'Men\u00fc',
      'cs.front.back': 'Zur\u00fcck',
      'cs.front.for_individuals': 'Einzelbestellung',
      'cs.front.material': 'Material',
      'cs.front.for_teams': 'Teambestellung',
      'cs.front.color': 'Farbe',
      'cs.front.size': 'Gr\u00f6sse',
      'cs.front.order_quantity': 'Bestellmenge',
      'cs.front.add_to_cart': 'In den Warenkorb',
      'cs.front.price': 'Preis',
      'cs.front.total_price': 'Gesamtpreis',
      'cs.front.total_quantity': 'Gesamtmenge',
      'cs.front.cancel': 'Abbrechen',
      'cs.front.features': 'Features auf einen Blick',
      'cs.front.description': 'Beschreibung',
      'cs.front.with_this_product_are_often_taken': 'Kunden kauften auch',
      'cs.front.wellcome': 'Willkommen',
      'cs.front.profile_index_note': 'Dies ist Ihr Konto Dashboard, wo Sie die M\u00f6glichkeit haben, Ihre letzten Kontoaktivit\u00e4ten einzusehen',
      'cs.front.personal_data': 'Pers\u00f6nliche Daten',
      'cs.front.change': '\u00c4ndern',
      'cs.front.newsletter': 'Gew\u00e4hlte Newsletter',
      'cs.front.newsletter_note': 'Ja, ich m\u00f6chte den kostenlosen Newsletter erhalten und \u00fcber aktuelle Angebote per E-Mail informiert werden. Eine Abmeldung ist jederzeit m\u00f6glich. Bitte beachten Sie unsere',
      'cs.front.privacy_policy': 'Datenschutzbestimmungen',
      'cs.front.primary_billing_address': 'Prim\u00e4re Rechnungsadresse',
      'cs.front.edit': 'Bearbeiten',
      'cs.front.pickup_address': 'Abholadresse',
      'cs.front.payment_method': 'Gew\u00e4hlte Zahlungsart',
      'cs.front.payment_method_cash': 'Bar oder Kartenzahlung bei Abholung',
      'cs.front.billing_address': 'Rechnungsadresse',
      'cs.front.first_name_and_surname': 'Vor- & Nachname',
      'cs.front.save': 'Speichern',
      'cs.front.saved_alert': '\u00c4nderungen gespeichert',
      'cs.front.date': 'Datum',
      'cs.front.order_status': 'Bestellstatus',
      'cs.front.actions': 'Aktionen',
      'cs.front.order_number': 'Bestellnummer',
      'cs.front.product': 'Artikel',
      'cs.front.quantity': 'Menge',
      'cs.front.profile_menu': 'Men\u00fc',
      'cs.front.overview': '\u00dcbersicht',
      'cs.front.orders': 'Bestellungen',
      'cs.front.addresses': 'Adressen',
      'cs.front.log_out': 'Abmelden',
      'cs.front.clear': 'Filter zur\u00fccksetzen',
      'cs.front.sortiment': 'Sortiment',
      'cs.front.share_on': 'Teilen auf',
      'cs.front.cart': 'Warenkorb',
      'cs.front.products_number': 'Anzahl Artikel',
      'cs.front.total': 'Gesamtsumme',
      'cs.front.to_checkout': 'Zur Kasse',
      'cs.front.edit_shopping_cart': 'Warenkorb bearbeiten',
      'cs.front.checkout_address': 'Ihre Adresse',
      'cs.front.payment_and_shipping': 'Zahlungsart & Versand',
      'cs.front.proof_and_order': 'Pr\u00fcfen & Bestellen',
      'cs.front.create_account_or_login': 'Kundenkonto anlegen oder einloggen',
      'cs.front.do_not_create_customer_account': 'Als Gast bestellen',
      'cs.front.create_account': 'Ich m\u00f6chte als Gast bestellen',
      'cs.front.full_name': 'Ihr Vor- & Nachname',
      'cs.front.city_field_note': '* hierbei handelt es sich um ein Pflichtfeld',
      'cs.front.go_next': 'Weiter',
      'cs.front.i_agree_to_privacy_policy': 'Ich habe die Datenschutzbestimmungen zur Kenntnis genommen.',
      'cs.front.register': 'Weiter',
      'cs.front.payment_on_pickup_note': 'Sie bezahlen Ihre Bestellung bequem bei Abholung bei Ihrem Fachhandelspartner.',
      'cs.front.select_payment_method': 'Zahlungsart w\u00e4hlen',
      'cs.front.main_billing_address': 'Prim\u00e4re Rechnungsadresse',
      'cs.front.new_customer_address': 'Neue Adresse hinzuf\u00fcgen',
      'cs.front.summary_info': 'Zusammenfassung',
      'cs.front.sum': 'Summe',
      'cs.front.delivery_price': 'Versandkosten',
      'cs.front.total_without_tax': 'Gesamtsumme ohne MwSt.',
      'cs.front.including_vat': 'zzgl. MwSt.',
      'cs.front.i_agree_to': 'Hiermit akzeptiere ich die',
      'cs.front.create_order': 'Zahlungspflichtig bestellen',
      'cs.front.thanks_for_order_in': 'Vielen Dank f\u00fcr Ihre Bestellung im Vereinsshop von',
      'cs.front.thank_page_note': 'Wir informieren Sie per E-Mail sobald Ihre Bestellung bearbeitet wurde.',
      'cs.front.to_shop': 'Zur\u00fcck zum Shop',
      'cs.front.view': 'Anzeigen',
      'cs.front.product_number': 'Artikelnummer',
      'cs.front.including': 'zzgl.',
      'cs.front.vat': 'MwSt.',
      'cs.front.email_repeat': 'E-Mail Adresse wiederholen',
      'cs.front.current_passwort': 'Ihr aktuelles Passwort',
      'cs.front.password_required': 'Passwort Wiederholung',
      'cs.front.email_sent': 'E-Mail wurde gesendet',
      'cs.front.order.STATUS_NEW': 'Neu',
      'cs.front.order.STATUS_IN_PROCESS': 'In Bearbeitung',
      'cs.front.order.STATUS_PICKING': 'Wird gepickt',
      'cs.front.order.STATUS_SENT_OUT': 'Versendet',
      'cs.front.order.STATUS_SMU_PROCESS': 'Wird veredelt',
      'cs.front.order.STATUS_READY_FOR_PICKUP': 'Bereit zur Abholung',
      'cs.front.order.STATUS_DONE': 'Abgeschlossen',
      'cs.front.order.STATUS_CANCELED': 'Storniert',
      'cs.front.at': 'ab',
    },
    'at-DE': {
      'cs.front.phone_is_invalid': 'Bitte prüfen Sie die angegebene Telefonnummer',

      'cs.front.only_text_for_all_positions': 'Den gleichen Text für alle {amount_of_products} Produkte der Größe {size} in Farbe {color} verwenden.',
      'cs.front.different_text_for_all_positions': 'Verschiedene Texte für jedes der {amount_of_products} Produkte der Größe {size} in Farbe {color} verwenden.',

      'cs.front.warnings.options_text_required': 'Ihre Auswahl enthält eine Veredelungsoption mit einem Freitext. Sie müssen den gewünschten Text für diese Option direkt im Menü "Veredelungsoptionen" eingeben, damit dieser auf Ihrem Produkt gedruckt werden kann.',
      'cs.labels.product_image_type_f': 'Vorderansicht',
      'cs.labels.product_image_type_r': 'Rückenansicht',
      'cs.labels.product_image_type_s': 'Seitenansicht',
      'cs.labels.product_image_type_d': 'Detailansicht',

      'cs.labels.available': 'Verfügbare',
      'cs.labels.product_options': 'Veredelungsoptionen',
      'cs.labels.product_customization': 'Veredelung',

      'cs.front.search_country': 'Suche',
      'cs.front.example': 'Beispiel',
      'cs.front.scan_qr_using_app_and_wait_up_to_1_min': 'Bitte scannen Sie den QR Code mit Ihrer Bank/TWINT App und warten Sie ca. 60 Sekunden.',
      'cs.front.payment_token': 'Token',
      'cs.front.payment_method_mollie_paypal': 'Zahlung per Paypal',
      'cs.front.payment_method_mollie_twint': 'Zahlung per Twint',
      'cs.front.payment_method_mollie_ideal': 'Zahlung per iDEAL',
      'cs.front.payment_method_mollie_bankontakt': 'Zahlung per Bancontact',
      'cs.front.payment_method_mollie_credit_and_debit_cards': 'Zahlung per Debit- oder Kreditkarte',
      'cs.front.payment_processing_trouble_in': 'Leider ist bei der Zahlung ein Problem aufgetreten.',
      'cs.front.payment_processing_trouble_note': 'Bitte versuchen Sie es erneut oder wählen Sie eine andere Zahlungsart aus.',

      'cs.front.post': 'Post',
      'cs.front.pickup_at_club_address': '',
      'cs.front.pickup_at_dealer_address': '',
      'cs.front.shipping_method': 'Gewählte Versandart',
      'cs.front.profile.orders.list.order_address': 'Lieferadresse',
      'cs.front.payment_method_paypal': 'Paypal',
      'cs.front.bye': 'Bis bald!',
      'cs.front.shipping_address': 'Lieferadresse',
      'cs.front.shipping_address_the_same_with_billing': 'Die Lieferadresse ist identisch mit der Rechnungsadresse.',
      'cs.front.select_shipping_method': 'Bitte wählen Sie die gewünschte Versandart',
      'cs.front.shipping_cost': 'Versandkosten',
      'cs.front.open_sizes_table': 'Größentabelle öffnen',
      'cs.front.finishing-comment-file-name': 'Veredelungsangaben',
      'cs.front.order_options_text_uploads_selection': 'Ich möchte die erforderlichen Informationen direkt eingeben, anstatt eine Datei hochzuladen',
      'cs.front.order_options_text_uploads_info': 'Bitte geben Sie hier alle nötigen Informationen für die bestellten Veredelungsoptionen an. Dies sind neben Artikelnummer und Größe die benötigten persönlichen Angaben je gewählter Veredelungsoption (z.B. Spielername, Initialien, Spielernummer, …).',
      'cs.front.productoptions_modal_file_upload_info': 'Bitte beachten Sie, dass wir im Bestellprozess genaue Informationen zur individuellen Veredelung benötigen. Hierzu werden wir Sie bitten eine Datei mit diesen Details hochzuladen. Diese Datei sollte neben Artikelnummer und Größe die benötigten persönlichen Angaben je gewählter Veredelungsoption (z.B. Spielername, Initialien, Spielernummer, …) enthalten. Mögliche Formate hierfür sind: doc, docx, xls, xlsx, pdf, txt, csv, odc, odt & zip. Alternativ können Sie diese Informationen auch in ein Textfeld eintragen.',
      'cs.front.colors.black': 'schwarz',
      'cs.front.colors.blue': 'blau',
      'cs.front.colors.brown': 'braun',
      'cs.front.colors.green': 'grün',
      'cs.front.colors.grey': 'grau',
      'cs.front.colors.purple': 'lila',
      'cs.front.colors.red': 'rot',
      'cs.front.colors.orange': 'orange',
      'cs.front.colors.white': 'weiß',
      'cs.front.colors.yellow': 'gelb',
      'cs.front.password_new': 'Neues Passwort',
      'cs.front.filter_apply': 'Filter anwenden',
      'cs.front.sum_options': 'Summe Veredelung',
      'cs.front.add_options': 'Veredelung hinzufügen',
      'cs.front.total_options_price': 'Summe Veredelung',
      'cs.front.productoptions_link_button': 'Veredelungsoptionen wählen',
      'cs.front.productoptions_selected_price_total': 'Gesamtpreis Veredelung',
      'cs.front.club_products': 'Vereinsartikel durchsuchen',
      'cs.front.eo_products': 'ERIMA Sortiment durchsuchen',
      'cs.front.no_products_found': 'Zu diesen Suchkriterien wurden keine Produkte gefunden',
      'cs.front.product_size_in_stock_status_few': 'Größe {size} geringer Lagerbestand. Eventuelle Lieferverzögerung.',
      'cs.front.product_size_in_stock_status_many_soon': 'Größe {size} aktuell kein Lagerbestand. Erwartet am {next_delivery_date}.',
      'cs.front.product_size_in_stock_status_not_available_now': 'Größe {size} aktuell kein Lagerbestand.',
      'cs.front.subscribe_to_newsletter': 'Newsletter abonnieren',
      'cs.front.current_password': 'Newsletter abonnieren',
      'cs.front.cart_position_options_title': 'Gewählte Veredelungsoptionen',
      'cs.front.productoptions_link': 'Veredelungsoptionen',
      'cs.front.productoptions_selected': 'gewählt',
      'cs.front.productoptions_modal_title': 'Verfügbare Veredelungsoptionen',
      'cs.front.productoptions_modal_total': 'Gesamtsumme Veredelung',
      'cs.front.checkout_address_select_modal_title': 'Wählen Sie hier die gewünschte Adresse',
      'cs.front.customer_address': 'Rechnungsadresse',
      'cs.front.make_default': 'Als Standard verwenden',
      'cs.front.enter_your_street_and_apartment': 'Bitte geben Sie hier Ihre Straße und Hausnummer ein',
      'cs.front.empty_cart': 'Ihr Warenkorb ist leer',
      'cs.front.welcome': 'Willkommen!',
      'cs.front.order_options_file_uploads_info': 'Bitte stellen Sie uns hier eine Übersicht über die gewünschten Inhalte für alle gewählten Veredelungsoptionen als Datei zur Verfügung.',
      'cs.front.order_options_file_uploads_button': 'Datei auswählen',
      'cs.front.gender_name_m': 'Herren',
      'cs.front.gender_name_w': 'Damen',
      'cs.front.gender': 'Geschlecht',
      'cs.front.search': 'Suche',
      'cs.front.search_query': 'Suchbegriff eingeben',
      'cs.front.filter': 'Filter anwenden',
      'cs.front.colors': 'Farben',
      'cs.front.in': 'in',
      'cs.front.phone': 'Telefonnummer',
      'cs.front.i_am_already_a_customer': 'Ich bin bereits Kunde',
      'cs.front.email': 'Ihre E-Mail Adresse',
      'cs.front.password': 'Ihr Passwort',
      'cs.front.forget_password': 'Passwort vergessen?',
      'cs.front.login': 'Anmelden',
      'cs.front.forget_password_field_note': 'Wir senden Ihnen eine Best\u00e4tigungs-E-Mail. Klicken Sie auf den darin enthaltenen Link, um Ihr Passwort zu \u00e4ndern.',
      'cs.front.send_email': 'E-Mail senden',
      'cs.front.setting_of_new_password': 'Neues Passwort festlegen',
      'cs.front.token_from_the_letter': 'Sicherheitscode aus E-Mail',
      'cs.front.new_password': 'Neues Passwort',
      'cs.front.set_password': 'Passwort \u00e4ndern',
      'cs.front.create_customer': 'Ich bin Neukunde',
      'cs.front.company': 'Firma \/ Verein',
      'cs.front.private_person': 'Privatkunde',
      'cs.front.company_name': 'Firma \/ Vereinsname',
      'cs.front.enter_your_name': 'Bitte geben Sie hier Ihren Vornamen an',
      'cs.front.enter_company_name': 'Geben Sie hier Ihren Firmen- oder Vereinsnamen ein',
      'cs.front.salutation': 'Anrede',
      'cs.front.salutation_mr': 'Herr',
      'cs.front.salutation_miss': 'Frau',
      'cs.front.salutation_na': 'keine Angabe',
      'cs.front.first_name': 'Vorname',
      'cs.front.surname': 'Nachname',
      'cs.front.enter_your_surname': 'Bitte geben Sie hier Ihren Nachnamen an',
      'cs.front.subscribe_newsletter': 'Ich m\u00f6chte mich zum Newsletter anmelden',
      'cs.front.enter_your_password': 'Bitte geben Sie hier Ihr gew\u00fcnschtes Passwort an',
      'cs.front.password_field_note': 'Ihr Passwort muss mindestens 8 Zeichen umfassen. Ber\u00fccksichtigen Sie Gro\u00df- und Kleinschreibung.',
      'cs.front.address': 'Ihre Adresse',
      'cs.front.street_and_apartment': 'Strasse und Hausnummer',
      'cs.front.zip': 'Postleitzahl',
      'cs.front.enter_your_zip': 'Bitte geben Sie hier Ihre Postleitzahl an',
      'cs.front.city': 'Ort',
      'cs.front.enter_your_city': 'Bitte geben Sie hier Ihren Ort an',
      'cs.front.this_field_is_required': '* hierbei handelt es sich um ein Pflichtfeld',
      'cs.front.privacy': 'Datenschutz',
      'cs.front.note_our_privacy_agreement': 'Bitte beachten Sie unsere',
      'cs.front.menu': 'Men\u00fc',
      'cs.front.back': 'Zur\u00fcck',
      'cs.front.for_individuals': 'Einzelbestellung',
      'cs.front.material': 'Material',
      'cs.front.for_teams': 'Teambestellung',
      'cs.front.color': 'Farbe',
      'cs.front.size': 'Gr\u00f6\u00dfe',
      'cs.front.order_quantity': 'Bestellmenge',
      'cs.front.add_to_cart': 'In den Warenkorb',
      'cs.front.price': 'Preis',
      'cs.front.total_price': 'Gesamtpreis',
      'cs.front.total_quantity': 'Gesamtmenge',
      'cs.front.cancel': 'Abbrechen',
      'cs.front.features': 'Features auf einen Blick',
      'cs.front.description': 'Beschreibung',
      'cs.front.with_this_product_are_often_taken': 'Kunden kauften auch',
      'cs.front.wellcome': 'Willkommen',
      'cs.front.profile_index_note': 'Dies ist Ihr Konto Dashboard, wo Sie die M\u00f6glichkeit haben, Ihre letzten Kontoaktivit\u00e4ten einzusehen',
      'cs.front.personal_data': 'Pers\u00f6nliche Daten',
      'cs.front.change': '\u00c4ndern',
      'cs.front.newsletter': 'Gew\u00e4hlte Newsletter',
      'cs.front.newsletter_note': 'Ja, ich m\u00f6chte den kostenlosen Newsletter erhalten und \u00fcber aktuelle Angebote per E-Mail informiert werden. Eine Abmeldung ist jederzeit m\u00f6glich. Bitte beachten Sie unsere',
      'cs.front.privacy_policy': 'Datenschutzbestimmungen',
      'cs.front.primary_billing_address': 'Prim\u00e4re Rechnungsadresse',
      'cs.front.edit': 'Bearbeiten',
      'cs.front.pickup_address': 'Abholadresse',
      'cs.front.payment_method': 'Gew\u00e4hlte Zahlungsart',
      'cs.front.payment_method_cash': 'Bar oder Kartenzahlung bei Abholung',
      'cs.front.billing_address': 'Rechnungsadresse',
      'cs.front.first_name_and_surname': 'Vor- & Nachname',
      'cs.front.save': 'Speichern',
      'cs.front.saved_alert': '\u00c4nderungen gespeichert',
      'cs.front.date': 'Datum',
      'cs.front.order_status': 'Bestellstatus',
      'cs.front.actions': 'Aktionen',
      'cs.front.order_number': 'Bestellnummer',
      'cs.front.product': 'Artikel',
      'cs.front.quantity': 'Menge',
      'cs.front.profile_menu': 'Men\u00fc',
      'cs.front.overview': '\u00dcbersicht',
      'cs.front.orders': 'Bestellungen',
      'cs.front.addresses': 'Adressen',
      'cs.front.log_out': 'Abmelden',
      'cs.front.clear': 'Filter zur\u00fccksetzen',
      'cs.front.sortiment': 'Sortiment',
      'cs.front.share_on': 'Teilen auf',
      'cs.front.cart': 'Warenkorb',
      'cs.front.products_number': 'Anzahl Artikel',
      'cs.front.total': 'Gesamtsumme',
      'cs.front.to_checkout': 'Zur Kasse',
      'cs.front.edit_shopping_cart': 'Warenkorb bearbeiten',
      'cs.front.checkout_address': 'Ihre Adresse',
      'cs.front.payment_and_shipping': 'Zahlungsart & Versand',
      'cs.front.proof_and_order': 'Pr\u00fcfen & Bestellen',
      'cs.front.create_account_or_login': 'Kundenkonto anlegen oder einloggen',
      'cs.front.do_not_create_customer_account': 'Als Gast bestellen',
      'cs.front.create_account': 'Ich m\u00f6chte als Gast bestellen',
      'cs.front.full_name': 'Ihr Vor- & Nachname',
      'cs.front.city_field_note': '* hierbei handelt es sich um ein Pflichtfeld',
      'cs.front.go_next': 'Weiter',
      'cs.front.i_agree_to_privacy_policy': 'Ich habe die Datenschutzbestimmungen zur Kenntnis genommen.',
      'cs.front.register': 'Weiter',
      'cs.front.payment_on_pickup_note': 'Sie bezahlen Ihre Bestellung bequem bei Abholung bei Ihrem Fachhandelspartner.',
      'cs.front.select_payment_method': 'Zahlungsart w\u00e4hlen',
      'cs.front.main_billing_address': 'Prim\u00e4re Rechnungsadresse',
      'cs.front.new_customer_address': 'Neue Adresse hinzuf\u00fcgen',
      'cs.front.summary_info': 'Zusammenfassung',
      'cs.front.sum': 'Summe',
      'cs.front.delivery_price': 'Versandkosten',
      'cs.front.total_without_tax': 'Gesamtsumme ohne MwSt.',
      'cs.front.including_vat': 'zzgl. MwSt.',
      'cs.front.i_agree_to': 'Hiermit akzeptiere ich die',
      'cs.front.create_order': 'Zahlungspflichtig bestellen',
      'cs.front.thanks_for_order_in': 'Vielen Dank f\u00fcr Ihre Bestellung im Vereinsshop von',
      'cs.front.thank_page_note': 'Wir informieren Sie per E-Mail sobald Ihre Bestellung bearbeitet wurde.',
      'cs.front.to_shop': 'Zur\u00fcck zum Shop',
      'cs.front.view': 'Anzeigen',
      'cs.front.product_number': 'Artikelnummer',
      'cs.front.including': 'zzgl.',
      'cs.front.vat': 'MwSt.',
      'cs.front.email_repeat': 'E-Mail Adresse wiederholen',
      'cs.front.current_passwort': 'Ihr aktuelles Passwort',
      'cs.front.password_required': 'Passwort Wiederholung',
      'cs.front.email_sent': 'E-Mail wurde gesendet',
      'cs.front.order.STATUS_NEW': 'Neu',
      'cs.front.order.STATUS_IN_PROCESS': 'In Bearbeitung',
      'cs.front.order.STATUS_PICKING': 'Wird gepickt',
      'cs.front.order.STATUS_SENT_OUT': 'Versendet',
      'cs.front.order.STATUS_SMU_PROCESS': 'Wird veredelt',
      'cs.front.order.STATUS_READY_FOR_PICKUP': 'Bereit zur Abholung',
      'cs.front.order.STATUS_DONE': 'Abgeschlossen',
      'cs.front.order.STATUS_CANCELED': 'Storniert',
      'cs.front.at': 'ab',
    },
    'fr-FR': {
      'cs.front.phone_is_invalid': 'Veuillez vérifier le numéro de téléphone indiqué',

      'cs.front.only_text_for_all_positions': 'Utiliser le même texte pour tous les {amount_of_products} produits de taille {size} et de couleur {color}.',
      'cs.front.different_text_for_all_positions': 'Utiliser un texte différent pour chacun des {amount_of_products} produits de taille {size} en couleur {color}.',

      'cs.front.warnings.options_text_required': 'Votre sélection contient une option d\'impression avec un texte libre. Vous devez saisir le texte souhaité pour cette option directement dans le menu "Options d\'impression" afin qu\'il puisse être imprimé sur votre produit.',
      'cs.labels.product_image_type_f': 'Vue de face',
      'cs.labels.product_image_type_r': 'Vue de dos',
      'cs.labels.product_image_type_s': 'Vue latérale',
      'cs.labels.product_image_type_d': 'Vue détaillée',

      'cs.labels.available': 'Disponible sur',
      'cs.labels.product_options': 'Options de finition',
      'cs.labels.product_customization': 'Finition',

      'cs.front.search_country': 'Recherche',
      'cs.front.example': 'Exemple',
      'cs.front.scan_qr_using_app_and_wait_up_to_1_min': 'Veuillez scanner le code QR avec votre application Bank/TWINT et attendre environ 60 secondes.',
      'cs.front.payment_token': 'Token',
      'cs.front.payment_method_mollie_paypal': 'Paiement per Paypal',
      'cs.front.payment_method_mollie_twint': 'Paiement per Twint',
      'cs.front.payment_method_mollie_ideal': 'Paiement par iDEAL',
      'cs.front.payment_method_mollie_bankontakt': 'Paiement par Bancontact',
      'cs.front.payment_method_mollie_credit_and_debit_cards': 'Paiement par carte de débit ou de crédit',
      'cs.front.payment_processing_trouble_in': 'Malheureusement, un problème est survenu lors du paiement.',
      'cs.front.payment_processing_trouble_note': 'Veuillez réessayer ou choisir un autre mode de paiement.',

      'cs.front.post': 'Post',
      'cs.front.pickup_at_club_address': '',
      'cs.front.pickup_at_dealer_address': '',
      'cs.front.shipping_method': 'Mode de livraison choisi',
      'cs.front.profile.orders.list.order_address': 'Addresse livraison',
      'cs.front.payment_method_paypal': 'Paypal',
      'cs.front.bye': 'À bientôt!',
      'cs.front.shipping_address': 'Adresse de livraison',
      'cs.front.shipping_address_the_same_with_billing': 'L\'adresse de livraison est identique à l\'adresse de facturation',
      'cs.front.select_shipping_method': 'Veuillez sélectionner le mode de livraison souhaité',
      'cs.front.shipping_cost': 'Frais d\'expédition',
      'cs.front.open_sizes_table': 'Ouvrir le tableau des tailles',
      'cs.front.finishing-comment-file-name': 'Details_impression',
      'cs.front.order_options_text_uploads_selection': 'Je voudrais entrer les informations requises directement au lieu de télécharger un fichier',
      'cs.front.order_options_text_uploads_info': 'Veuillez indiquer ici toutes les informations nécessaires pour les options de personnalisation commandées. Il s\'agit, en plus du numéro d\'article et de la taille, des informations personnelles nécessaires en fonction de l\'option d\'impression choisie (p. ex. nom du joueur, initiales, numéro du joueur, ...).',
      'cs.front.productoptions_modal_file_upload_info': "Veuillez noter que nous avons besoin d'informations précises pour l’impression individuelle dans le processus de commande. Pour cela, nous vous demanderons de télécharger un fichier avec ces détails. En plus du numéro et de la taille de l'article, ce fichier doit contenir les informations personnelles requises pour chaque option de raffinement sélectionnée (par exemple, le nom du joueur, les initiales, le numéro du joueur, ...). Les formats possibles pour cela sont : doc, docx, xls, xlsx, pdf, txt, csv, odc, odt & zip. Alternativement, vous pouvez également saisir ces informations dans un champ de texte.",
      'cs.front.colors.black': 'noir',
      'cs.front.colors.blue': 'bleu',
      'cs.front.colors.brown': 'brun',
      'cs.front.colors.green': 'vert',
      'cs.front.colors.grey': 'gris',
      'cs.front.colors.purple': 'violet',
      'cs.front.colors.red': 'rouge',
      'cs.front.colors.orange': 'orange',
      'cs.front.colors.white': 'blanc',
      'cs.front.colors.yellow': 'jaune',
      'cs.front.password_new': 'Nouveau mot de passe',
      'cs.front.filter_apply': 'Appliquer le filtre',
      'cs.front.sum_options': 'Total impression',
      'cs.front.add_options': 'Ajouter l\'impression',
      'cs.front.total_options_price': 'Total impression',
      'cs.front.productoptions_link_button': 'Sélectionner options d\'impression',
      'cs.front.productoptions_selected_price_total': 'Prix total impression',
      'cs.front.club_products': 'Rechercher dans les articles du club',
      'cs.front.eo_products': 'Rechercher dans la gamme ERIMA',
      'cs.front.no_products_found': 'Aucun article trouvé pour les critères de recherche sélectionnés',
      'cs.front.product_size_in_stock_status_few': 'Taille {size} peu de stock disponible. Retards de livraison possibles.',
      'cs.front.product_size_in_stock_status_many_soon': 'Taille {size} actuellement en rupture de stock. Retour en stock prévu le {next_delivery_date}.',
      'cs.front.product_size_in_stock_status_not_available_now': 'Taille {size} actuellement en rupture de stock.',
      'cs.front.subscribe_to_newsletter': 'S\'inscrire à la Newsletter',
      'cs.front.current_password': 'Votre mot de passe',
      'cs.front.cart_position_options_title': 'Options d\'impression choisies',
      'cs.front.productoptions_link': 'Options d\'impression',
      'cs.front.productoptions_selected': 'sélectionné',
      'cs.front.productoptions_modal_title': 'Options d\'impression disponibles',
      'cs.front.productoptions_modal_total': 'Totale impression',
      'cs.front.checkout_address_select_modal_title': 'Sélectionnez ici l\'adresse souhaitée',
      'cs.front.customer_address': 'Adresse de facturation',
      'cs.front.make_default': 'Utiliser comme standard',
      'cs.front.enter_your_street_and_apartment': 'Veuillez saisir ici votre rue et votre numéro de maison',
      'cs.front.empty_cart': 'Votre panier est vide',
      'cs.front.welcome': 'Bienvenue!',
      'cs.front.order_options_file_uploads_info': 'Veuillez nous fournir un aperçu du contenu souhaité pour toutes les options de finition sélectionnées sous forme de fichier.',
      'cs.front.order_options_file_uploads_button': 'Choisissez un fichier',
      'cs.front.gender_name_m': 'Hommes',
      'cs.front.gender_name_w': 'Femmes',
      'cs.front.gender': 'Sexe',
      'cs.front.search': 'Recherche',
      'cs.front.search_query': 'Entrez un terme de recherche',
      'cs.front.filter': 'Appliquer des filtres',
      'cs.front.colors': 'Couleurs',
      'cs.front.in': 'En',
      'cs.front.phone': 'T\u00e9l\u00e9phone',
      'cs.front.i_am_already_a_customer': 'Je suis d\u00e9j\u00e0 client',
      'cs.front.email': 'Votre adresse e-mail',
      'cs.front.password': 'Votre mot de passe',
      'cs.front.forget_password': 'Mot de passe oubli\u00e9?',
      'cs.front.login': 'Connexion',
      'cs.front.forget_password_field_note': "Nous vous enverrons un e-mail de confirmation. Cliquez sur le lien qu'il contient pour modifier votre mot de passe.",
      'cs.front.send_email': 'Envoyer un e-mail',
      'cs.front.setting_of_new_password': 'Definir un nouveau mot de passe',
      'cs.front.token_from_the_letter': "Code de s\u00e9curit\u00e9 de l'e-mail de r\u00e9initialisation",
      'cs.front.new_password': 'Nouveau mot de passe',
      'cs.front.set_password': 'Changer le mot de passe',
      'cs.front.create_customer': 'Je suis un nouveau client',
      'cs.front.company': 'Entreprise \/ club',
      'cs.front.private_person': 'Client priv\u00e9',
      'cs.front.company_name': "Nom de l'entreprise\/du club",
      'cs.front.enter_your_name': 'Veuillez entrer votre pr\u00e9nom ici',
      'cs.front.enter_company_name': 'Entrez le nom de votre entreprise ou club ici',
      'cs.front.salutation': 'Salutation',
      'cs.front.salutation_mr': 'M.',
      'cs.front.salutation_miss': 'Mme.',
      'cs.front.salutation_na': 'n \/ A',
      'cs.front.first_name': 'Pr\u00e9nom',
      'cs.front.surname': 'Nom de famille',
      'cs.front.enter_your_surname': 'Veuillez entrer votre nom de famille ici',
      'cs.front.subscribe_newsletter': "Je souhaite m'inscrire \u00e0 la newsletter",
      'cs.front.enter_your_password': 'Veuillez saisir ici le mot de passe souhait\u00e9',
      'cs.front.password_field_note': 'Votre mot de passe doit contenir au moins 8 caract\u00e8res. Consid\u00e9rez les majuscules et les minuscules.',
      'cs.front.address': 'Votre adresse',
      'cs.front.street_and_apartment': 'Rue et num\u00e9ro de maison',
      'cs.front.zip': 'Code postal',
      'cs.front.enter_your_zip': 'Veuillez entrer votre code postal ici',
      'cs.front.city': 'Ville',
      'cs.front.enter_your_city': 'Veuillez entrer votre ville ici',
      'cs.front.this_field_is_required': "* C'est un champ obligatoire",
      'cs.front.privacy': 'Confidentialit\u00e9',
      'cs.front.note_our_privacy_agreement': 'Veuillez consulter notre',
      'cs.front.menu': 'Menu',
      'cs.front.back': 'Retour',
      'cs.front.for_individuals': 'Commande unique',
      'cs.front.material': 'Mat\u00e9riel',
      'cs.front.for_teams': "Commande d'\u00e9quipe",
      'cs.front.color': 'Couleur',
      'cs.front.size': 'Taille',
      'cs.front.order_quantity': 'Quantit\u00e9 command\u00e9e',
      'cs.front.add_to_cart': 'Ajouter au panier',
      'cs.front.price': 'Prix',
      'cs.front.total_price': 'Prix \u200b\u200btotal',
      'cs.front.total_quantity': 'Quantit\u00e9 totale',
      'cs.front.cancel': 'Annuler',
      'cs.front.features': 'Aper\u00e7u des caract\u00e9ristiques',
      'cs.front.description': 'Description',
      'cs.front.with_this_product_are_often_taken': 'Les clients ont \u00e9galement achet\u00e9',
      'cs.front.wellcome': 'Bienvenue',
      'cs.front.profile_index_note': "Voici votre tableau de bord de votre compte o\u00f9 vous avez la possibilit\u00e9 d'afficher l'activit\u00e9 r\u00e9cente de votre compte",
      'cs.front.personal_data': 'Donn\u00e9es personnelles',
      'cs.front.change': 'Changer',
      'cs.front.newsletter': 'Newsletters s\u00e9lectionn\u00e9es',
      'cs.front.newsletter_note': 'Oui, je souhaite recevoir la newsletter gratuite et \u00eatre inform\u00e9 des offres en cours par e-mail. Vous pouvez vous d\u00e9sinscrire \u00e0 tout moment. Veuillez consulter notre',
      'cs.front.privacy_policy': 'Politique de confidentialit\u00e9',
      'cs.front.primary_billing_address': 'Adresse de facturation principale',
      'cs.front.edit': '\u00c9diter',
      'cs.front.pickup_address': "Adresse d'enl\u00e8vement",
      'cs.front.payment_method': 'Mode de paiement s\u00e9lectionn\u00e9',
      'cs.front.payment_method_cash': "Paiement en esp\u00e8ces ou par carte \u00e0 l'enl\u00e8vement",
      'cs.front.billing_address': 'Adresse de facturation',
      'cs.front.first_name_and_surname': 'Pr\u00e9nom et nom',
      'cs.front.save': 'Sauvegarder',
      'cs.front.saved_alert': 'Changements sauvegard\u00e9s',
      'cs.front.date': 'Date',
      'cs.front.order_status': 'Statut de la commande',
      'cs.front.actions': 'Actions',
      'cs.front.order_number': 'Num\u00e9ro de commande',
      'cs.front.product': 'Article',
      'cs.front.quantity': 'Quantit\u00e9',
      'cs.front.profile_menu': 'Menu',
      'cs.front.overview': 'Aper\u00e7u',
      'cs.front.orders': 'Ordres',
      'cs.front.addresses': 'Adresses',
      'cs.front.log_out': 'D\u00e9connexion',
      'cs.front.clear': 'R\u00e9initialiser le filtre',
      'cs.front.sortiment': 'Gamme de produits',
      'cs.front.share_on': 'Partager sur',
      'cs.front.cart': 'Panier',
      'cs.front.products_number': "Nombre d'articles",
      'cs.front.total': 'Total',
      'cs.front.to_checkout': 'Passer \u00e0 la caisse',
      'cs.front.edit_shopping_cart': 'Modifier le panier',
      'cs.front.checkout_address': 'Votre adresse',
      'cs.front.payment_and_shipping': 'Paiement et exp\u00e9dition',
      'cs.front.proof_and_order': 'V\u00e9rifier et commander',
      'cs.front.create_account_or_login': 'Cr\u00e9ez un compte client ou connectez-vous',
      'cs.front.do_not_create_customer_account': 'Commander en tant que visiteur',
      'cs.front.create_account': 'Je pr\u00e9f\u00e8re commander en tant que visiteur',
      'cs.front.full_name': 'Votre pr\u00e9nom & nom',
      'cs.front.city_field_note': "* C'est un champ obligatoire",
      'cs.front.go_next': 'Continuer',
      'cs.front.i_agree_to_privacy_policy': "J'ai lu la politique de confidentialit\u00e9.",
      'cs.front.register': 'Continuer',
      'cs.front.payment_on_pickup_note': 'Vous payez facilement votre commande lorsque vous la r\u00e9cup\u00e9rez chez votre revendeur partenaire.',
      'cs.front.select_payment_method': 'Choisissez le mode de paiement',
      'cs.front.main_billing_address': 'Adresse de facturation principale',
      'cs.front.new_customer_address': 'Ajouter nouvelle adresse',
      'cs.front.summary_info': 'Sommaire',
      'cs.front.sum': 'Total',
      'cs.front.delivery_price': "Frais d'\u00e9xp\u00e9dition",
      'cs.front.total_without_tax': 'Total hors TVA',
      'cs.front.including_vat': 'plus TVA',
      'cs.front.i_agree_to': "J'accepte la",
      'cs.front.create_order': 'Commander',
      'cs.front.thanks_for_order_in': 'Merci pour votre commande dans le shop de',
      'cs.front.thank_page_note': 'Nous vous informons par e-mail dès que votre commande a été traitée.',
      'cs.front.to_shop': 'Retour au shop',
      'cs.front.view': 'Afficher',
      'cs.front.product_number': "Num\u00e9ro d'article",
      'cs.front.including': 'plus',
      'cs.front.vat': 'T.V.A.',
      'cs.front.email_repeat': "R\u00e9p\u00e9ter l'adresse e-mail",
      'cs.front.current_passwort': 'Votre mot de passe actuel',
      'cs.front.password_required': 'R\u00e9p\u00e9tition du mot de passe',
      'cs.front.email_sent': 'Email envoy\u00e9',
      'cs.front.order.STATUS_NEW': 'Nouveau',
      'cs.front.order.STATUS_IN_PROCESS': 'En cours',
      'cs.front.order.STATUS_PICKING': 'En commission',
      'cs.front.order.STATUS_SENT_OUT': 'Exp\u00e9di\u00e9',
      'cs.front.order.STATUS_SMU_PROCESS': 'Sera individualis\u00e9',
      'cs.front.order.STATUS_READY_FOR_PICKUP': 'Pr\u00eat \u00e0 \u00eatre r\u00e9cup\u00e9r\u00e9',
      'cs.front.order.STATUS_DONE': 'Compl\u00e9t\u00e9',
      'cs.front.order.STATUS_CANCELED': 'Annul\u00e9',
      'cs.front.at': '\u00e0 partir d',
    },
    'be-FR': {
      'cs.front.phone_is_invalid': 'Veuillez vérifier le numéro de téléphone indiqué',

      'cs.front.only_text_for_all_positions': 'Utiliser le même texte pour tous les {amount_of_products} produits de taille {size} et de couleur {color}.',
      'cs.front.different_text_for_all_positions': 'Utiliser un texte différent pour chacun des {amount_of_products} produits de taille {size} en couleur {color}.',

      'cs.front.warnings.options_text_required': 'Votre sélection contient une option d\'impression avec un texte libre. Vous devez saisir le texte souhaité pour cette option directement dans le menu "Options d\'impression" afin qu\'il puisse être imprimé sur votre produit.',
      'cs.labels.product_image_type_f': 'Vue de face',
      'cs.labels.product_image_type_r': 'Vue de dos',
      'cs.labels.product_image_type_s': 'Vue latérale',
      'cs.labels.product_image_type_d': 'Vue détaillée',

      'cs.labels.available': 'Disponible sur',
      'cs.labels.product_options': 'Options de finition',
      'cs.labels.product_customization': 'Finition',

      'cs.front.search_country': 'Recherche',
      'cs.front.example': 'Exemple',
      'cs.front.scan_qr_using_app_and_wait_up_to_1_min': 'Veuillez scanner le code QR avec votre application Bank/TWINT et attendre environ 60 secondes.',
      'cs.front.payment_token': 'Token',
      'cs.front.payment_method_mollie_paypal': 'Paiement per Paypal',
      'cs.front.payment_method_mollie_twint': 'Paiement per Twint',
      'cs.front.payment_method_mollie_ideal': 'Paiement par iDEAL',
      'cs.front.payment_method_mollie_bankontakt': 'Paiement par Bancontact',
      'cs.front.payment_method_mollie_credit_and_debit_cards': 'Paiement par carte de débit ou de crédit',
      'cs.front.payment_processing_trouble_in': 'Malheureusement, un problème est survenu lors du paiement.',
      'cs.front.payment_processing_trouble_note': 'Veuillez réessayer ou choisir un autre mode de paiement.',

      'cs.front.post': 'Bpost',
      'cs.front.pickup_at_club_address': 'Enlèvement chez le club',
      'cs.front.pickup_at_dealer_address': 'Enlèvement chez le revendeur',
      'cs.front.shipping_method': 'Mode de livraison choisi',
      'cs.front.profile.orders.list.order_address': 'Addresse livraison',
      'cs.front.payment_method_paypal': 'Paypal',
      'cs.front.bye': 'À bientôt!',
      'cs.front.shipping_address': 'Adresse de livraison',
      'cs.front.shipping_address_the_same_with_billing': 'L\'adresse de livraison est identique à l\'adresse de facturation',
      'cs.front.select_shipping_method': 'Veuillez sélectionner le mode de livraison souhaité',
      'cs.front.shipping_cost': 'Frais d\'expédition',
      'cs.front.open_sizes_table': 'Ouvrir le tableau des tailles',
      'cs.front.finishing-comment-file-name': 'Details_impression',
      'cs.front.order_options_text_uploads_selection': 'Je voudrais entrer les informations requises directement au lieu de télécharger un fichier',
      'cs.front.order_options_text_uploads_info': 'Veuillez indiquer ici toutes les informations nécessaires pour les options de personnalisation commandées. Il s\'agit, en plus du numéro d\'article et de la taille, des informations personnelles nécessaires en fonction de l\'option d\'impression choisie (p. ex. nom du joueur, initiales, numéro du joueur, ...).',
      'cs.front.productoptions_modal_file_upload_info': "Veuillez noter que nous avons besoin d'informations précises pour l’impression individuelle dans le processus de commande. Pour cela, nous vous demanderons de télécharger un fichier avec ces détails. En plus du numéro et de la taille de l'article, ce fichier doit contenir les informations personnelles requises pour chaque option de raffinement sélectionnée (par exemple, le nom du joueur, les initiales, le numéro du joueur, ...). Les formats possibles pour cela sont : doc, docx, xls, xlsx, pdf, txt, csv, odc, odt & zip. Alternativement, vous pouvez également saisir ces informations dans un champ de texte.",
      'cs.front.colors.black': 'noir',
      'cs.front.colors.blue': 'bleu',
      'cs.front.colors.brown': 'brun',
      'cs.front.colors.green': 'vert',
      'cs.front.colors.grey': 'gris',
      'cs.front.colors.purple': 'violet',
      'cs.front.colors.red': 'rouge',
      'cs.front.colors.orange': 'orange',
      'cs.front.colors.white': 'blanc',
      'cs.front.colors.yellow': 'jaune',
      'cs.front.password_new': 'Nouveau mot de passe',
      'cs.front.filter_apply': 'Appliquer le filtre',
      'cs.front.sum_options': 'Total impression',
      'cs.front.add_options': 'Ajouter l\'impression',
      'cs.front.total_options_price': 'Total impression',
      'cs.front.productoptions_link_button': 'Sélectionner options d\'impression',
      'cs.front.productoptions_selected_price_total': 'Prix total impression',
      'cs.front.club_products': 'Rechercher dans les articles du club',
      'cs.front.eo_products': 'Rechercher dans la gamme ERIMA',
      'cs.front.no_products_found': 'Aucun article trouvé pour les critères de recherche sélectionnés',
      'cs.front.product_size_in_stock_status_few': 'Taille {size} peu de stock disponible. Retards de livraison possibles.',
      'cs.front.product_size_in_stock_status_many_soon': 'Taille {size} actuellement en rupture de stock. Retour en stock prévu le {next_delivery_date}.',
      'cs.front.product_size_in_stock_status_not_available_now': 'Taille {size} actuellement en rupture de stock.',
      'cs.front.subscribe_to_newsletter': 'S\'inscrire à la Newsletter',
      'cs.front.current_password': 'Votre mot de passe',
      'cs.front.cart_position_options_title': 'Options d\'impression choisies',
      'cs.front.productoptions_link': 'Options d\'impression',
      'cs.front.productoptions_selected': 'sélectionné',
      'cs.front.productoptions_modal_title': 'Options d\'impression disponibles',
      'cs.front.productoptions_modal_total': 'Totale impression',
      'cs.front.checkout_address_select_modal_title': 'Sélectionnez ici l\'adresse souhaitée',
      'cs.front.customer_address': 'Adresse de facturation',
      'cs.front.make_default': 'Utiliser comme standard',
      'cs.front.enter_your_street_and_apartment': 'Veuillez saisir ici votre rue et votre numéro de maison',
      'cs.front.empty_cart': 'Votre panier est vide',
      'cs.front.welcome': 'Bienvenue!',
      'cs.front.order_options_file_uploads_info': 'Veuillez nous fournir un aperçu du contenu souhaité pour toutes les options de finition sélectionnées sous forme de fichier.',
      'cs.front.order_options_file_uploads_button': 'Choisissez un fichier',
      'cs.front.gender_name_m': 'Hommes',
      'cs.front.gender_name_w': 'Femmes',
      'cs.front.gender': 'Sexe',
      'cs.front.search': 'Recherche',
      'cs.front.search_query': 'Entrez un terme de recherche',
      'cs.front.filter': 'Appliquer des filtres',
      'cs.front.colors': 'Couleurs',
      'cs.front.in': 'En',
      'cs.front.phone': 'T\u00e9l\u00e9phone',
      'cs.front.i_am_already_a_customer': 'Je suis d\u00e9j\u00e0 client',
      'cs.front.email': 'Votre adresse e-mail',
      'cs.front.password': 'Votre mot de passe',
      'cs.front.forget_password': 'Mot de passe oubli\u00e9?',
      'cs.front.login': 'Connexion',
      'cs.front.forget_password_field_note': "Nous vous enverrons un e-mail de confirmation. Cliquez sur le lien qu'il contient pour modifier votre mot de passe.",
      'cs.front.send_email': 'Envoyer un e-mail',
      'cs.front.setting_of_new_password': 'Definir un nouveau mot de passe',
      'cs.front.token_from_the_letter': "Code de s\u00e9curit\u00e9 de l'e-mail de r\u00e9initialisation",
      'cs.front.new_password': 'Nouveau mot de passe',
      'cs.front.set_password': 'Changer le mot de passe',
      'cs.front.create_customer': 'Je suis un nouveau client',
      'cs.front.company': 'Entreprise \/ club',
      'cs.front.private_person': 'Client priv\u00e9',
      'cs.front.company_name': "Nom de l'entreprise\/du club",
      'cs.front.enter_your_name': 'Veuillez entrer votre pr\u00e9nom ici',
      'cs.front.enter_company_name': 'Entrez le nom de votre entreprise ou club ici',
      'cs.front.salutation': 'Salutation',
      'cs.front.salutation_mr': 'M.',
      'cs.front.salutation_miss': 'Mme.',
      'cs.front.salutation_na': 'n \/ A',
      'cs.front.first_name': 'Pr\u00e9nom',
      'cs.front.surname': 'Nom de famille',
      'cs.front.enter_your_surname': 'Veuillez entrer votre nom de famille ici',
      'cs.front.subscribe_newsletter': "Je souhaite m'inscrire \u00e0 la newsletter",
      'cs.front.enter_your_password': 'Veuillez saisir ici le mot de passe souhait\u00e9',
      'cs.front.password_field_note': 'Votre mot de passe doit contenir au moins 8 caract\u00e8res. Consid\u00e9rez les majuscules et les minuscules.',
      'cs.front.address': 'Votre adresse',
      'cs.front.street_and_apartment': 'Rue et num\u00e9ro de maison',
      'cs.front.zip': 'Code postal',
      'cs.front.enter_your_zip': 'Veuillez entrer votre code postal ici',
      'cs.front.city': 'Ville',
      'cs.front.enter_your_city': 'Veuillez entrer votre ville ici',
      'cs.front.this_field_is_required': "* C'est un champ obligatoire",
      'cs.front.privacy': 'Confidentialit\u00e9',
      'cs.front.note_our_privacy_agreement': 'Veuillez consulter notre',
      'cs.front.menu': 'Menu',
      'cs.front.back': 'Retour',
      'cs.front.for_individuals': 'Commande unique',
      'cs.front.material': 'Mat\u00e9riel',
      'cs.front.for_teams': "Commande d'\u00e9quipe",
      'cs.front.color': 'Couleur',
      'cs.front.size': 'Taille',
      'cs.front.order_quantity': 'Quantit\u00e9 command\u00e9e',
      'cs.front.add_to_cart': 'Ajouter au panier',
      'cs.front.price': 'Prix',
      'cs.front.total_price': 'Prix \u200b\u200btotal',
      'cs.front.total_quantity': 'Quantit\u00e9 totale',
      'cs.front.cancel': 'Annuler',
      'cs.front.features': 'Aper\u00e7u des caract\u00e9ristiques',
      'cs.front.description': 'Description',
      'cs.front.with_this_product_are_often_taken': 'Les clients ont \u00e9galement achet\u00e9',
      'cs.front.wellcome': 'Bienvenue',
      'cs.front.profile_index_note': "Voici votre tableau de bord de votre compte o\u00f9 vous avez la possibilit\u00e9 d'afficher l'activit\u00e9 r\u00e9cente de votre compte",
      'cs.front.personal_data': 'Donn\u00e9es personnelles',
      'cs.front.change': 'Changer',
      'cs.front.newsletter': 'Newsletters s\u00e9lectionn\u00e9es',
      'cs.front.newsletter_note': 'Oui, je souhaite recevoir la newsletter gratuite et \u00eatre inform\u00e9 des offres en cours par e-mail. Vous pouvez vous d\u00e9sinscrire \u00e0 tout moment. Veuillez consulter notre',
      'cs.front.privacy_policy': 'Politique de confidentialit\u00e9',
      'cs.front.primary_billing_address': 'Adresse de facturation principale',
      'cs.front.edit': '\u00c9diter',
      'cs.front.pickup_address': "Adresse d'enl\u00e8vement",
      'cs.front.payment_method': 'Mode de paiement s\u00e9lectionn\u00e9',
      'cs.front.payment_method_cash': "Paiement en esp\u00e8ces ou par carte \u00e0 l'enl\u00e8vement",
      'cs.front.billing_address': 'Adresse de facturation',
      'cs.front.first_name_and_surname': 'Pr\u00e9nom et nom',
      'cs.front.save': 'Sauvegarder',
      'cs.front.saved_alert': 'Changements sauvegard\u00e9s',
      'cs.front.date': 'Date',
      'cs.front.order_status': 'Statut de la commande',
      'cs.front.actions': 'Actions',
      'cs.front.order_number': 'Num\u00e9ro de commande',
      'cs.front.product': 'Article',
      'cs.front.quantity': 'Quantit\u00e9',
      'cs.front.profile_menu': 'Menu',
      'cs.front.overview': 'Aper\u00e7u',
      'cs.front.orders': 'Ordres',
      'cs.front.addresses': 'Adresses',
      'cs.front.log_out': 'D\u00e9connexion',
      'cs.front.clear': 'R\u00e9initialiser le filtre',
      'cs.front.sortiment': 'Gamme de produits',
      'cs.front.share_on': 'Partager sur',
      'cs.front.cart': 'Panier',
      'cs.front.products_number': "Nombre d'articles",
      'cs.front.total': 'Total',
      'cs.front.to_checkout': 'Passer \u00e0 la caisse',
      'cs.front.edit_shopping_cart': 'Modifier le panier',
      'cs.front.checkout_address': 'Votre adresse',
      'cs.front.payment_and_shipping': 'Paiement et exp\u00e9dition',
      'cs.front.proof_and_order': 'V\u00e9rifier et commander',
      'cs.front.create_account_or_login': 'Cr\u00e9ez un compte client ou connectez-vous',
      'cs.front.do_not_create_customer_account': 'Commander en tant que visiteur',
      'cs.front.create_account': 'Je pr\u00e9f\u00e8re commander en tant que visiteur',
      'cs.front.full_name': 'Votre pr\u00e9nom & nom',
      'cs.front.city_field_note': "* C'est un champ obligatoire",
      'cs.front.go_next': 'Continuer',
      'cs.front.i_agree_to_privacy_policy': "J'ai lu la politique de confidentialit\u00e9.",
      'cs.front.register': 'Continuer',
      'cs.front.payment_on_pickup_note': 'Vous payez facilement votre commande lorsque vous la r\u00e9cup\u00e9rez chez votre revendeur partenaire.',
      'cs.front.select_payment_method': 'Choisissez le mode de paiement',
      'cs.front.main_billing_address': 'Adresse de facturation principale',
      'cs.front.new_customer_address': 'Ajouter nouvelle adresse',
      'cs.front.summary_info': 'Sommaire',
      'cs.front.sum': 'Total',
      'cs.front.delivery_price': "Frais d'\u00e9xp\u00e9dition",
      'cs.front.total_without_tax': 'Total hors TVA',
      'cs.front.including_vat': 'plus TVA',
      'cs.front.i_agree_to': "J'accepte la",
      'cs.front.create_order': 'Commander',
      'cs.front.thanks_for_order_in': 'Merci pour votre commande dans le shop de',
      'cs.front.thank_page_note': 'Nous vous informons par e-mail dès que votre commande a été traitée.',
      'cs.front.to_shop': 'Retour au shop',
      'cs.front.view': 'Afficher',
      'cs.front.product_number': "Num\u00e9ro d'article",
      'cs.front.including': 'plus',
      'cs.front.vat': 'T.V.A.',
      'cs.front.email_repeat': "R\u00e9p\u00e9ter l'adresse e-mail",
      'cs.front.current_passwort': 'Votre mot de passe actuel',
      'cs.front.password_required': 'R\u00e9p\u00e9tition du mot de passe',
      'cs.front.email_sent': 'Email envoy\u00e9',
      'cs.front.order.STATUS_NEW': 'Nouveau',
      'cs.front.order.STATUS_IN_PROCESS': 'En cours',
      'cs.front.order.STATUS_PICKING': 'En commission',
      'cs.front.order.STATUS_SENT_OUT': 'Exp\u00e9di\u00e9',
      'cs.front.order.STATUS_SMU_PROCESS': 'Sera individualis\u00e9',
      'cs.front.order.STATUS_READY_FOR_PICKUP': 'Pr\u00eat \u00e0 \u00eatre r\u00e9cup\u00e9r\u00e9',
      'cs.front.order.STATUS_DONE': 'Compl\u00e9t\u00e9',
      'cs.front.order.STATUS_CANCELED': 'Annul\u00e9',
      'cs.front.at': '\u00e0 partir d',
    },
    'ch-FR': {
      'cs.front.phone_is_invalid': 'Veuillez vérifier le numéro de téléphone indiqué',

      'cs.front.only_text_for_all_positions': 'Utiliser le même texte pour tous les {amount_of_products} produits de taille {size} et de couleur {color}.',
      'cs.front.different_text_for_all_positions': 'Utiliser un texte différent pour chacun des {amount_of_products} produits de taille {size} en couleur {color}.',

      'cs.front.warnings.options_text_required': 'Votre sélection contient une option d\'impression avec un texte libre. Vous devez saisir le texte souhaité pour cette option directement dans le menu "Options d\'impression" afin qu\'il puisse être imprimé sur votre produit.',
      'cs.labels.product_image_type_f': 'Vue de face',
      'cs.labels.product_image_type_r': 'Vue de dos',
      'cs.labels.product_image_type_s': 'Vue latérale',
      'cs.labels.product_image_type_d': 'Vue détaillée',

      'cs.labels.available': 'Disponible sur',
      'cs.labels.product_options': 'Options de finition',
      'cs.labels.product_customization': 'Finition',

      'cs.front.search_country': 'Recherche',
      'cs.front.example': 'Exemple',
      'cs.front.scan_qr_using_app_and_wait_up_to_1_min': 'Veuillez scanner le code QR avec votre application Bank/TWINT et attendre environ 60 secondes.',
      'cs.front.payment_token': 'Token',
      'cs.front.payment_method_mollie_paypal': 'Paiement per Paypal',
      'cs.front.payment_method_mollie_twint': 'Paiement per Twint',
      'cs.front.payment_method_mollie_ideal': 'Paiement par iDEAL',
      'cs.front.payment_method_mollie_bankontakt': 'Paiement par Bancontact',
      'cs.front.payment_method_mollie_credit_and_debit_cards': 'Paiement par carte de débit ou de crédit',
      'cs.front.payment_processing_trouble_in': 'Malheureusement, un problème est survenu lors du paiement.',
      'cs.front.payment_processing_trouble_note': 'Veuillez réessayer ou choisir un autre mode de paiement.',

      'cs.front.post': 'Poste CH',
      'cs.front.pickup_at_club_address': 'Enlèvement chez le club',
      'cs.front.pickup_at_dealer_address': 'Enlèvement chez le revendeur',
      'cs.front.shipping_method': 'Mode de livraison choisi',
      'cs.front.profile.orders.list.order_address': 'Addresse livraison',
      'cs.front.payment_method_paypal': 'Paypal',
      'cs.front.bye': 'À bientôt!',
      'cs.front.shipping_address': 'Adresse de livraison',
      'cs.front.shipping_address_the_same_with_billing': 'L\'adresse de livraison est identique à l\'adresse de facturation',
      'cs.front.select_shipping_method': 'Veuillez sélectionner le mode de livraison souhaité',
      'cs.front.shipping_cost': 'Frais d\'expédition',
      'cs.front.open_sizes_table': 'Ouvrir le tableau des tailles',
      'cs.front.finishing-comment-file-name': 'Details_impression',
      'cs.front.order_options_text_uploads_selection': 'Je voudrais entrer les informations requises directement au lieu de télécharger un fichier',
      'cs.front.order_options_text_uploads_info': 'Veuillez indiquer ici toutes les informations nécessaires pour les options de personnalisation commandées. Il s\'agit, en plus du numéro d\'article et de la taille, des informations personnelles nécessaires en fonction de l\'option d\'impression choisie (p. ex. nom du joueur, initiales, numéro du joueur, ...).',
      'cs.front.productoptions_modal_file_upload_info': "Veuillez noter que nous avons besoin d'informations précises pour l’impression individuelle dans le processus de commande. Pour cela, nous vous demanderons de télécharger un fichier avec ces détails. En plus du numéro et de la taille de l'article, ce fichier doit contenir les informations personnelles requises pour chaque option de raffinement sélectionnée (par exemple, le nom du joueur, les initiales, le numéro du joueur, ...). Les formats possibles pour cela sont : doc, docx, xls, xlsx, pdf, txt, csv, odc, odt & zip. Alternativement, vous pouvez également saisir ces informations dans un champ de texte.",
      'cs.front.colors.black': 'noir',
      'cs.front.colors.blue': 'bleu',
      'cs.front.colors.brown': 'brun',
      'cs.front.colors.green': 'vert',
      'cs.front.colors.grey': 'gris',
      'cs.front.colors.purple': 'violet',
      'cs.front.colors.red': 'rouge',
      'cs.front.colors.orange': 'orange',
      'cs.front.colors.white': 'blanc',
      'cs.front.colors.yellow': 'jaune',
      'cs.front.password_new': 'Nouveau mot de passe',
      'cs.front.filter_apply': 'Appliquer le filtre',
      'cs.front.sum_options': 'Total impression',
      'cs.front.add_options': 'Ajouter l\'impression',
      'cs.front.total_options_price': 'Total impression',
      'cs.front.productoptions_link_button': 'Sélectionner options d\'impression',
      'cs.front.productoptions_selected_price_total': 'Prix total impression',
      'cs.front.club_products': 'Rechercher dans les articles du club',
      'cs.front.eo_products': 'Rechercher dans la gamme ERIMA',
      'cs.front.no_products_found': 'Aucun article trouvé pour les critères de recherche sélectionnés',
      'cs.front.product_size_in_stock_status_few': 'Taille {size} peu de stock disponible. Retards de livraison possibles.',
      'cs.front.product_size_in_stock_status_many_soon': 'Taille {size} actuellement en rupture de stock. Retour en stock prévu le {next_delivery_date}.',
      'cs.front.product_size_in_stock_status_not_available_now': 'Taille {size} actuellement en rupture de stock.',
      'cs.front.subscribe_to_newsletter': 'S\'inscrire à la Newsletter',
      'cs.front.current_password': 'Votre mot de passe',
      'cs.front.cart_position_options_title': 'Options d\'impression choisies',
      'cs.front.productoptions_link': 'Options d\'impression',
      'cs.front.productoptions_selected': 'sélectionné',
      'cs.front.productoptions_modal_title': 'Options d\'impression disponibles',
      'cs.front.productoptions_modal_total': 'Totale impression',
      'cs.front.checkout_address_select_modal_title': 'Sélectionnez ici l\'adresse souhaitée',
      'cs.front.customer_address': 'Adresse de facturation',
      'cs.front.make_default': 'Utiliser comme standard',
      'cs.front.enter_your_street_and_apartment': 'Veuillez saisir ici votre rue et votre numéro de maison',
      'cs.front.empty_cart': 'Votre panier est vide',
      'cs.front.welcome': 'Bienvenue!',
      'cs.front.order_options_file_uploads_info': 'Veuillez nous fournir un aperçu du contenu souhaité pour toutes les options de finition sélectionnées sous forme de fichier.',
      'cs.front.order_options_file_uploads_button': 'Choisissez un fichier',
      'cs.front.gender_name_m': 'Hommes',
      'cs.front.gender_name_w': 'Femmes',
      'cs.front.gender': 'Sexe',
      'cs.front.search': 'Recherche',
      'cs.front.search_query': 'Entrez un terme de recherche',
      'cs.front.filter': 'Appliquer des filtres',
      'cs.front.colors': 'Couleurs',
      'cs.front.in': 'En',
      'cs.front.phone': 'T\u00e9l\u00e9phone',
      'cs.front.i_am_already_a_customer': 'Je suis d\u00e9j\u00e0 client',
      'cs.front.email': 'Votre adresse e-mail',
      'cs.front.password': 'Votre mot de passe',
      'cs.front.forget_password': 'Mot de passe oubli\u00e9?',
      'cs.front.login': 'Connexion',
      'cs.front.forget_password_field_note': "Nous vous enverrons un e-mail de confirmation. Cliquez sur le lien qu'il contient pour modifier votre mot de passe.",
      'cs.front.send_email': 'Envoyer un e-mail',
      'cs.front.setting_of_new_password': 'Definir un nouveau mot de passe',
      'cs.front.token_from_the_letter': "Code de s\u00e9curit\u00e9 de l'e-mail de r\u00e9initialisation",
      'cs.front.new_password': 'Nouveau mot de passe',
      'cs.front.set_password': 'Changer le mot de passe',
      'cs.front.create_customer': 'Je suis un nouveau client',
      'cs.front.company': 'Entreprise \/ club',
      'cs.front.private_person': 'Client priv\u00e9',
      'cs.front.company_name': "Nom de l'entreprise\/du club",
      'cs.front.enter_your_name': 'Veuillez entrer votre pr\u00e9nom ici',
      'cs.front.enter_company_name': 'Entrez le nom de votre entreprise ou club ici',
      'cs.front.salutation': 'Salutation',
      'cs.front.salutation_mr': 'M.',
      'cs.front.salutation_miss': 'Mme.',
      'cs.front.salutation_na': 'n \/ A',
      'cs.front.first_name': 'Pr\u00e9nom',
      'cs.front.surname': 'Nom de famille',
      'cs.front.enter_your_surname': 'Veuillez entrer votre nom de famille ici',
      'cs.front.subscribe_newsletter': "Je souhaite m'inscrire \u00e0 la newsletter",
      'cs.front.enter_your_password': 'Veuillez saisir ici le mot de passe souhait\u00e9',
      'cs.front.password_field_note': 'Votre mot de passe doit contenir au moins 8 caract\u00e8res. Consid\u00e9rez les majuscules et les minuscules.',
      'cs.front.address': 'Votre adresse',
      'cs.front.street_and_apartment': 'Rue et num\u00e9ro de maison',
      'cs.front.zip': 'Code postal',
      'cs.front.enter_your_zip': 'Veuillez entrer votre code postal ici',
      'cs.front.city': 'Ville',
      'cs.front.enter_your_city': 'Veuillez entrer votre ville ici',
      'cs.front.this_field_is_required': "* C'est un champ obligatoire",
      'cs.front.privacy': 'Confidentialit\u00e9',
      'cs.front.note_our_privacy_agreement': 'Veuillez consulter notre',
      'cs.front.menu': 'Menu',
      'cs.front.back': 'Retour',
      'cs.front.for_individuals': 'Commande unique',
      'cs.front.material': 'Mat\u00e9riel',
      'cs.front.for_teams': "Commande d'\u00e9quipe",
      'cs.front.color': 'Couleur',
      'cs.front.size': 'Taille',
      'cs.front.order_quantity': 'Quantit\u00e9 command\u00e9e',
      'cs.front.add_to_cart': 'Ajouter au panier',
      'cs.front.price': 'Prix',
      'cs.front.total_price': 'Prix \u200b\u200btotal',
      'cs.front.total_quantity': 'Quantit\u00e9 totale',
      'cs.front.cancel': 'Annuler',
      'cs.front.features': 'Aper\u00e7u des caract\u00e9ristiques',
      'cs.front.description': 'Description',
      'cs.front.with_this_product_are_often_taken': 'Les clients ont \u00e9galement achet\u00e9',
      'cs.front.wellcome': 'Bienvenue',
      'cs.front.profile_index_note': "Voici votre tableau de bord de votre compte o\u00f9 vous avez la possibilit\u00e9 d'afficher l'activit\u00e9 r\u00e9cente de votre compte",
      'cs.front.personal_data': 'Donn\u00e9es personnelles',
      'cs.front.change': 'Changer',
      'cs.front.newsletter': 'Newsletters s\u00e9lectionn\u00e9es',
      'cs.front.newsletter_note': 'Oui, je souhaite recevoir la newsletter gratuite et \u00eatre inform\u00e9 des offres en cours par e-mail. Vous pouvez vous d\u00e9sinscrire \u00e0 tout moment. Veuillez consulter notre',
      'cs.front.privacy_policy': 'Politique de confidentialit\u00e9',
      'cs.front.primary_billing_address': 'Adresse de facturation principale',
      'cs.front.edit': '\u00c9diter',
      'cs.front.pickup_address': "Adresse d'enl\u00e8vement",
      'cs.front.payment_method': 'Mode de paiement s\u00e9lectionn\u00e9',
      'cs.front.payment_method_cash': "Paiement en esp\u00e8ces ou par carte \u00e0 l'enl\u00e8vement",
      'cs.front.billing_address': 'Adresse de facturation',
      'cs.front.first_name_and_surname': 'Pr\u00e9nom et nom',
      'cs.front.save': 'Sauvegarder',
      'cs.front.saved_alert': 'Changements sauvegard\u00e9s',
      'cs.front.date': 'Date',
      'cs.front.order_status': 'Statut de la commande',
      'cs.front.actions': 'Actions',
      'cs.front.order_number': 'Num\u00e9ro de commande',
      'cs.front.product': 'Article',
      'cs.front.quantity': 'Quantit\u00e9',
      'cs.front.profile_menu': 'Menu',
      'cs.front.overview': 'Aper\u00e7u',
      'cs.front.orders': 'Ordres',
      'cs.front.addresses': 'Adresses',
      'cs.front.log_out': 'D\u00e9connexion',
      'cs.front.clear': 'R\u00e9initialiser le filtre',
      'cs.front.sortiment': 'Gamme de produits',
      'cs.front.share_on': 'Partager sur',
      'cs.front.cart': 'Panier',
      'cs.front.products_number': "Nombre d'articles",
      'cs.front.total': 'Total',
      'cs.front.to_checkout': 'Passer \u00e0 la caisse',
      'cs.front.edit_shopping_cart': 'Modifier le panier',
      'cs.front.checkout_address': 'Votre adresse',
      'cs.front.payment_and_shipping': 'Paiement et exp\u00e9dition',
      'cs.front.proof_and_order': 'V\u00e9rifier et commander',
      'cs.front.create_account_or_login': 'Cr\u00e9ez un compte client ou connectez-vous',
      'cs.front.do_not_create_customer_account': 'Commander en tant que visiteur',
      'cs.front.create_account': 'Je pr\u00e9f\u00e8re commander en tant que visiteur',
      'cs.front.full_name': 'Votre pr\u00e9nom & nom',
      'cs.front.city_field_note': "* C'est un champ obligatoire",
      'cs.front.go_next': 'Continuer',
      'cs.front.i_agree_to_privacy_policy': "J'ai lu la politique de confidentialit\u00e9.",
      'cs.front.register': 'Continuer',
      'cs.front.payment_on_pickup_note': 'Vous payez facilement votre commande lorsque vous la r\u00e9cup\u00e9rez chez votre revendeur partenaire.',
      'cs.front.select_payment_method': 'Choisissez le mode de paiement',
      'cs.front.main_billing_address': 'Adresse de facturation principale',
      'cs.front.new_customer_address': 'Ajouter nouvelle adresse',
      'cs.front.summary_info': 'Sommaire',
      'cs.front.sum': 'Total',
      'cs.front.delivery_price': "Frais d'\u00e9xp\u00e9dition",
      'cs.front.total_without_tax': 'Total hors TVA',
      'cs.front.including_vat': 'plus TVA',
      'cs.front.i_agree_to': "J'accepte la",
      'cs.front.create_order': 'Commander',
      'cs.front.thanks_for_order_in': 'Merci pour votre commande dans le shop de',
      'cs.front.thank_page_note': 'Nous vous informons par e-mail dès que votre commande a été traitée.',
      'cs.front.to_shop': 'Retour au shop',
      'cs.front.view': 'Afficher',
      'cs.front.product_number': "Num\u00e9ro d'article",
      'cs.front.including': 'plus',
      'cs.front.vat': 'T.V.A.',
      'cs.front.email_repeat': "R\u00e9p\u00e9ter l'adresse e-mail",
      'cs.front.current_passwort': 'Votre mot de passe actuel',
      'cs.front.password_required': 'R\u00e9p\u00e9tition du mot de passe',
      'cs.front.email_sent': 'Email envoy\u00e9',
      'cs.front.order.STATUS_NEW': 'Nouveau',
      'cs.front.order.STATUS_IN_PROCESS': 'En cours',
      'cs.front.order.STATUS_PICKING': 'En commission',
      'cs.front.order.STATUS_SENT_OUT': 'Exp\u00e9di\u00e9',
      'cs.front.order.STATUS_SMU_PROCESS': 'Sera individualis\u00e9',
      'cs.front.order.STATUS_READY_FOR_PICKUP': 'Pr\u00eat \u00e0 \u00eatre r\u00e9cup\u00e9r\u00e9',
      'cs.front.order.STATUS_DONE': 'Compl\u00e9t\u00e9',
      'cs.front.order.STATUS_CANCELED': 'Annul\u00e9',
      'cs.front.at': '\u00e0 partir d',
    },
    'nl-NL': {
      'cs.front.phone_is_invalid': 'Controleer het opgegeven telefoonnummer',

      'cs.front.only_text_for_all_positions': 'Gebruik dezelfde tekst voor alle {amount_of_products} producten van maat {size} in kleur {color}.',
      'cs.front.different_text_for_all_positions': 'Gebruik een andere tekst voor elk van de {amount_of_products} producten van grootte {size} in kleur {color}.',

      'cs.front.warnings.options_text_required': 'Je selectie bevat een opdrukoptie met een vrije tekst. Je moet de gewenste tekst voor deze optie direct in het menu "Opdrukopties" invoeren, zodat deze op je product kan worden afgedrukt.',
      'cs.labels.product_image_type_f': 'Vooraanzicht',
      'cs.labels.product_image_type_r': 'Achteraanzicht',
      'cs.labels.product_image_type_s': 'Zijaanzicht',
      'cs.labels.product_image_type_d': 'Detailweergave',

      'cs.labels.available': 'Beschikbaar',
      'cs.labels.product_options': 'Afwerkingsmogelijkheden',
      'cs.labels.product_customization': 'Afwerking',

      'cs.front.search_country': 'Zoek op',
      'cs.front.example': 'Voorbeeld',
      'cs.front.scan_qr_using_app_and_wait_up_to_1_min': 'Scan de QR-code met je bank/TWINT-app en wacht ongeveer 60 seconden.',
      'cs.front.payment_token': 'Token',
      'cs.front.payment_method_mollie_paypal': 'Betaling via Paypal',
      'cs.front.payment_method_mollie_twint': 'Betaling via Twint',
      'cs.front.payment_method_mollie_ideal': 'Betaling via iDEAL',
      'cs.front.payment_method_mollie_bankontakt': 'Betaling via Bancontact',
      'cs.front.payment_method_mollie_credit_and_debit_cards': 'Betaling via debetkaart of creditcard',
      'cs.front.payment_processing_trouble_in': 'Helaas was er een probleem met de betaling.',
      'cs.front.payment_processing_trouble_note': 'Probeer het opnieuw of selecteer een andere betaalmethode.',

      'cs.front.post': 'Post',
      'cs.front.pickup_at_club_address': '',
      'cs.front.pickup_at_dealer_address': '',
      'cs.front.shipping_method': 'Geselecteerde Verzendmethode',
      'cs.front.profile.orders.list.order_address': 'Leveringsadres',
      'cs.front.payment_method_paypal': 'Paypal',
      'cs.front.bye': 'Tot ziens!',
      'cs.front.shipping_address': 'Leveringsadres',
      'cs.front.shipping_address_the_same_with_billing': 'Het leveringsadres is identiek aan het factuuradres',
      'cs.front.select_shipping_method': 'Selecteer de gewenste verzendmethode',
      'cs.front.shipping_cost': 'Verzendkosten',
      'cs.front.open_sizes_table': 'Open maattabel',
      'cs.front.finishing-comment-file-name': 'Opdruk_details',
      'cs.front.order_options_text_uploads_selection': 'Ik wil de vereiste informatie rechtstreeks invoeren in plaats van een bestand te uploaden.',
      'cs.front.order_options_text_uploads_info': 'Vul hier alle noodzakelijke informatie in voor de bestelde opdrukopties. Naast het artikelnummer en de maat, bevat dit de vereiste persoonlijke gegevens voor elke geselecteerde opdrukoptie (bv. spelersnaam, initialen, spelersnummer, ...).',
      'cs.front.productoptions_modal_file_upload_info': 'Houd er rekening mee dat we nauwkeurige informatie nodig hebben voor individuele verfijning in het bestelproces. Hiervoor zullen wij u vragen een bestand met deze gegevens te uploaden. Naast het itemnummer en de grootte, moet dit bestand de vereiste persoonlijke informatie bevatten voor elke geselecteerde opdrukoptie (bijv. naam speler, initialen, spelernummer, ...). Mogelijke formaten hiervoor zijn: doc, docx, xls, xlsx, pdf, txt, csv, odc, odt & zip. Als alternatief kunt u deze informatie ook in een tekstveld invullen.',
      'cs.front.colors.black': 'zwart',
      'cs.front.colors.blue': 'blauw',
      'cs.front.colors.brown': 'bruin',
      'cs.front.colors.green': 'groen',
      'cs.front.colors.grey': 'grijs',
      'cs.front.colors.purple': 'paars',
      'cs.front.colors.red': 'rood',
      'cs.front.colors.orange': 'oranje',
      'cs.front.colors.white': 'wit',
      'cs.front.colors.yellow': 'geel',
      'cs.front.password_new': 'Jouw wachtwoord',
      'cs.front.filter_apply': 'Filter toepassen',
      'cs.front.sum_options': 'Totale opdruk',
      'cs.front.add_options': 'Opdruk toevoegen',
      'cs.front.total_options_price': 'Totaal opdruk',
      'cs.front.productoptions_link_button': 'Opdrukopties kiezen',
      'cs.front.productoptions_selected_price_total': 'Totale prijs opdruk',
      'cs.front.club_products': 'Zoeken in clubartikelen',
      'cs.front.eo_products': 'Zoeken in het ERIMA-assortiment',
      'cs.front.no_products_found': 'Bij dit zoekcriterium werden geen producten gevonden',
      'cs.front.product_size_in_stock_status_few': 'Maat {size} laag op voorraad. Vertraging in levering mogelijk.',
      'cs.front.product_size_in_stock_status_many_soon': 'Maat {size} momenteel niet op voorraad. Verwacht weer op voorraad op {next_delivery_date}.',
      'cs.front.product_size_in_stock_status_not_available_now': 'Maat {size} momenteel niet op voorraad.',
      'cs.front.subscribe_to_newsletter': 'Abonneren op de nieuwsbrief',
      'cs.front.current_password': 'Jouw wachtwoord',
      'cs.front.cart_position_options_title': 'Gekozen opdrukopties',
      'cs.front.productoptions_link': 'Opdrukopties',
      'cs.front.productoptions_selected': 'geselecteerd',
      'cs.front.productoptions_modal_title': 'Beschikbare Opdrukopties',
      'cs.front.productoptions_modal_total': 'Totale Opdruk',
      'cs.front.checkout_address_select_modal_title': 'Selecteer hier het gewenste adres',
      'cs.front.customer_address': 'Facturatie adres',
      'cs.front.make_default': 'Gebruik als een standaard',
      'cs.front.enter_your_street_and_apartment': 'Vul hier jouw straat en huisnummer in',
      'cs.front.empty_cart': 'Uw winkelwagen is leeg',
      'cs.front.welcome': 'Welkom!',
      'cs.front.order_options_file_uploads_info': 'Geef ons een overzicht van de gewenste inhoud voor alle geselecteerde opdrukopties als bestand.',
      'cs.front.order_options_file_uploads_button': 'Kies een bestand',
      'cs.front.gender_name_m': 'Heren',
      'cs.front.gender_name_w': 'Dames',
      'cs.front.gender': 'Geslacht',
      'cs.front.search': 'Zoeken',
      'cs.front.search_query': 'Voer een zoekterm in',
      'cs.front.filter': 'Filters toepassen',
      'cs.front.colors': 'Kleuren',
      'cs.front.in': 'in',
      'cs.front.phone': 'Telefoon',
      'cs.front.i_am_already_a_customer': 'Ik ben al klant',
      'cs.front.email': 'Jouw e-mailadres',
      'cs.front.password': 'Jouw wachtwoord',
      'cs.front.forget_password': 'Wachtwoord vergeten?',
      'cs.front.login': 'Log in',
      'cs.front.forget_password_field_note': 'We sturen je een bevestigingsmail. Klik op de daarin opgenomen link om jouw wachtwoord te wijzigen.',
      'cs.front.send_email': 'E-Mail sturen',
      'cs.front.setting_of_new_password': 'Nieuw wachtwoord instellen',
      'cs.front.token_from_the_letter': 'Beveiligingscode van reset-e-mail',
      'cs.front.new_password': 'Nieuw wachtwoord',
      'cs.front.set_password': 'Wachtwoord wijzigen',
      'cs.front.create_customer': 'Ik ben een nieuwe klant',
      'cs.front.company': 'Bedrijf \/ club',
      'cs.front.private_person': 'Particuliere klant',
      'cs.front.company_name': 'Naam bedrijf \/ club',
      'cs.front.enter_your_name': 'Vul hier jouw voornaam in',
      'cs.front.enter_company_name': 'Vul hier jouw bedrijfs - of clubnaam in',
      'cs.front.salutation': 'Aanhef',
      'cs.front.salutation_mr': 'Dhr',
      'cs.front.salutation_miss': 'Mvr',
      'cs.front.salutation_na': 'n \/ A',
      'cs.front.first_name': 'Voornaam',
      'cs.front.surname': 'Achternaam',
      'cs.front.enter_your_surname': 'Vul hier jouw achternaam in',
      'cs.front.subscribe_newsletter': 'Ik wil me graag inschrijven voor de nieuwsbrief',
      'cs.front.enter_your_password': 'Voer hier jouw gewenste wachtwoord in',
      'cs.front.password_field_note': 'Jouw wachtwoord moet minimaal 8 tekens bevatten. Denk aan hoofdletters en kleine letters.',
      'cs.front.address': 'Jouw adres',
      'cs.front.street_and_apartment': 'Straat en huisnummer',
      'cs.front.zip': 'Postcode',
      'cs.front.enter_your_zip': 'Vul hier jouw postcode in',
      'cs.front.city': 'Plaats',
      'cs.front.enter_your_city': 'Vul hier jouw plaats in',
      'cs.front.this_field_is_required': '* dit is een verplicht veld',
      'cs.front.privacy': 'Privacy',
      'cs.front.note_our_privacy_agreement': 'Zie ons',
      'cs.front.menu': 'Menu',
      'cs.front.back': 'Terug',
      'cs.front.for_individuals': 'Enkele bestelling',
      'cs.front.material': 'materiaal',
      'cs.front.for_teams': 'Team bestelling',
      'cs.front.color': 'Kleur',
      'cs.front.size': 'Maat',
      'cs.front.order_quantity': 'Bestelhoeveelheid',
      'cs.front.add_to_cart': 'Toevoegen aan winkelwagentje',
      'cs.front.price': 'Prijs',
      'cs.front.total_price': 'Totale prijs',
      'cs.front.total_quantity': 'Totaalbedrag',
      'cs.front.cancel': 'Annuleren',
      'cs.front.features': 'Overzicht van de kenmerken',
      'cs.front.description': 'Beschrijving',
      'cs.front.with_this_product_are_often_taken': 'Klanten kochten ook',
      'cs.front.wellcome': 'Welkom',
      'cs.front.profile_index_note': 'Dit is jouw accountdashboard waar je de mogelijkheid hebt om jouw recente accountactiviteit te bekijken',
      'cs.front.personal_data': 'Persoonlijke gegevens',
      'cs.front.change': 'Toepassen',
      'cs.front.newsletter': 'Geselecteerde nieuwsbrieven',
      'cs.front.newsletter_note': 'Ja, ik wil graag de gratis nieuwsbrief ontvangen en per e-mail op de hoogte worden gehouden van actuele aanbiedingen. Je kunt je op elk moment afmelden. Zie ons',
      'cs.front.privacy_policy': 'Privacybeleid',
      'cs.front.primary_billing_address': 'Primair factuuradres',
      'cs.front.edit': 'Bewerken',
      'cs.front.pickup_address': 'Ophaaladres',
      'cs.front.payment_method': 'Geselecteerde betaalmethode',
      'cs.front.payment_method_cash': 'Contante betaling of pinbetaling bij afhalen',
      'cs.front.billing_address': 'Facturatie adres',
      'cs.front.first_name_and_surname': 'Voornaam en achternaam',
      'cs.front.save': 'Opslaan',
      'cs.front.saved_alert': 'Wijzigingen opgeslagen',
      'cs.front.date': 'Datum',
      'cs.front.order_status': 'Bestelstatus',
      'cs.front.actions': 'Acties',
      'cs.front.order_number': 'Bestelnummer',
      'cs.front.product': 'Artikel',
      'cs.front.quantity': 'Hoeveelheid',
      'cs.front.profile_menu': 'Menu',
      'cs.front.overview': 'Overzicht',
      'cs.front.orders': 'Bestellingen',
      'cs.front.addresses': 'Adressen',
      'cs.front.log_out': 'Afmelden',
      'cs.front.clear': 'Filters resetten',
      'cs.front.sortiment': 'Assortiment',
      'cs.front.share_on': 'Delen op',
      'cs.front.cart': 'Winkelwagentje',
      'cs.front.products_number': 'Aantal stuks',
      'cs.front.total': 'Totaal',
      'cs.front.to_checkout': 'Uitchecken',
      'cs.front.edit_shopping_cart': 'Winkelwagentje bewerken',
      'cs.front.checkout_address': 'Jouw adres',
      'cs.front.payment_and_shipping': 'Betaling en verzending',
      'cs.front.proof_and_order': 'Check & Bestel',
      'cs.front.create_account_or_login': 'Maak een klantaccount aan of log in',
      'cs.front.do_not_create_customer_account': 'Bestel als gast',
      'cs.front.create_account': 'Ik bestel het liefst als gast',
      'cs.front.full_name': 'Je voor- en achternaam',
      'cs.front.city_field_note': '* dit is een verplicht veld',
      'cs.front.go_next': 'Doorgaan',
      'cs.front.i_agree_to_privacy_policy': 'Ik heb het privacybeleid gelezen.',
      'cs.front.register': 'Doorgaan',
      'cs.front.payment_on_pickup_note': 'Je betaalt jouw bestelling gemakkelijk wanneer je deze bij jouw ERIMA-dealer ophaalt.',
      'cs.front.select_payment_method': 'Kies betalingsmethode',
      'cs.front.main_billing_address': 'Primair factuuradres',
      'cs.front.new_customer_address': 'Nieuwe adres toevoegen',
      'cs.front.summary_info': 'Overzicht',
      'cs.front.sum': 'Totaal',
      'cs.front.delivery_price': 'Verzendkosten',
      'cs.front.total_without_tax': 'Totaal exclusief Btw',
      'cs.front.including_vat': 'plus Btw',
      'cs.front.i_agree_to': 'Ik accepteer het',
      'cs.front.create_order': 'Bestellen',
      'cs.front.thanks_for_order_in': 'Bedankt voor je bestelling in de clubshop van',
      'cs.front.thank_page_note': 'We informeren je per e-mail zodra je bestelling is verwerkt.',
      'cs.front.to_shop': 'Terug naar de shop',
      'cs.front.view': 'Bekijken',
      'cs.front.product_number': 'Artikelnummer',
      'cs.front.including': 'plus',
      'cs.front.vat': 'Btw.',
      'cs.front.email_repeat': 'Herhaal e-mailadres',
      'cs.front.current_passwort': 'Je huidige wachtwoord',
      'cs.front.password_required': 'Wachtwoordherhaling',
      'cs.front.email_sent': 'Email verzonden',
      'cs.front.order.STATUS_NEW': 'Nieuw',
      'cs.front.order.STATUS_IN_PROCESS': 'In verwerking',
      'cs.front.order.STATUS_PICKING': 'Wordt geplukt',
      'cs.front.order.STATUS_SENT_OUT': 'Verzonden',
      'cs.front.order.STATUS_SMU_PROCESS': 'Wordt ge\u00efndividualiseerd',
      'cs.front.order.STATUS_READY_FOR_PICKUP': 'Klaar om op te halen',
      'cs.front.order.STATUS_DONE': 'Compleet',
      'cs.front.order.STATUS_CANCELED': 'Geannuleerd',
      'cs.front.at': 'vanaf',
    },
    'be-NL': {
      'cs.front.phone_is_invalid': 'Controleer het opgegeven telefoonnummer',

      'cs.front.only_text_for_all_positions': 'Gebruik dezelfde tekst voor alle {amount_of_products} producten van maat {size} in kleur {color}.',
      'cs.front.different_text_for_all_positions': 'Gebruik een andere tekst voor elk van de {amount_of_products} producten van grootte {size} in kleur {color}.',

      'cs.front.warnings.options_text_required': 'Je selectie bevat een opdrukoptie met een vrije tekst. Je moet de gewenste tekst voor deze optie direct in het menu "Opdrukopties" invoeren, zodat deze op je product kan worden afgedrukt.',
      'cs.labels.product_image_type_f': 'Vooraanzicht',
      'cs.labels.product_image_type_r': 'Achteraanzicht',
      'cs.labels.product_image_type_s': 'Zijaanzicht',
      'cs.labels.product_image_type_d': 'Detailweergave',

      'cs.labels.available': 'Beschikbaar',
      'cs.labels.product_options': 'Afwerkingsmogelijkheden',
      'cs.labels.product_customization': 'Afwerking',

      'cs.front.search_country': 'Zoek op',
      'cs.front.example': 'Voorbeeld',
      'cs.front.scan_qr_using_app_and_wait_up_to_1_min': 'Scan de QR-code met je bank/TWINT-app en wacht ongeveer 60 seconden.',
      'cs.front.payment_token': 'Token',
      'cs.front.payment_method_mollie_paypal': 'Betaling via Paypal',
      'cs.front.payment_method_mollie_twint': 'Betaling via Twint',
      'cs.front.payment_method_mollie_ideal': 'Betaling via iDEAL',
      'cs.front.payment_method_mollie_bankontakt': 'Betaling via Bancontact',
      'cs.front.payment_method_mollie_credit_and_debit_cards': 'Betaling via debetkaart of creditcard',
      'cs.front.payment_processing_trouble_in': 'Helaas was er een probleem met de betaling.',
      'cs.front.payment_processing_trouble_note': 'Probeer het opnieuw of selecteer een andere betaalmethode.',

      'cs.front.post': 'Bpost',
      'cs.front.pickup_at_club_address': 'Afhaling bij de club',
      'cs.front.pickup_at_dealer_address': 'Afhaling bij de dealer',
      'cs.front.shipping_method': 'Geselecteerde Verzendmethode',
      'cs.front.profile.orders.list.order_address': 'Leveringsadres',
      'cs.front.payment_method_paypal': 'Paypal',
      'cs.front.bye': 'Tot ziens!',
      'cs.front.shipping_address': 'Leveringsadres',
      'cs.front.shipping_address_the_same_with_billing': 'Het leveringsadres is identiek aan het factuuradres',
      'cs.front.select_shipping_method': 'Selecteer de gewenste verzendmethode',
      'cs.front.shipping_cost': 'Verzendkosten',
      'cs.front.open_sizes_table': 'Open maattabel',
      'cs.front.finishing-comment-file-name': 'Opdruk_details',
      'cs.front.order_options_text_uploads_selection': 'Ik wil de vereiste informatie rechtstreeks invoeren in plaats van een bestand te uploaden.',
      'cs.front.order_options_text_uploads_info': 'Vul hier alle noodzakelijke informatie in voor de bestelde opdrukopties. Naast het artikelnummer en de maat, bevat dit de vereiste persoonlijke gegevens voor elke geselecteerde opdrukoptie (bv. spelersnaam, initialen, spelersnummer, ...).',
      'cs.front.productoptions_modal_file_upload_info': 'Houd er rekening mee dat we nauwkeurige informatie nodig hebben voor individuele verfijning in het bestelproces. Hiervoor zullen wij u vragen een bestand met deze gegevens te uploaden. Naast het itemnummer en de grootte, moet dit bestand de vereiste persoonlijke informatie bevatten voor elke geselecteerde opdrukoptie (bijv. naam speler, initialen, spelernummer, ...). Mogelijke formaten hiervoor zijn: doc, docx, xls, xlsx, pdf, txt, csv, odc, odt & zip. Als alternatief kunt u deze informatie ook in een tekstveld invullen.',
      'cs.front.colors.black': 'zwart',
      'cs.front.colors.blue': 'blauw',
      'cs.front.colors.brown': 'bruin',
      'cs.front.colors.green': 'groen',
      'cs.front.colors.grey': 'grijs',
      'cs.front.colors.purple': 'paars',
      'cs.front.colors.red': 'rood',
      'cs.front.colors.orange': 'oranje',
      'cs.front.colors.white': 'wit',
      'cs.front.colors.yellow': 'geel',
      'cs.front.password_new': 'Nieuw wachtwoord',
      'cs.front.filter_apply': 'Filter toepassen',
      'cs.front.sum_options': 'Totale opdruk',
      'cs.front.add_options': 'Opdruk toevoegen',
      'cs.front.total_options_price': 'Totaal opdruk',
      'cs.front.productoptions_link_button': 'Opdrukopties kiezen',
      'cs.front.productoptions_selected_price_total': 'Totale prijs opdruk',
      'cs.front.club_products': 'Zoeken in clubartikelen',
      'cs.front.eo_products': 'Zoeken in het ERIMA-assortiment',
      'cs.front.no_products_found': 'Bij dit zoekcriterium werden geen producten gevonden',
      'cs.front.product_size_in_stock_status_few': 'Maat {size} laag op voorraad. Vertraging in levering mogelijk.',
      'cs.front.product_size_in_stock_status_many_soon': 'Maat {size} momenteel niet op voorraad. Verwacht weer op voorraad op {next_delivery_date}.',
      'cs.front.product_size_in_stock_status_not_available_now': 'Maat {size} momenteel niet op voorraad.',
      'cs.front.subscribe_to_newsletter': 'Abonneren op de nieuwsbrief',
      'cs.front.current_password': 'Jouw wachtwoord',
      'cs.front.cart_position_options_title': 'Gekozen opdrukopties',
      'cs.front.productoptions_link': 'Opdrukopties',
      'cs.front.productoptions_selected': 'geselecteerd',
      'cs.front.productoptions_modal_title': 'Beschikbare Opdrukopties',
      'cs.front.productoptions_modal_total': 'Totale Opdruk',
      'cs.front.checkout_address_select_modal_title': 'Selecteer hier het gewenste adres',
      'cs.front.customer_address': 'Facturatie adres',
      'cs.front.make_default': 'Gebruik als een standaard',
      'cs.front.enter_your_street_and_apartment': 'Vul hier jouw straat en huisnummer in',
      'cs.front.empty_cart': 'Uw winkelwagen is leeg',
      'cs.front.welcome': 'Welkom!',
      'cs.front.order_options_file_uploads_info': 'Geef ons een overzicht van de gewenste inhoud voor alle geselecteerde opdrukopties als bestand.',
      'cs.front.order_options_file_uploads_button': 'Kies een bestand',
      'cs.front.gender_name_m': 'Heren',
      'cs.front.gender_name_w': 'Dames',
      'cs.front.gender': 'Geslacht',
      'cs.front.search': 'Zoeken',
      'cs.front.search_query': 'Voer een zoekterm in',
      'cs.front.filter': 'Filters toepassen',
      'cs.front.colors': 'Kleuren',
      'cs.front.in': 'in',
      'cs.front.phone': 'Telefoon',
      'cs.front.i_am_already_a_customer': 'Ik ben al klant',
      'cs.front.email': 'Jouw e-mailadres',
      'cs.front.password': 'Jouw wachtwoord',
      'cs.front.forget_password': 'Wachtwoord vergeten?',
      'cs.front.login': 'Log in',
      'cs.front.forget_password_field_note': 'We sturen je een bevestigingsmail. Klik op de daarin opgenomen link om jouw wachtwoord te wijzigen.',
      'cs.front.send_email': 'E-Mail sturen',
      'cs.front.setting_of_new_password': 'Nieuw wachtwoord instellen',
      'cs.front.token_from_the_letter': 'Beveiligingscode van reset-e-mail',
      'cs.front.new_password': 'Nieuw wachtwoord',
      'cs.front.set_password': 'Wachtwoord wijzigen',
      'cs.front.create_customer': 'Ik ben een nieuwe klant',
      'cs.front.company': 'Bedrijf \/ club',
      'cs.front.private_person': 'Particuliere klant',
      'cs.front.company_name': 'Naam bedrijf \/ club',
      'cs.front.enter_your_name': 'Vul hier jouw voornaam in',
      'cs.front.enter_company_name': 'Vul hier jouw bedrijfs - of clubnaam in',
      'cs.front.salutation': 'Aanhef',
      'cs.front.salutation_mr': 'Dhr',
      'cs.front.salutation_miss': 'Mvr',
      'cs.front.salutation_na': 'n \/ A',
      'cs.front.first_name': 'Voornaam',
      'cs.front.surname': 'Achternaam',
      'cs.front.enter_your_surname': 'Vul hier jouw achternaam in',
      'cs.front.subscribe_newsletter': 'Ik wil me graag inschrijven voor de nieuwsbrief',
      'cs.front.enter_your_password': 'Voer hier jouw gewenste wachtwoord in',
      'cs.front.password_field_note': 'Jouw wachtwoord moet minimaal 8 tekens bevatten. Denk aan hoofdletters en kleine letters.',
      'cs.front.address': 'Jouw adres',
      'cs.front.street_and_apartment': 'Straat en huisnummer',
      'cs.front.zip': 'Postcode',
      'cs.front.enter_your_zip': 'Vul hier jouw postcode in',
      'cs.front.city': 'Plaats',
      'cs.front.enter_your_city': 'Vul hier jouw plaats in',
      'cs.front.this_field_is_required': '* dit is een verplicht veld',
      'cs.front.privacy': 'Privacy',
      'cs.front.note_our_privacy_agreement': 'Zie ons',
      'cs.front.menu': 'Menu',
      'cs.front.back': 'Terug',
      'cs.front.for_individuals': 'Enkele bestelling',
      'cs.front.material': 'materiaal',
      'cs.front.for_teams': 'Team bestelling',
      'cs.front.color': 'Kleur',
      'cs.front.size': 'Maat',
      'cs.front.order_quantity': 'Bestelhoeveelheid',
      'cs.front.add_to_cart': 'Toevoegen aan winkelwagentje',
      'cs.front.price': 'Prijs',
      'cs.front.total_price': 'Totale prijs',
      'cs.front.total_quantity': 'Totaalbedrag',
      'cs.front.cancel': 'Annuleren',
      'cs.front.features': 'Overzicht van de kenmerken',
      'cs.front.description': 'Beschrijving',
      'cs.front.with_this_product_are_often_taken': 'Klanten kochten ook',
      'cs.front.wellcome': 'Welkom',
      'cs.front.profile_index_note': 'Dit is jouw accountdashboard waar je de mogelijkheid hebt om jouw recente accountactiviteit te bekijken',
      'cs.front.personal_data': 'Persoonlijke gegevens',
      'cs.front.change': 'Toepassen',
      'cs.front.newsletter': 'Geselecteerde nieuwsbrieven',
      'cs.front.newsletter_note': 'Ja, ik wil graag de gratis nieuwsbrief ontvangen en per e-mail op de hoogte worden gehouden van actuele aanbiedingen. Je kunt je op elk moment afmelden. Zie ons',
      'cs.front.privacy_policy': 'Privacybeleid',
      'cs.front.primary_billing_address': 'Primair factuuradres',
      'cs.front.edit': 'Bewerken',
      'cs.front.pickup_address': 'Ophaaladres',
      'cs.front.payment_method': 'Geselecteerde betaalmethode',
      'cs.front.payment_method_cash': 'Contante betaling of pinbetaling bij afhalen',
      'cs.front.billing_address': 'Facturatie adres',
      'cs.front.first_name_and_surname': 'Voornaam en achternaam',
      'cs.front.save': 'Opslaan',
      'cs.front.saved_alert': 'Wijzigingen opgeslagen',
      'cs.front.date': 'Datum',
      'cs.front.order_status': 'Bestelstatus',
      'cs.front.actions': 'Acties',
      'cs.front.order_number': 'Bestelnummer',
      'cs.front.product': 'Artikel',
      'cs.front.quantity': 'Hoeveelheid',
      'cs.front.profile_menu': 'Menu',
      'cs.front.overview': 'Overzicht',
      'cs.front.orders': 'Bestellingen',
      'cs.front.addresses': 'Adressen',
      'cs.front.log_out': 'Afmelden',
      'cs.front.clear': 'Filters resetten',
      'cs.front.sortiment': 'Assortiment',
      'cs.front.share_on': 'Delen op',
      'cs.front.cart': 'Winkelwagentje',
      'cs.front.products_number': 'Aantal stuks',
      'cs.front.total': 'Totaal',
      'cs.front.to_checkout': 'Uitchecken',
      'cs.front.edit_shopping_cart': 'Winkelwagentje bewerken',
      'cs.front.checkout_address': 'Jouw adres',
      'cs.front.payment_and_shipping': 'Betaling en verzending',
      'cs.front.proof_and_order': 'Check & Bestel',
      'cs.front.create_account_or_login': 'Maak een klantaccount aan of log in',
      'cs.front.do_not_create_customer_account': 'Bestel als gast',
      'cs.front.create_account': 'Ik bestel het liefst als gast',
      'cs.front.full_name': 'Je voor- en achternaam',
      'cs.front.city_field_note': '* dit is een verplicht veld',
      'cs.front.go_next': 'Doorgaan',
      'cs.front.i_agree_to_privacy_policy': 'Ik heb het privacybeleid gelezen.',
      'cs.front.register': 'Doorgaan',
      'cs.front.payment_on_pickup_note': 'Je betaalt jouw bestelling gemakkelijk wanneer je deze bij jouw ERIMA-dealer ophaalt.',
      'cs.front.select_payment_method': 'Kies betalingsmethode',
      'cs.front.main_billing_address': 'Primair factuuradres',
      'cs.front.new_customer_address': 'Nieuwe adres toevoegen',
      'cs.front.summary_info': 'Overzicht',
      'cs.front.sum': 'Totaal',
      'cs.front.delivery_price': 'Verzendkosten',
      'cs.front.total_without_tax': 'Totaal exclusief Btw',
      'cs.front.including_vat': 'plus Btw',
      'cs.front.i_agree_to': 'Ik accepteer het',
      'cs.front.create_order': 'Bestellen',
      'cs.front.thanks_for_order_in': 'Bedankt voor je bestelling in de clubshop van',
      'cs.front.thank_page_note': 'We informeren je per e-mail zodra je bestelling is verwerkt.',
      'cs.front.to_shop': 'Terug naar de shop',
      'cs.front.view': 'Bekijken',
      'cs.front.product_number': 'Artikelnummer',
      'cs.front.including': 'plus',
      'cs.front.vat': 'Btw.',
      'cs.front.email_repeat': 'Herhaal e-mailadres',
      'cs.front.current_passwort': 'Je huidige wachtwoord',
      'cs.front.password_required': 'Wachtwoordherhaling',
      'cs.front.email_sent': 'Email verzonden',
      'cs.front.order.STATUS_NEW': 'Nieuw',
      'cs.front.order.STATUS_IN_PROCESS': 'In verwerking',
      'cs.front.order.STATUS_PICKING': 'Wordt geplukt',
      'cs.front.order.STATUS_SENT_OUT': 'Verzonden',
      'cs.front.order.STATUS_SMU_PROCESS': 'Wordt ge\u00efndividualiseerd',
      'cs.front.order.STATUS_READY_FOR_PICKUP': 'Klaar om op te halen',
      'cs.front.order.STATUS_DONE': 'Compleet',
      'cs.front.order.STATUS_CANCELED': 'Geannuleerd',
      'cs.front.at': 'vanaf',
    },
    'eu-EN': {
      'cs.front.phone_is_invalid': 'Please check the telephone number provided',

      'cs.front.only_text_for_all_positions': 'Use the same text for all {amount_of_products} products of size {size} in color {color}.',
      'cs.front.different_text_for_all_positions': 'Use different text for each of the {amount_of_products} products of size {size} in color {color}.',

      'cs.front.warnings.options_text_required': 'Your selection contains a finishing option with a free text. You must enter the desired text for this option directly in the "Finishing options" menu so that it can be printed on your product.',
      'cs.labels.product_image_type_f': 'Front view',
      'cs.labels.product_image_type_r': 'Back view',
      'cs.labels.product_image_type_s': 'Side view',
      'cs.labels.product_image_type_d': 'Detail view',

      'cs.labels.available': 'Available',
      'cs.labels.product_options': 'Finishing Options',
      'cs.labels.product_customization': 'Finishing',

      'cs.front.search_country': 'Search',
      'cs.front.example': 'Example',
      'cs.front.scan_qr_using_app_and_wait_up_to_1_min': 'Please scan the QR code with your bank/TWINT app and wait approx. 60 seconds.',
      'cs.front.payment_token': 'Token',
      'cs.front.payment_method_mollie_paypal': 'Payment via Paypal',
      'cs.front.payment_method_mollie_twint': 'Payment via Twint',
      'cs.front.payment_method_mollie_ideal': 'Payment via iDEAL',
      'cs.front.payment_method_mollie_bankontakt': 'Payment via Bancontact',
      'cs.front.payment_method_mollie_credit_and_debit_cards': 'Payment by debit or credit card',
      'cs.front.payment_processing_trouble_in': 'Unfortunately there was a problem with the payment.',
      'cs.front.payment_processing_trouble_note': 'Please try again or select another payment method.',

      'cs.front.post': 'Post',
      'cs.front.pickup_at_club_address': '',
      'cs.front.pickup_at_dealer_address': '',
      'cs.front.shipping_method': 'Selected Shipping Method',
      'cs.front.profile.orders.list.order_address': 'Delivery address',
      'cs.front.payment_method_paypal': 'Paypal',
      'cs.front.bye': 'See you soon!',
      'cs.front.shipping_address': 'Shipping address',
      'cs.front.shipping_address_the_same_with_billing': 'The shipping address is identical with the billing address.',
      'cs.front.select_shipping_method': 'Please select the desired shipping method',
      'cs.front.shipping_cost': 'Shipping Cost',

      'cs.front.open_sizes_table': 'Open size table',
      'cs.front.finishing-comment-file-name': 'Finishing_details',
      'cs.front.order_options_text_uploads_selection': 'I would like to enter the required info directly instead of uploading a file',
      'cs.front.order_options_text_uploads_info': 'Please enter all necessary information for the ordered finishing options here. In addition to item number and size, this is the required personal information per selected finishing option (e.g. player name, initials, player number, ...).',

      'cs.front.productoptions_modal_file_upload_info': 'Please note that we need precise information for individual finishing in the ordering process. For this we will ask you to upload a file with these details. In addition to the item number and size, this file should contain the required personal information for each selected finishing option (e.g. player name, initials, player number, ...). Possible formats for this are: doc, docx, xls, xlsx, pdf, txt, csv, odc, odt & zip. Alternatively, you can also enter this information in a text field.',
      'cs.front.colors.black': 'black',
      'cs.front.colors.blue': 'blue',
      'cs.front.colors.brown': 'brown',
      'cs.front.colors.green': 'green',
      'cs.front.colors.grey': 'grey',
      'cs.front.colors.purple': 'purple',
      'cs.front.colors.red': 'red',
      'cs.front.colors.orange': 'orange',
      'cs.front.colors.white': 'white',
      'cs.front.colors.yellow': 'yellow',
      'cs.front.password_new': 'New password',
      'cs.front.filter_apply': 'Apply filter',
      'cs.front.sum_options': 'Total finishing',
      'cs.front.add_options': 'Add finishing',
      'cs.front.total_options_price': 'Total finishing',
      'cs.front.productoptions_link_button': 'Select finishing options',
      'cs.front.productoptions_selected_price_total': 'Total price finishing',
      'cs.front.product_size_in_stock_status_few': 'Size {size} low on stock. Delays in delivery possible.',
      'cs.front.product_size_in_stock_status_many_soon': 'Size {size} currently out of stock. Expected back in stock on {next_delivery_date}.',
      'cs.front.product_size_in_stock_status_not_available_now': 'Size {size} currently out of stock.',
      'cs.front.subscribe_to_newsletter': 'Subscribe to Newsletter',
      'cs.front.current_password': 'Your password',
      'cs.front.cart_position_options_title': 'Selected finishing options',
      'cs.front.productoptions_link': 'Finishing options',
      'cs.front.productoptions_selected': 'selected',
      'cs.front.productoptions_modal_title': 'Available finishing options',
      'cs.front.productoptions_modal_total': 'Total finishing',
      'cs.front.checkout_address_select_modal_title': 'Select the desired address here',
      'cs.front.customer_address': 'Billing address',
      'cs.front.make_default': 'Use as a standard',
      'cs.front.enter_your_street_and_apartment': 'Please enter your street and house number here',
      'cs.front.empty_cart': 'Your cart is empty',
      'cs.front.welcome': 'Welcome!',
      'cs.front.order_options_file_uploads_info': 'Please provide us with an overview of the desired content for all selected finishing options as a file.',
      'cs.front.order_options_file_uploads_button': 'Choose a file',
      'cs.front.gender_name_m': 'Male',
      'cs.front.gender_name_w': 'Female',
      'cs.front.gender': 'Gender',
      'cs.front.search': 'Search',
      'cs.front.search_query': 'Enter a search term',
      'cs.front.filter': 'Apply filters',
      'cs.front.colors': 'Colours',
      'cs.front.in': 'In',
      'cs.front.phone': 'Phone',
      'cs.front.i_am_already_a_customer': 'I am already a customer',
      'cs.front.email': 'Your email address',
      'cs.front.password': 'Your password',
      'cs.front.forget_password': 'Forgot Password?',
      'cs.front.login': 'Log in',
      'cs.front.forget_password_field_note': 'We will send you a confirmation email. Click on the link contained therein to change your password.',
      'cs.front.send_email': 'Send e-mail',
      'cs.front.setting_of_new_password': 'Set new password',
      'cs.front.token_from_the_letter': 'Security code from reset e-mail',
      'cs.front.new_password': 'New Password',
      'cs.front.set_password': 'Change Password',
      'cs.front.create_customer': 'I am a new customer',
      'cs.front.company': 'Company \/ club',
      'cs.front.private_person': 'Private customer',
      'cs.front.company_name': 'Company \/ Club name',
      'cs.front.enter_your_name': 'Please enter your first name here',
      'cs.front.enter_company_name': 'Enter your company or club name here',
      'cs.front.salutation': 'Salutation',
      'cs.front.salutation_mr': 'Mr.',
      'cs.front.salutation_miss': 'Mrs.',
      'cs.front.salutation_na': 'n\/a',
      'cs.front.first_name': 'First name',
      'cs.front.surname': 'Last name',
      'cs.front.enter_your_surname': 'Please enter your last name here',
      'cs.front.subscribe_newsletter': 'I would like to subscribe to the newsletter',
      'cs.front.enter_your_password': 'Please enter your desired password here',
      'cs.front.password_field_note': 'Your password must contain at least 8 characters. Consider upper and lower case letters.',
      'cs.front.address': 'Your Address',
      'cs.front.street_and_apartment': 'Street and house number',
      'cs.front.zip': 'Postal code',
      'cs.front.enter_your_zip': 'Please enter your zip code here',
      'cs.front.city': 'City',
      'cs.front.enter_your_city': 'Please enter your city here',
      'cs.front.this_field_is_required': '* this is a mandatory field',
      'cs.front.privacy': 'Privacy',
      'cs.front.note_our_privacy_agreement': 'Please see our',
      'cs.front.menu': 'Menu',
      'cs.front.back': 'Back',
      'cs.front.for_individuals': 'Single order',
      'cs.front.material': 'Material',
      'cs.front.for_teams': 'Team order',
      'cs.front.color': 'Colour',
      'cs.front.size': 'Size',
      'cs.front.order_quantity': 'Order quantity',
      'cs.front.add_to_cart': 'Add to cart',
      'cs.front.price': 'Price',
      'cs.front.total_price': 'Total price',
      'cs.front.total_quantity': 'Total quantity',
      'cs.front.cancel': 'Cancel',
      'cs.front.features': 'Features at a glance',
      'cs.front.description': 'Description',
      'cs.front.with_this_product_are_often_taken': 'Customers also bought',
      'cs.front.wellcome': 'Welcome',
      'cs.front.profile_index_note': 'This is your account dashboard where you have the option to view your recent account activity',
      'cs.front.personal_data': 'Personal data',
      'cs.front.change': 'Change',
      'cs.front.newsletter': 'Selected newsletters',
      'cs.front.newsletter_note': 'Yes, I would like to receive the free newsletter and be informed about current offers by e-mail. You can unsubscribe at any time. Please see our',
      'cs.front.privacy_policy': 'Privacy Policy',
      'cs.front.primary_billing_address': 'Primary billing address',
      'cs.front.edit': 'Edit',
      'cs.front.pickup_address': 'Pickup address',
      'cs.front.payment_method': 'Selected method of payment',
      'cs.front.payment_method_cash': 'Cash or card payment on collection',
      'cs.front.billing_address': 'Billing address',
      'cs.front.first_name_and_surname': 'First and last name',
      'cs.front.save': 'Save',
      'cs.front.saved_alert': 'Changes saved',
      'cs.front.date': 'Date',
      'cs.front.order_status': 'Order status',
      'cs.front.actions': 'Actions',
      'cs.front.order_number': 'Order number',
      'cs.front.product': 'Article',
      'cs.front.quantity': 'Quantity',
      'cs.front.profile_menu': 'Menu',
      'cs.front.overview': 'Overview',
      'cs.front.orders': 'Orders',
      'cs.front.addresses': 'Addresses',
      'cs.front.log_out': 'Sign out',
      'cs.front.clear': 'Reset filter',
      'cs.front.sortiment': 'Product range',
      'cs.front.share_on': 'Share on',
      'cs.front.cart': 'Shopping cart',
      'cs.front.products_number': 'Number of items',
      'cs.front.total': 'Total',
      'cs.front.to_checkout': 'To checkout',
      'cs.front.edit_shopping_cart': 'Edit shopping cart',
      'cs.front.checkout_address': 'Your Address',
      'cs.front.payment_and_shipping': 'Payment & shipping',
      'cs.front.proof_and_order': 'Check & Order',
      'cs.front.create_account_or_login': 'Create a customer account or log in',
      'cs.front.do_not_create_customer_account': 'Order as a guest',
      'cs.front.create_account': 'I prefer to order as a guest',
      'cs.front.full_name': 'Your first & last name',
      'cs.front.city_field_note': '* this is a mandatory field',
      'cs.front.go_next': 'Continue',
      'cs.front.i_agree_to_privacy_policy': 'I have read the privacy policy.',
      'cs.front.register': 'Continue',
      'cs.front.payment_on_pickup_note': 'You conveniently pay for your order when you pick it up from your retail partner.',
      'cs.front.select_payment_method': 'Choose payment method',
      'cs.front.main_billing_address': 'Primary billing address',
      'cs.front.new_customer_address': 'Add new address',
      'cs.front.summary_info': 'Summary',
      'cs.front.sum': 'Total',
      'cs.front.delivery_price': 'Shipping',
      'cs.front.total_without_tax': 'Total excluding VAT',
      'cs.front.including_vat': 'plus VAT',
      'cs.front.i_agree_to': 'Hereby, I accept the',
      'cs.front.create_order': 'Order',
      'cs.front.thanks_for_order_in': 'Thank you for your order in the club shop of',
      'cs.front.thank_page_note': 'We will inform you by e-mail as soon as your order has been processed.',
      'cs.front.to_shop': 'Back to the shop',
      'cs.front.view': 'View',
      'cs.front.product_number': 'Item number',
      'cs.front.including': 'plus',
      'cs.front.vat': 'VAT',
      'cs.front.email_repeat': 'Repeat email address',
      'cs.front.current_passwort': 'Your current password',
      'cs.front.password_required': 'Password repetition',
      'cs.front.email_sent': 'E-Mail sent',
      'cs.front.order.STATUS_NEW': 'New',
      'cs.front.order.STATUS_IN_PROCESS': 'In processing',
      'cs.front.order.STATUS_PICKING': 'Will be picked',
      'cs.front.order.STATUS_SENT_OUT': 'Shipped',
      'cs.front.order.STATUS_SMU_PROCESS': 'Will be individualized',
      'cs.front.order.STATUS_READY_FOR_PICKUP': 'Ready for pickup',
      'cs.front.order.STATUS_DONE': 'Completed',
      'cs.front.order.STATUS_CANCELED': 'Canceled',
      'cs.front.at': 'from',
    },
  },
};

export default translations;
