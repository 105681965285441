<template>
  <div class="row">
    <div class="col-lg-4 offset-lg-1 mb-4">
      <div class="box box-login" @keyup.enter="loginWrapper(loginData)">
        <div class="box-title">
          <i class="icon icon-profile-edit"></i>
          {{ $t('cs.front.i_am_already_a_customer') }}
        </div>
        <div class="form-group">
          <input
            v-model="loginData.username"
            type="text"
            :placeholder="$t('cs.front.email')"
          />
        </div>
        <div class="form-group">
          <input
            v-model="loginData.password"
            type="password"
            :placeholder="$t('cs.front.password')"
          />
        </div>
        <a
          @click="showForgetPasswordPopup = true"
          href="javascript:"
          class="pass-forgot"
        >
          {{ $t('cs.front.forget_password') }}
        </a>
        <button @click="loginWrapper(loginData)" class="btn btn-green">
          {{ $t('cs.front.login') }}
        </button>
      </div>
      <div class="box box-recovery" v-if="showForgetPasswordPopup">
        <button
          @click="showForgetPasswordPopup = false"
          type="button"
          class="box-recovery-close"
        >
          <i class="icon icon-close"></i>
        </button>
        <p class="h4 modal-title">{{ $t('cs.front.forget_password') }}</p>
        <div class="form-group">
          <input
            @keyup.enter="restorePassword()"
            v-model="forgetPasswordUsername"
            type="text"
            :placeholder="$t('cs.front.email')"
          />
        </div>
        <p class="recovery-text">{{ $t('cs.front.forget_password_field_note') }}</p>
        <div class="text-center">
          <button
            @click="restorePassword()"
            class="btn btn-green"
            type="button"
          >
            {{ $t('cs.front.send_email') }}
          </button>
        </div>
      </div>
      <div class="box box-recovery" v-if="showNewPasswordForm">
        <button
          @click="showNewPasswordForm = false"
          type="button"
          class="box-recovery-close"
        >
          <i class="icon icon-close"></i>
        </button>
        <p class="h4 modal-title">{{ $t('cs.front.setting_of_new_password') }}</p>
        <div class="form-group" @keyup.enter="setNewPasswordWrapper()">
          <label>
            {{ $t('cs.front.token_from_the_letter') }}
            <input
              v-model="recoverPasswordToken"
              type="text"
            />
          </label>
          <label>
            {{ $t('cs.front.new_password') }}
            <input
              v-model="newPassword"
              type="password"
            />
          </label>
        </div>
        <div class="text-center">
          <button
            @click="setNewPasswordWrapper()"
            class="btn btn-green"
            type="button"
          >
            {{ $t('cs.front.set_password') }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-lg-7">
      <div class="box" @keyup.enter="registerWrapper(registerProfile)">
        <div class="box-title">
          <i class="icon icon-profile-edit"></i>
          {{ $t('cs.front.create_customer') }}
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <select v-model="registerProfile.type">
                <option value="C">{{ $t('cs.front.company') }}</option>
                <option value="PP">{{ $t('cs.front.private_person') }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <template v-if="registerProfile.type === 'C'">
              <p class="h5">{{ $t('cs.front.company_name') }}*</p>
              <div class="form-group">
                <input
                  v-model="registerProfile.company_name"
                  type="text"
                  :placeholder="$t('cs.front.enter_company_name')"
                >
              </div>
            </template>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 d-flex pb-4">
            <b>{{ $t('cs.front.salutation') }}*</b>
            <div class="radio">
              <input
                v-model="registerProfile.salutation"
                id="Herr"
                type="radio"
                name="andere"
                value="M"
              />
              <label for="Herr">{{ $t('cs.front.salutation_mr') }}</label>
            </div>
            <div class="radio">
              <input
                v-model="registerProfile.salutation"
                id="Frau"
                type="radio"
                name="andere"
                value="W"
              />
              <label for="Frau">{{ $t('cs.front.salutation_miss') }} </label>
            </div>
            <div class="radio">
              <input
                v-model="registerProfile.salutation"
                id="Kne"
                type="radio"
                name="andere"
                value="NA"
              />
              <label for="Kne">{{ $t('cs.front.salutation_na') }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p class="h5">{{ $t('cs.front.first_name') }}*</p>
            <div class="form-group">
              <input
                v-model="registerProfile.name"
                type="text"
                :placeholder="$t('cs.front.enter_your_name')"
              />
            </div>
          </div>
          <div class="col-md-6">
            <p class="h5">{{ $t('cs.front.surname') }}*</p>
            <div class="form-group">
              <input
                v-model="registerProfile.surname"
                type="text"
                :placeholder="$t('cs.front.enter_your_surname')"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p class="h5">{{ $t('cs.front.email') }}*</p>
            <div class="form-group mb-2">
              <input
                v-model="registerProfile.email"
                type="email"
                :placeholder="$t('cs.front.email')"
              />
            </div>
            <div class="checkbox mb-4 mb-md-0">
              <input
                v-model="registerProfile.has_newsletter"
                id="newsletter"
                type="checkbox"
              />
              <label for="newsletter">{{ $t('cs.front.subscribe_newsletter') }}</label>
            </div>
          </div>
          <div class="col-md-6">
            <p class="h5">{{ $t('cs.front.password') }}*</p>
            <div class="form-group mb-2">
              <input
                v-model="registerProfile.password"
                type="password"
                :placeholder="$t('cs.front.enter_your_password')"
                minlength="8"
              />
            </div>
            <p class="remark">{{ $t('cs.front.password_field_note') }}.</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <p class="h5">{{ $t('cs.front.phone') }}</p>

            <div
              class="form-group mb-2"
            >
              <MazPhoneNumberInput
                v-model="registerProfile.phone"
                color="primary"
                autocomplete="off"
                :countryCode="$i18n.locale.split('-')[1] ?? 'DE'"
                :translations="{
                  countrySelector: {
                    placeholder: '',
                    searchPlaceholder: $t('cs.front.search_country')
                  },
                  phoneInput: {
                    placeholder: '',
                    example: $t('cs.front.example')
                  }
                }"
                @update="phoneRes = $event"
                :success="phoneRes?.isValid"
                :preferredCountries="['DE', 'NL', 'CH', 'FR', 'AT', 'BE', 'NL']"
                country-selector-display-name
                countrySelectorWidth="17.5rem"
              />
              <div style="clear: both"></div>
              <strong
                v-if="registerProfile.phone && phoneRes && !phoneRes.isValid"
                style="color: red;"
              >
                {{ $t('cs.front.phone_is_invalid') }}
              </strong>
            </div>
          </div>
        </div>

        <p class="h4 mt-4">{{ $t('cs.front.billing_address') }}</p>
        <div class="row pb-md-2">
          <div class="col-md-6">
            <p class="h5">{{ $t('cs.front.street_and_apartment') }}</p>
            <div class="form-group">
              <input
                v-model="registerProfile.billing_address"
                type="text"
                :placeholder="$t('cs.front.enter_your_street_and_apartment')"
              />
            </div>
          </div>
          <div class="col-md-6">
            <p class="h5">{{ $t('cs.front.zip') }}*</p>
            <div class="form-group">
              <input
                v-model="registerProfile.billing_zip"
                type="text"
                :placeholder="$t('cs.front.enter_your_zip')"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p class="h5">{{ $t('cs.front.city') }}*</p>
            <div class="form-group mb-2">
              <input
                v-model="registerProfile.billing_city"
                type="text"
                :placeholder="$t('cs.front.enter_your_city')"
              />
            </div>
          </div>
        </div>
        <p>
          <label>
            <input v-model="registerProfile.common_address" type="checkbox" />
            {{ $t('cs.front.shipping_address_the_same_with_billing') }}
          </label>
        </p>
        <template v-if="!registerProfile.common_address">
          <p class="h4 mt-4">{{ $t('cs.front.shipping_address') }}</p>
          <div class="row pb-md-2">
            <div class="col-md-6">
              <p class="h5">{{ $t('cs.front.street_and_apartment') }}</p>
              <div class="form-group">
                <input
                  v-model="registerProfile.shipping_address"
                  type="text"
                  :placeholder="$t('cs.front.enter_your_street_and_apartment')"
                />
              </div>
            </div>
            <div class="col-md-6">
              <p class="h5">{{ $t('cs.front.zip') }}*</p>
              <div class="form-group">
                <input
                  v-model="registerProfile.shipping_zip"
                  type="text"
                  :placeholder="$t('cs.front.enter_your_zip')"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p class="h5">{{ $t('cs.front.city') }}*</p>
              <div class="form-group mb-2">
                <input
                  v-model="registerProfile.shipping_city"
                  type="text"
                  :placeholder="$t('cs.front.enter_your_city')"
                />
              </div>
            </div>
          </div>
        </template>

        <p class="remark">{{ $t('cs.front.this_field_is_required') }}</p>

        <p class="h4">{{ $t('cs.front.privacy') }}</p>
        <p class="mb-0">{{ $t('cs.front.note_our_privacy_agreement') }}</p>
        <p>
          <router-link
            :to="{ name: 'info', params: { type: pageTypes.TYPE_AGREEMENT }}"
            class="small under blue"
          >
            {{ $t('cs.front.privacy_policy') }}
          </router-link>
        </p>
        <p>
          <label>
            <input type="checkbox" v-model="registerProfile.has_privacy_agreement" />
            {{ $t('cs.front.i_agree_to_privacy_policy') }}
          </label>
        </p>
        <div class="text-center pt-4">
          <button @click="registerWrapper(registerProfile)" class="btn btn-green">
            {{ $t('cs.front.register') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { useRoute } from 'vue-router';
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
import * as pageTypes from '../../consts/page';

export default {
  components: {
    MazPhoneNumberInput,
  },
  props: ['scenario'],
  data() {
    return {
      showForgetPasswordPopup: false,
      showNewPasswordForm: false,
      recoverPasswordToken: null,
      newPassword: null,
      loginData: { username: null, password: null },
      forgetPasswordUsername: null,

      registerProfile: {
        type: 'PP',
        common_address: true,
      },
      phoneRes: null,
      pageTypes,
    };
  },
  computed: {

  },
  watch: {

  },
  methods: {
    ...mapActions('auth', {
      login: 'login',
      register: 'register',
      sendRecoverPasswordLetter: 'sendRecoverPasswordLetter',
      setNewPassword: 'setNewPassword',
    }),
    loginWrapper(loginData) {
      this.login(loginData).then(() => {
        this.$toast.success(this.$t('cs.front.welcome'));

        if (this.scenario === 'purchase') {
          this.$router.push({ name: 'checkout.final-step' });
        } else {
          this.$router.push({ name: 'profile.index' });
        }
      });
    },
    registerWrapper(registerProfile) {
      this.register(registerProfile).then(() => {
        this.$toast.success(this.$t('cs.front.welcome'));

        if (this.scenario === 'purchase') {
          this.$router.push({ name: 'checkout.final-step' });
        } else {
          this.$router.push({ name: 'profile.index' });
        }
      });
    },
    restorePassword() {
      this.sendRecoverPasswordLetter(this.forgetPasswordUsername).then(() => {
        this.showForgetPasswordPopup = false;
        this.showNewPasswordForm = true;
        this.forgetPasswordUsername = null;
        this.$toast.success(this.$t('cs.front.email_sent'));
      });
    },
    setNewPasswordWrapper() {
      this.setNewPassword({ token: this.recoverPasswordToken, password: this.newPassword })
        .then(() => {
          this.showForgetPasswordPopup = false;
          this.showNewPasswordForm = false;
          this.$toast.success(this.$t('cs.front.it_was_set'));
        });
    },
  },
  mounted() {
    const route = useRoute();
    if (route.params.recoverPasswordToken) {
      this.recoverPasswordToken = route.params.recoverPasswordToken;
      this.showNewPasswordForm = true;
    }
  },
};
</script>
