<template>
  <main class="content checkout-page">
    <div class="container">

      <div v-if="shop && !showLoader && !paymentFailed" class="checkout-finish">
        <p class="title">{{ $t('cs.front.thanks_for_order_in') }} {{ shop.name }}!</p>
        <p class="text">{{ $t('cs.front.thank_page_note') }}</p>
        <router-link :to="{ name: 'home' }" class="btn btn-green">
          {{ $t('cs.front.to_shop') }}
        </router-link>
      </div>

      <div v-if="paymentFailed" class="checkout-finish" style="color: red !important;">
        <p class="title">{{ $t('cs.front.payment_processing_trouble_in') }}</p>
        <p class="text">{{ $t('cs.front.payment_processing_trouble_note') }}</p>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import * as paymentMethodTypes from '../../../consts/payment_method';
import * as paymentTransactionTypes from '../../../consts/payment_transacton';

export default {
  components: {

  },
  data() {
    return {
      paymentMethodTypes,
      paymentTransactionTypes,
      paymentFailed: false,
    };
  },
  computed: {
    ...mapGetters({
      shop: 'shop/selected',
      transaction: 'transaction/selected',
    }),
    ...mapGetters({
      showLoader: 'showLoader',
    }),
  },
  watch: {

  },
  methods: {
    ...mapActions('cart', {
      fetchCart: 'fetch',
      paymentTransactionCallback: 'paymentTransactionCallback',
    }),
    ...mapActions('orderFile', {
      clearPreUploadedFile: 'clearPreUploadedFile',
    }),
    ...mapActions('shop', {
      reFetchShop: 'select',
    }),
    ...mapActions('transaction', {
      fetchTransaction: 'select',
    }),
  },
  mounted() {
    this.clearPreUploadedFile();
    localStorage.removeItem('orderText');

    const urlParams = Object.fromEntries(new URLSearchParams(window.location.search));

    if (urlParams.transaction_id && urlParams.payment_provider) {
      if (urlParams.payment_provider === paymentMethodTypes.PAYMENT_METHOD_GROUP_PAYPAL) {
        this.$store.commit('showLoader');
        this.paymentTransactionCallback(urlParams).then(() => {
          this.$store.commit('hideLoader');
          this.fetchCart();
          this.reFetchShop(this.shop.url);
        });
      }

      this.$store.commit('showLoader');
      setTimeout(() => {
        if (urlParams.payment_provider === paymentMethodTypes.PAYMENT_METHOD_GROUP_MOLLIE) {
          this.fetchTransaction(urlParams.transaction_id).then(() => {
            this.$store.commit('hideLoader');
            this.fetchCart();
            this.reFetchShop(this.shop.url);

            if (this.transaction.status !== paymentTransactionTypes.STATUS_DONE) {
              this.paymentFailed = true;
            }
          });
        }
      }, 3000);
    }

    if (this.shop && this.shop.url) {
      this.reFetchShop(this.shop.url);
    }

    setTimeout(() => {
      this.fetchCart();
    }, 2500);

    this.$store.commit('hideSideCart');
  },
};
</script>
