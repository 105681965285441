import axios from 'axios';

export default {
  fetch(context, filters = {}) {
    return axios.get(
      `${context.rootState.apiHost}/club-shop/app/footer-blocks/`,
      { params: filters },
    ).then((response) => {
      context.commit('setList', response.data);
    });
  },
};
