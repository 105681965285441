<template>
  <section class="container home-seller">
    <h2 class="h2">{{ header }}</h2>
    <div class="product-slider" v-if="products && products.length">
      <carousel :items-to-show="itemsToShow">
        <slide v-for="product in products" :key="product.id">
          <div style="margin-right: 30px; min-width: 24%;">
            <router-link
              :to="{ name: 'product', params: { productId: product.id }}"
              class="category-item"
            >
              <div v-if="product.image" class="img">
                <img :src="product.image" :alt="product.name">
              </div>
              <div class="desc">
                <div class="name">{{ product.name }}</div>
                <div class="price">
                  <template v-if="product.display_rrp">
                    <s>
                      <small>{{ $filters.priceFormat(product.price_rrp, product.currency) }}</small>
                    </s>
                    &nbsp;
                    <template v-if="product.price_prefix">
                      {{ $t('cs.front.at') }} &nbsp;
                    </template>

                    <strong style="color: red;">
                      {{ $filters.priceFormat(product.price, product.currency) }}
                    </strong>
                  </template>
                  <template v-else>
                    <template v-if="product.price_prefix">
                      {{ $t('cs.front.at') }}
                    </template>
                    {{ $filters.priceFormat(product.price, product.currency) }}
                  </template>
                </div>
              </div>
            </router-link>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
  </section>
</template>

<script>
import {
  Carousel, Slide, Navigation,
} from 'vue3-carousel';

export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  props: {
    products: Array,
    header: String,
  },
  data() {
    return {

    };
  },
  computed: {
    itemsToShow() {
      // eslint-disable-next-line no-restricted-globals
      if (screen.width < 1050) {
        return 1;
      }

      return 4;
    },
  },
  watch: {

  },
  methods: {

  },
  mounted() {

  },
};
</script>
