import axios from 'axios';

export default {
  login(context, loginData) {
    return axios.post(
      `${context.rootState.apiHost}/club-shop/app/login/`,
      loginData,
    ).then((response) => {
      context.commit('auth/setApiToken', response.headers['x-access-token'], { root: true });
      context.commit('profile/setMyProfile', response.data.profile, { root: true });
    });
  },
  register(context, registerProfile) {
    return axios.post(
      `${context.rootState.apiHost}/club-shop/app/register/`,
      registerProfile,
    ).then((response) => {
      context.commit('auth/setApiToken', response.headers['x-access-token'], { root: true });
      context.commit('profile/setMyProfile', response.data.profile, { root: true });
    });
  },
  sendRecoverPasswordLetter(context, forgetPasswordUsername) {
    return axios.post(
      `${context.rootState.apiHost}/club-shop/app/recover-password/`,
      { username: forgetPasswordUsername },
    ).then((response) => response);
  },
  setNewPassword(context, data) {
    return axios.put(
      `${context.rootState.apiHost}/club-shop/app/set-new-password/`,
      data,
    ).then((response) => response);
  },
};
