import { createRouter, createWebHistory } from 'vue-router';
import CheckoutAnon from '@/views/screens/checkout/CheckoutAnon.vue';
import HomeIndex from '../views/screens/home/HomeIndex.vue';
import CategoryIndex from '../views/screens/category/CategoryIndex.vue';
import SearchIndex from '../views/screens/search/SearchIndex.vue';
import ProductView from '../views/screens/product/ProductView.vue';
import PageView from '../views/screens/page/PageView.vue';
import InfoView from '../views/screens/page/InfoView.vue';
import ProfileIndex from '../views/screens/profile/ProfileIndex.vue';
import ProfileAddresses from '../views/screens/profile/ProfileAddresses.vue';
import ProfileSettings from '../views/screens/profile/ProfileSettings.vue';
import ProfileOrders from '../views/screens/profile/ProfileOrders.vue';
import AuthIndex from '../views/screens/auth/AuthIndex.vue';
import CheckoutIndex from '../views/screens/checkout/CheckoutIndex.vue';
import CheckoutAddress from '../views/screens/checkout/CheckoutAddress.vue';
import CheckoutFinalStep from '../views/screens/checkout/CheckoutFinalStep.vue';
import CheckoutThanks from '../views/screens/checkout/CheckoutThanks.vue';
import CheckoutPaymentProcess from '../views/screens/checkout/CheckoutPaymentProcess.vue';

let shopUrl;
if (window.shopUrl) {
  shopUrl = window.shopUrl;
} else {
  shopUrl = 'unknown-shop';
}

const routes = [
  {
    path: `/${shopUrl}/`,
    name: 'home',
    component: HomeIndex,
  },
  {
    path: `/${shopUrl}/auth`,
    name: 'auth',
    component: AuthIndex,
  },
  {
    path: `/${shopUrl}/auth/set-new-password/:recoverPasswordToken`,
    name: 'set-new-password',
    component: AuthIndex,
  },
  {
    path: `/${shopUrl}/checkout`,
    name: 'checkout',
    component: CheckoutIndex,
  },
  {
    path: `/${shopUrl}/checkout/payment/:paymentService/:txId`,
    name: 'checkout.payment.process',
    component: CheckoutPaymentProcess,
  },
  {
    path: `/${shopUrl}/checkout/address`,
    name: 'checkout.address',
    component: CheckoutAddress,
  },
  {
    path: `/${shopUrl}/checkout/final-step`,
    name: 'checkout.final-step',
    component: CheckoutFinalStep,
  },
  {
    path: `/${shopUrl}/checkout/anon`,
    name: 'checkout.anon',
    component: CheckoutAnon,
  },
  {
    path: `/${shopUrl}/checkout/thanks`,
    name: 'checkout.thanks',
    component: CheckoutThanks,
  },
  {
    path: `/${shopUrl}/category/:categoryId`,
    name: 'category',
    component: CategoryIndex,
  },
  {
    path: `/${shopUrl}/erima-category/:eoCategoryId`,
    name: 'eo-products',
    component: CategoryIndex,
  },
  {
    path: `/${shopUrl}/search/:searchArea/:q`,
    name: 'search',
    component: SearchIndex,
  },
  {
    path: `/${shopUrl}/product/:productId`,
    name: 'product',
    component: ProductView,
  },
  {
    path: `/${shopUrl}/eo-product/:eoProductId`,
    name: 'eo-product',
    component: ProductView,
  },
  {
    path: `/${shopUrl}/info/:type`,
    name: 'info',
    component: InfoView,
  },
  {
    path: `/${shopUrl}/page/:url`,
    name: 'page',
    component: PageView,
  },
  {
    path: `/${shopUrl}/profile`,
    name: 'profile.index',
    component: ProfileIndex,
  },
  {
    path: `/${shopUrl}/profile/addresses`,
    name: 'profile.addresses',
    component: ProfileAddresses,
  },
  {
    path: `/${shopUrl}/profile/settings`,
    name: 'profile.settings',
    component: ProfileSettings,
  },
  {
    path: `/${shopUrl}/order/:id`,
    name: 'order',
    component: ProfileOrders,
  },
  {
    path: `/${shopUrl}/profile/orders`,
    name: 'profile.orders',
    component: ProfileOrders,
  },
];

const router = createRouter({
  baseUrl: '/test/',
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
