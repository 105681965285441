import axios from 'axios';

export default {
  fetch(context, filters = {}) {
    return axios.get(
      `${context.rootState.apiHost}/club-shop/app/profile/addresses/`,
      { params: filters },
    ).then((response) => {
      context.commit('setList', response.data);

      return response;
    });
  },
  save(context, data) {
    return axios.put(
      `${context.rootState.apiHost}/club-shop/app/profile/addresses/${data.id}`,
      data,
    ).then((response) => response);
  },
  create(context, data) {
    return axios.post(
      `${context.rootState.apiHost}/club-shop/app/profile/addresses/`,
      data,
    ).then((response) => response);
  },
  delete(context, id) {
    return axios.delete(`${context.rootState.apiHost}/club-shop/app/profile/addresses/${id}`)
      .then((response) => response);
  },
};
