import axios from 'axios';

export default {
  setApiToken: (state, token) => {
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      localStorage.setItem('api-token', token);
    } else {
      delete axios.defaults.headers.common.Authorization;
      localStorage.removeItem('api-token');
    }

    state.apiToken = token;
  },
  setCart: (state, cart) => {
    state.cart = cart;
  },
};
