import axios from 'axios';

export default {
  fetch(context, filters = {}) {
    return axios.get(
      `${context.rootState.apiHost}/club-shop/app/eo-products/`,
      { params: filters },
    ).then((response) => {
      context.commit('setList', response.data);

      return response;
    });
  },
  select(context, id) {
    if (!id) {
      context.commit('select', null);
      return false;
    }

    return axios.get(`${context.rootState.apiHost}/club-shop/app/eo-products/${id}/`).then((response) => {
      context.commit('select', response.data);
      // fetch the products of the same model

      if (response.data.model_id) {
        axios.get(
          `${context.rootState.apiHost}/club-shop/app/eo-products/`,
          { params: { model_id: response.data.model_id } },
        ).then((modelResponse) => {
          context.commit('setModelList', modelResponse.data);

          return response;
        });
      }

      return response;
    });
  },
};
