<template>
  <section
    class="home-hero"
    :style="image ? `background-image: url(${image});` : `background-image: url(${defaultImage});`"
  >
    <div class="container">
      <h1 class="h1">{{ name }}</h1>
      <div>
        {{ text }}
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {

  },
  props: {
    name: String,
    image: String,
    text: String,
  },
  data() {
    return {
      defaultImage: '/img/cs-default-banner-bg.jpg',
    };
  },
  computed: {

  },
  watch: {

  },
  methods: {

  },
  mounted() {

  },
};
</script>
