<template>
  <div :class="{wrapper: true, 'cart-sidebar-open': showSideCart}">
    <header class="header header-with-alert">
      <div class="header-inner">
        <div class="container">
          <router-link v-if="shop && shop.club" :to="{ name: 'home' }" class="logo">
            <img
              v-if="shop.club.logo"
              :src="`${staticHost}/${shop.club.logo}`"
              :alt="shop.name"
            >
          </router-link>
          <main-menu ref="side_cart" />
        </div>
        <div class="search">
          <div class="container">
            <search-form />
          </div>
        </div>
      </div>
    </header>

    <router-view />

    <side-cart v-if="showSideCart" />

    <footer v-show="!showLoader" class="footer">
      <div class="footer-top">
        <div class="container">
          <footer-links-menu />
        </div>
      </div>
      <div class="footer-bottom">
        <div class="container">
          <footer-pages-menu />

          <p v-if="!prod" style="text-align: center; display: none;">
            <select
              v-model="localApiHost"
              style="padding: 1px;  margin: 1px; width: 250px;
              color: #ccc; height: auto; font-size: 11px"
            >
              <option>https://dev-api.erima-online.com/api</option>
              <option>https://cir-dev-api.erima-online.com/api</option>
              <option>http://127.0.0.1:8000/api</option>
            </select>
            <br />
            <a
              @click="changeConnectedShop()"
              href="javascript:" style=" color: #ccc; display: inline;"
            >
              <small>Change connected shop <template v-if="shop">({{ shop.url }})</template></small>
            </a>
          </p>
        </div>
      </div>
      <div v-if="!cookieConcerned" class="cookie-consent container">
        <div>
          <div class="row" style="text-align: center;">
            <div class="col-md-10" style="font-size: 14px;">
              Wir verwenden auf unserer Website nur erforderliche Cookies
              die für die grundlegende Funktionalität erforderlich sind.
              Weitere Informationen finden Sie in unserer Datenschutzinformation.
              <br>
              We only use necessary cookies on our website, which are necessary
              for basic functionality.
              Further information can be found in our data protection information.
            </div>
            <div class="col-md-2">
              <button
                @click="cookieConcern()"
                class="skew btn btn-small btn-green mr-2 mb-3 mb-md-0"
                style="float: right;"
              >
                <span>Akzeptieren / Accept</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <div class="preloader" v-if="showLoader">
      <img src="./assets/img/logo-small.svg" alt="">
    </div>
  </div>
</template>

<script>
import 'bootstrap';
import { mapGetters } from 'vuex';
import SideCart from '@/views/common/SideCart.vue';
import FooterPagesMenu from './views/common/footer/FooterPagesMenu.vue';
import FooterLinksMenu from './views/common/footer/FooterLinksMenu.vue';
import MainMenu from './views/common/header/MainMenu.vue';
import SearchForm from './views/common/header/SearchForm.vue';

export default {
  components: {
    SideCart,
    FooterPagesMenu,
    FooterLinksMenu,
    SearchForm,
    MainMenu,
  },
  data() {
    return {
      localApiHost: false,
      cookieConcerned: false,
    };
  },
  computed: {
    ...mapGetters({
      shop: 'shop/selected',
      showSideCart: 'showSideCart',
      showLoader: 'showLoader',
      staticHost: 'staticHost',
      apiHost: 'apiHost',
    }),
    prod() {
      return process.env.VUE_APP_ENV === 'p';
    },
  },
  watch: {
    localApiHost(newVal, oldVal) {
      if (oldVal) {
        localStorage.removeItem('api-token');
        localStorage.removeItem('randomId');
        localStorage.removeItem('cookieConcerned');

        this.$store.commit('setApiHost', newVal);

        document.location.reload();
      }
    },
    shop(shop) {
      document.title = this.shop.root_category_seo_title ?? `${this.shop.club.name}/ ${this.shop.name}`;

      this.setFavicon(`${this.staticHost}/${shop.club.logo}`);
    },
  },
  methods: {
    cookieConcern() {
      this.cookieConcerned = true;
      localStorage.setItem('cookieConcerned', 1);
    },
    changeConnectedShop() {
      const shopUrl = prompt('Connected shop URL');

      if (shopUrl) {
        localStorage.setItem('shop-id', shopUrl);
        localStorage.removeItem('api-token');
        localStorage.removeItem('randomId');
        localStorage.removeItem('cookieConcerned');
        document.location.reload();
      }
    },
    setFavicon(icon) {
      const links = document.querySelectorAll("link[rel~='icon']");

      links.forEach((link) => {
        // eslint-disable-next-line no-param-reassign
        link.href = icon;
      });
    },
  },
  mounted() {
    this.cookieConcerned = localStorage.getItem('cookieConcerned');
    this.localApiHost = this.apiHost;
  },
};
</script>
