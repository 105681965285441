<template>
  <div v-if="shop">
    <p class="title">{{ $t('cs.front.summary_info') }}</p>
    <ul class="details">
      <li>
        <span>{{ $t('cs.front.sum') }}:</span>
        {{ $filters.priceFormat(cart.totalProductsPrice, shop.currency) }}
      </li>
      <li v-if="cart.totalOptionsPrice>0">
        <span>{{ $t('cs.front.sum_options') }}:</span>
        {{ $filters.priceFormat(cart.totalOptionsPrice, shop.currency) }}
      </li>
      <li><span>{{ $t('cs.front.delivery_price') }}:</span>
        {{ $filters.priceFormat(shippingPrice, shop.currency) }} </li>
    </ul>
    <p class="total">
      {{ $t('cs.front.total') }}:
      <span>{{ $filters.priceFormat(cart.totalPrice+this.shippingPrice, shop.currency) }}</span>
    </p>
    <ul class="details-small">
      <li class="upper">
        <span>{{ $t('cs.front.total_without_tax') }}</span>
        {{ $filters.priceFormat(
          (
            (cart.totalPrice+shippingPrice)-cart.taxPrice-calculateTax(shippingPrice, shop.tax)
          ).toFixed(2), shop.currency) }}
      </li>
      <li class="grey">
        <span>{{ $t('cs.front.including_vat') }} ({{ shop.tax }}%).</span>
        {{ $filters.priceFormat(
            parseFloat(cart.taxPrice) + calculateTax(shippingPrice, shop.tax),
            shop.currency
        ) }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {

  },
  props: {
    cart: Object,
    shippingPrice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters({
      shop: 'shop/selected',
    }),
  },
  watch: {

  },
  methods: {
    ...mapActions('cart', {

    }),
    calculateTax(price, tax) {
      if (!price || !tax) {
        return 0;
      }

      let taxPercent = parseFloat(tax).toFixed(2);

      if (taxPercent < 10) {
        taxPercent = `0${taxPercent * 10}`;
      }

      return parseFloat(parseFloat(price - (price / parseFloat(`1.${taxPercent}`))).toFixed(2));
    },
  },
  mounted() {

  },
};
</script>
