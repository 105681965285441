export default {
  setMyProfile: (state, profile) => {
    state.me = profile;
  },
  setAnonProfile: (state, profile) => {
    state.anonProfile = profile;
  },
  setMyOrders: (state, list) => {
    state.orders = list;
  },
};
