<template>
  <p class="h3 modal-title">{{ $t('cs.front.order_number') }} {{ order.number }}</p>
  <div class="row d-none d-lg-flex order-header">
    <div class="col-lg-9">{{ $t('cs.front.product') }}</div>
    <div class="col-lg-3">
      <div class="row">
        <div class="col-6">{{ $t('cs.front.quantity') }}</div>
        <div class="col-6 text-lg-right">{{ $t('cs.front.price') }}</div>
      </div>
    </div>
  </div>
  <hr class="d-none d-lg-block">
  <div class="order-item">
    <div
      v-for="position in order.positions"
      :key="position.id"
      class="row"
    >
      <div class="col-lg-9 pb-3">
        <div v-if="position.product" class="row align-items-center align-items-lg-start">
          <div class="col-3">
            <router-link
              :to="{ name: 'product', params: { productId: position.product.id }}"
              class="img"
            >
              <img :src="position.product.image" alt="">
            </router-link>
          </div>
          <div class="col-9">
            <router-link
              :to="(!position.cs_product || !position.cs_product.deleted_at) ?
              { name: 'product', params: { productId: position.product.id }} : '#'"
              class="name"
            >
              {{ position.product.name }}
            </router-link>
            <div class="id">
              {{ $t('cs.front.product_number') }}.: {{ position.product.number }}
            </div>
            <div v-if="position.options.length" class="cart-position-options">
              <div class="positions-title">
                <u>{{ $t('cs.front.cart_position_options_title') }}</u>
              </div>
              <div v-for="option in position.options"
                :key="option.id"
                class="row w-75 position-option-row"
              >
                <div class="col-8 option-name">{{ option.name }}</div>
                <div class="col-4 p-0 text-right option-price">
                  {{ $filters.priceFormat(option.price, order.currency) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 col-lg-3 offset-3 offset-lg-0">
        <div class="row align-items-center align-items-lg-start">
          <div class="col-6 text-center">
            <b>{{ position.quantity }}</b>
          </div>
          <div class="col-6 text-lg-right">
            <b class="red">{{ $filters.priceFormat(position.price, order.currency) }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <ul class="order-sum">
    <li class="total">
      {{ $t('cs.front.total') }}:
      <span class="green">
        {{ $filters.priceFormat(order.price_with_tax, order.currency) }} *
      </span>
    </li>
    <li class="without-tax">
      {{ $t('cs.front.total_without_tax') }}:
      <span>{{ $filters.priceFormat(order.price_without_tax, order.currency) }} *</span>
    </li>
    <li class="tax">
      {{ $t('cs.front.including') }} {{ order.tax_rate_percent_1 }}%
      {{ $t('cs.front.vat') }}: {{ $filters.priceFormat(order.tax_sum, order.currency) }}
    </li>
    <li v-if="order.shipping_cost" class="shipping-cost" style="color: black;">
      {{ $t('cs.front.shipping_cost') }}:
      <span>{{ $filters.priceFormat(order.shipping_cost, order.currency) }}</span>
    </li>
  </ul>
  <hr>
  <div class="row order-bottom">
    <div class="col-lg-4">
      <div class="row">
        <div class="col-6 col-lg-12">
          <b>{{ $t('cs.front.payment_method') }}</b>
        </div>
        <div class="col-6 col-lg-12 grey">
          <span v-if="!order.payment_method || order.payment_method.default">
          {{ $t('cs.front.payment_method_cash') }}
          </span>
          <span v-else-if="order.payment_method">
          {{ order.payment_method.name }}
          </span>
        </div>
      </div>
      <div v-if="order.shipping_method" class="row">
        <div class="col-6 col-lg-12">
          <b>{{ $t('cs.front.shipping_method') }}</b>
        </div>
        <div class="col-6 col-lg-12 grey">
          <b v-if="order.shipping_method_name">
            {{ order.shipping_method_name }}
          </b>
          <b v-else-if="order.shipping_method.name_i18n">{{ $t(shm.name_i18n) }}</b>
          <b v-else>{{ order.shipping_method.name }}</b>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="row">
        <div class="col-6 col-lg-12">
          <b>{{ $t('cs.front.billing_address') }}</b>
        </div>
        <div class="col-6 col-lg-12 grey">
            {{ order.billing_company }}<br/>
            {{ order.billing_address_name }} {{ order.billing_address_surname }}<br/>
            {{ order.billing_address_street }}<br/>
            {{ order.billing_address_zip }} {{ order.billing_address_city }}<br/>
            {{ order.billing_address_country }}
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="row">
        <template
          v-if="
            order.shipping_method
            && order.shipping_method.custom
            === shippingMethodTypes.SHIPPING_METHOD_TYPE_SHIPPING_COMPANY
          ">
          <div class="col-6 col-lg-12">
            <b>{{ $t('cs.front.shipping_address') }}</b>
          </div>
          <div class="col-6 col-lg-12 grey">
            {{ order.shipping_address_company }}<br/>
            {{ order.shipping_address_name }} {{ order.shipping_address_surname }}<br/>
            {{ order.shipping_address_street }}<br/>
            {{ order.shipping_address_zip }} {{ order.shipping_address_city }}<br/>
            {{ order.shipping_address_country }}
          </div>
        </template>
        <template  v-else>
          <div class="col-6 col-lg-12">
            <b>{{ $t('cs.front.pickup_address') }}</b>
          </div>
          <div class="col-6 col-lg-12 grey">
            <!--{{ order.pickup_company }}<br/>-->
            {{ order.pickup_address_name }} {{ order.pickup_address_surname }}<br/>
            {{ order.pickup_address_street }}<br/>
            {{ order.pickup_address_zip }} {{ order.pickup_address_city }}<br/>
            {{ order.pickup_address_country }}
          </div>
        </template>

      </div>
    </div>
  </div>
</template>

<script>
import * as shippingMethodTypes from '../../../../consts/shipping_method';

export default {
  components: {

  },
  props: {
    order: Object,
  },
  data() {
    return {
      shippingMethodTypes,
    };
  },
  computed: {

  },
  watch: {

  },
  methods: {

  },
  mounted() {

  },
};
</script>
