<template>
  <main class="content checkout-page login-page">
    <div v-if="cart && cart.positions && shop" class="container">
      <a class="back-link d-lg-none" href="javascript:history.back();">
        <i class="icon icon-arrow-left"></i> {{ $t('cs.front.back') }}
      </a>

      <checkout-steps />

      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-3">
          <h4>
            <label>
              <input v-model="createAccount" value="yes" type="radio" />
              {{ $t('cs.front.create_account_or_login') }}
            </label>
          </h4>
        </div>
        <div class="col-md-3">
          <h3>
            <label>
              <input v-model="createAccount" value="no" type="radio" />
              {{ $t('cs.front.do_not_create_customer_account') }}
            </label>
          </h3>
        </div>
      </div>

      <hr />

      <auth-form v-if="createAccount === 'yes'" scenario="purchase" />

      <div v-else class="row">
        <div class="box col-md-12" @keyup.enter="goNext()">
          <div class="box-title">
            <i class="icon icon-profile-edit"></i>
            {{ $t('cs.front.create_account') }}
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <select v-model="anonProfile.type">
                  <option value="C">{{ $t('cs.front.company') }}</option>
                  <option value="PP">{{ $t('cs.front.private_person') }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <template v-if="anonProfile.type === 'C'">
                <p class="h5">{{ $t('cs.front.company_name') }}*</p>
                <div class="form-group">
                  <input
                    v-model="anonProfile.company_name"
                    type="text"
                  >
                </div>
              </template>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 d-flex pb-4">
              <b>{{ $t('cs.front.salutation') }}*</b>
              <div class="radio">
                <input
                  v-model="anonProfile.salutation"
                  id="Herr"
                  type="radio"
                  name="andere"
                  value="M"
                />
                <label for="Herr">{{ $t('cs.front.salutation_mr') }}</label>
              </div>
              <div class="radio">
                <input
                  v-model="anonProfile.salutation"
                  id="Frau"
                  type="radio"
                  name="andere"
                  value="W"
                />
                <label for="Frau">{{ $t('cs.front.salutation_miss') }} </label>
              </div>
              <div class="radio">
                <input
                  v-model="anonProfile.salutation"
                  id="Kne"
                  type="radio"
                  name="andere"
                  value="NA"
                />
                <label for="Kne">{{ $t('cs.front.salutation_na') }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p class="h5">{{ $t('cs.front.email') }}*</p>
              <div class="form-group mb-2">
                <input
                  v-model="anonProfile.email"
                  type="email"
                  :style="{border: !isEmailValid(anonProfile.email) ? '1px solid red' : ''}"
                />
              </div>
              <div class="checkbox mb-4 mb-md-0">
                <input
                  v-model="anonProfile.has_newsletter"
                  id="newsletter"
                  type="checkbox"
                />
                <label for="newsletter">{{ $t('cs.front.subscribe_to_newsletter') }}</label>
              </div>
            </div>
            <div class="col-md-6">
              <p class="h5">{{ $t('cs.front.phone') }}</p>

              <MazPhoneNumberInput
                v-model="anonProfile.phone"
                show-code-on-list
                color="primary"
                autocomplete="off"
                :countryCode="$i18n.locale.split('-')[1] ?? 'DE'"
                :translations="{
                  countrySelector: {
                    placeholder: '',
                    searchPlaceholder: $t('cs.front.search_country')
                  },
                  phoneInput: {
                    placeholder: '',
                    example: $t('cs.front.example')
                  }
                }"
                @update="phoneRes = $event"
                :success="phoneRes?.isValid"
                :preferredCountries="['DE', 'NL', 'CH', 'FR', 'AT', 'BE', 'NL']"
                country-selector-display-name
                countrySelectorWidth="17.5rem"
              />
              <div style="clear: both"></div>
              <strong
                v-if="anonProfile.phone && phoneRes && !phoneRes.isValid"
                style="color: red;"
              >
                {{ $t('cs.front.phone_is_invalid') }}
              </strong>
            </div>
          </div>
          <p class="h4 mt-4">{{ $t('cs.front.billing_address') }}</p>
          <div class="row">
            <div class="col-md-6">
              <p class="h5">{{ $t('cs.front.first_name') }}*</p>
              <div class="form-group">
                <input
                  v-model="anonProfile.billing_address.name"
                  type="text"
                />
              </div>
            </div>
            <div class="col-md-6">
              <p class="h5">{{ $t('cs.front.surname') }}*</p>
              <div class="form-group">
                <input
                  v-model="anonProfile.billing_address.surname"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div class="row pb-md-2">
            <div class="col-md-6">
              <p class="h5">{{ $t('cs.front.street_and_apartment') }}*</p>
              <div class="form-group">
                <input
                  v-model="anonProfile.billing_address.address"
                  type="text"
                />
              </div>
            </div>
            <div class="col-md-6">
              <p class="h5">{{ $t('cs.front.zip') }}*</p>
              <div class="form-group">
                <input
                  v-model="anonProfile.billing_address.zip"
                  type="text"
                />
              </div>
            </div>
            <div class="col-md-6">
              <p class="h5">{{ $t('cs.front.city') }}*</p>
              <div class="form-group mb-2">
                <input
                  v-model="anonProfile.billing_address.city"
                  type="text"
                  :placeholder="$t('cs.front.city')"
                />
              </div>
              <p class="remark">{{ $t('cs.front.city_field_note') }}</p>
            </div>
          </div>
          <p class="h4 mt-4">{{ $t('cs.front.shipping_address') }}</p>
          <p>
            <label>
              <input v-model="anonProfile.common_address" type="checkbox" />
              {{ $t('cs.front.shipping_address_the_same_with_billing') }}
            </label>
          </p>
          <template v-if="!anonProfile.common_address">
            <div class="row">
              <div class="col-md-6">
                <p class="h5">{{ $t('cs.front.first_name') }}*</p>
                <div class="form-group">
                  <input
                    v-model="anonProfile.shipping_address.name"
                    type="text"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <p class="h5">{{ $t('cs.front.surname') }}*</p>
                <div class="form-group">
                  <input
                    v-model="anonProfile.shipping_address.surname"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="row pb-md-2">
              <div class="col-md-6">
                <p class="h5">{{ $t('cs.front.street_and_apartment') }}*</p>
                <div class="form-group">
                  <input
                    v-model="anonProfile.shipping_address.address"
                    type="text"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <p class="h5">{{ $t('cs.front.zip') }}*</p>
                <div class="form-group">
                  <input
                    v-model="anonProfile.shipping_address.zip"
                    type="text"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <p class="h5">{{ $t('cs.front.city') }}*</p>
                <div class="form-group mb-2">
                  <input
                    v-model="anonProfile.shipping_address.city"
                    type="text"
                    :placeholder="$t('cs.front.city')"
                  />
                </div>
                <p class="remark">{{ $t('cs.front.city_field_note') }}</p>
              </div>
            </div>
          </template>

          <div class="text-center pt-4">
            <button
              @click="goNext()"
              class="btn btn-green"
              :disabled="!anonProfileComplete"
            >
              {{ $t('cs.front.go_next') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AuthForm from '@/views/common/AuthForm.vue';
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
import CheckoutSteps from './components/CheckoutSteps.vue';

export default {
  components: {
    AuthForm,
    CheckoutSteps,
    MazPhoneNumberInput,
  },
  data() {
    return {
      createAccount: 'no',
      anonProfile: { billing_address: {}, common_address: true, shipping_address: {} },
      phoneRes: null,
    };
  },
  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      shop: 'shop/selected',
      me: 'profile/me',
      showLoader: 'showLoader',
    }),
    anonProfileComplete() {
      return (this.anonProfile.billing_address.address
        && this.anonProfile.billing_address.name
        && this.anonProfile.billing_address.surname
        && this.anonProfile.billing_address.city
        && this.anonProfile.billing_address.zip
        && this.anonProfile.billing_address.zip.length >= 4
        && this.isEmailValid(this.anonProfile.email)
        && (!this.anonProfile.phone || (!this.phoneRes || this.phoneRes.isValid))
      );
    },
  },
  watch: {
    anonProfile: {
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal && newVal) {
          const data = JSON.stringify(newVal);
          localStorage.setItem('anonProfile', data);
        }
      },
    },
  },
  methods: {
    ...mapActions('profile', {
      fetchMe: 'fetchMe',
    }),
    ...mapActions('cart', {
      fetchCart: 'fetch',
      createOrder: 'createOrder',
    }),
    goNext() {
      if (!this.anonProfileComplete) {
        return false;
      }

      this.$store.commit('profile/setAnonProfile', this.anonProfile);

      this.$router.push({ name: 'checkout.final-step' });

      return true;
    },
    initAnonProfileData() {
      let anonProfile = localStorage.getItem('anonProfile');

      if (!anonProfile) {
        anonProfile = {
          type: 'PP',
          billing_address: {
            name: null,
            surname: null,
            company_name: null,
            address: null,
            zip: null,
            city: null,
            country: null,
          },
          common_address: true,
          shipping_address: {
            name: null,
            surname: null,
            company_name: null,
            address: null,
            zip: null,
            city: null,
            country: null,
          },
        };
      } else {
        anonProfile = JSON.parse(anonProfile);

        if (!anonProfile.shipping_address) {
          anonProfile.shipping_address = {
            name: null,
            surname: null,
            company_name: null,
            address: null,
            zip: null,
            city: null,
            country: null,
          };
          anonProfile.common_address = true;
        }
      }

      this.anonProfile = anonProfile;

      return anonProfile;
    },
    isEmailValid(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    },
  },
  mounted() {
    if (!this.cart) {
      this.fetchCart();
    }

    this.$store.commit('hideSideCart');

    this.initAnonProfileData();
  },
};
</script>
