<template>
  <main class="content profile-page">
    <div v-if="profile" class="container">
      <div class="row">
        <div class="col-md-4 col-lg-3">
          <profile-side-menu
            v-if="profile"
            :user-email="profile.email"
            :user-full-name="profile.name+' '+profile.surname"
          />
        </div>
        <div class="col-md-8 col-lg-9">
          <div class="profile-content">
            <div class="profile-order-list">
              <div class="profile-order d-none d-xl-block">
                <div class="row">
                  <div class="col-xl-2">
                    <div class="label">{{ $t('cs.front.date') }}</div>
                  </div>
                  <div class="col-xl-2">
                    <div class="label">{{ $t('cs.front.order_number') }}</div>
                  </div>
                  <div class="col-xl-3">
                    <div class="label">{{ $t('cs.front.profile.orders.list.order_address') }}</div>
                  </div>
                  <div class="col-xl-2">
                    <div class="label">{{ $t('cs.front.order_status') }}</div>
                  </div>
                  <div class="col-xl-3">
                    <div class="label">{{ $t('cs.front.actions') }}</div>
                  </div>
                </div>
              </div>
                <div
                  v-for="order in orders"
                  :key="order.id"
                  class="profile-order"
                >
                  <div class="row">
                    <div class="col-sm-6 col-xl-2">
                      <div class="label">{{ $t('cs.front.date') }}</div>
                      <div class="value"><b>{{ order.date }}</b></div>
                    </div>
                    <div class="col-sm-6 col-xl-2">
                      <div class="label">{{ $t('cs.front.order_number') }}</div>
                      <div class="value"><b>{{ order.number }}</b></div>
                    </div>
                    <div class="col-sm-6 col-xl-3">
                      <div class="label">{{ $t('cs.front.shop') }}</div>
                      <div class="value">
                        <template
                          v-if="
                            order.shipping_method
                            && order.shipping_method.custom
                            === shippingMethodTypes.SHIPPING_METHOD_TYPE_SHIPPING_COMPANY
                          ">
                            {{ order.shipping_address_street }}
                            {{ order.shipping_address_zip }}
                            {{ order.shipping_address_city }}
                        </template>
                        <template  v-else>
                            {{ order.pickup_address_street }}
                            {{ order.pickup_address_zip }}
                            {{ order.pickup_address_city }}
                        </template>
                      </div>
                    </div>
                    <div class="col-sm-6 col-xl-2">
                      <div class="label">{{ $t('cs.front.order_status') }}</div>
                      <div
                        :class="`status ${orderTypes.statuses.find((el) =>
                        el.value === order.status).cssClass}`">
                        {{ $t('cs.front.order.' +
                            orderTypes.statuses.find((el) => el.value === order.status).name) }}
                      </div>
                    </div>
                    <div class="col-xl-3">
                      <div class="label">{{ $t('cs.front.actions') }}</div>
                      <button
                        @click="selected = order"
                        class="btn btn-small"
                        type="button"
                      >
                        {{ $t('cs.front.view') }}
                      </button>
                    </div>
                  </div>
                  <hr />
                </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="selected"
        class="modal show in"
        style="display: block;"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <button
              @click="selected = false"
              type="button"
              class="close"
              data-dismiss="modal"
            >
              <i class="icon icon-close"></i>
            </button>
            <div class="modal-body">
              <profile-order :order="selected" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProfileOrder from './components/ProfileOrder.vue';
import ProfileSideMenu from './components/ProfileSideMenu.vue';
import * as pageTypes from '../../../consts/page';
import * as orderTypes from '../../../consts/order';
import * as shippingMethodTypes from '../../../consts/shipping_method';

export default {
  components: {
    ProfileOrder,
    ProfileSideMenu,
  },
  data() {
    return {
      openedOrder: false,
      profile: null,
      selected: false,
      pageTypes,
      orderTypes,
      shippingMethodTypes,
    };
  },
  computed: {
    ...mapGetters({
      me: 'profile/me',
      orders: 'profile/orders',
      shop: 'shop/selected',
    }),
  },
  watch: {
    me(newVal) {
      this.profile = newVal;
    },
  },
  methods: {
    ...mapActions('profile', {
      fetchMe: 'fetchMe',
      fetchOrders: 'fetchOrders',
    }),
  },
  mounted() {
    this.profile = this.me;
    this.fetchMe().finally(() => {
      if (!this.me || !this.me.id) {
        this.$router.push({ name: 'auth' });
      }
    });

    this.$store.commit('showLoader');
    this.fetchOrders().finally(() => {
      this.$store.commit('hideLoader');
    });
  },
};
</script>
