import axios from 'axios';
import router from '@/router';

import { useToast } from 'vue-toast-notification';

const toast = useToast();

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response || !error.response.status) {
      toast.error('Connection Error.');
      return false;
    }

    switch (error.response.status) {
      case 401:
        // toast.warning('Need auth');
        router.push({ name: 'auth' });
        break;

      case 403:
        // toast.warning('Need auth');
        // router.push({ name: 'auth' });
        break;

      case 400:
        if (error.response.data.message) {
          toast.warning(error.response.data.message);
        } else {
          toast.warning('Validation Error');
        }

        break;

      case 422:
        if (error.response.data.message) {
          toast.warning(error.response.data.message);
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(error.response.data.errors)) {
          value.forEach((message) => {
            toast.warning(`${key}: ${message}`);
          });
        }
        break;

      case 404:
        toast.warning('Not found');
        break;

      case 500:
        toast.error('Server Error');

        if (error.response.data) {
          toast.error(error.response.data.message);
        }

        break;

      default:
        break;
    }

    return Promise.reject(error);
  },
);

export default axios;
