<template>
  <main class="content checkout-page">
    <div v-if="cart && cart.positions && shop" class="container">
      <a class="back-link d-lg-none" href="javascript:history.back();">
        <i class="icon icon-arrow-left"></i> {{ $t('cs.front.back') }}
      </a>

      <checkout-steps />

      <div class="row">
          <div class="col-lg-8 checkout-address">
            <div class="box">
              <checkout-positions v-if="cart.positions" :positions="cart.positions" />

              <p v-if="!cart.positions.length">{{ $t('cs.front.empty_cart') }}.</p>
            </div>
            <div
              v-if="shop"
              class="box"
            >
              <div class="box-header">
                <i class="icon icon-delivery"></i> {{ $t('cs.front.select_shipping_method') }}
              </div>
              <div class="row">
                <div class="col-md-6">
                  <template
                    v-for="shm in shop.shipping_methods"
                    :key="shm.id"
                  >
                    <div
                      class="radio"
                    >
                      <input
                        v-model="shippingMethodId"
                        :id="'shm1' + shm.id"
                        :value="shm.id"
                        type="radio"
                      />
                      <label
                        :for="'shm1' + shm.id"
                        class="mb-2"
                        :data-title="`ID ${shm.id}`"
                      >

                        <b v-if="shm.shop_settings && shm.shop_settings.name !== shm.name">
                          {{ shm.shop_settings.name }}
                        </b>
                        <b v-else-if="shm.name_i18n">{{ $t(shm.name_i18n) }}</b>
                        <b v-else>{{ shm.name }}</b>

                        <span>
                          ({{ $filters.priceFormat(shippingPrice(shm.id), shop.currency) }})
                        </span>
                      </label>
                    </div>
                    <p v-if="shm.description" class="pickup">{{ $t(shm.description) }}</p>
                  </template>
                </div>
                <div
                  v-if="shippingMethod
                        && shippingMethod.shop_settings
                        && shippingMethod.custom
                          === shippingMethodTypes.SHIPPING_METHOD_TYPE_CUSTOM_PICKUP"
                  class="col-md-6"
                >
                  <div class="box-header">
                    {{ $t('cs.front.pickup_address') }}
                  </div>

                  {{ shippingMethod.shop_settings.pickup_address_name1 }}<br />
                  {{ shippingMethod.shop_settings.pickup_address_name2 }}<br />
                  {{ shippingMethod.shop_settings.pickup_address_address }}<br />
                  {{ shippingMethod.shop_settings.pickup_address_zip }}
                  {{ shippingMethod.shop_settings.pickup_address_city }}
                </div>
              </div>
            </div>

            <div v-if="me && me.id">
              <template v-if="!customShipping">
                <div class="box">
                  <div class="box-header">
                    <i class="icon icon-address"></i> {{ $t('cs.front.pickup_address') }}
                  </div>
                  <div class="box-text">
                    {{ shop.pickup_address.name_1 }} {{ shop.pickup_address.name_2 }}<br>
                    {{ shop.pickup_address.address }}<br>
                    {{ shop.pickup_address.zip }} {{ shop.pickup_address.city }}<br>
                    {{ shop.pickup_address.country }}
                  </div>
                  <div class="box-btns">

                  </div>
                </div>
              </template>
              <template v-else-if="shippingMethod.custom
                  !== shippingMethodTypes.SHIPPING_METHOD_TYPE_CUSTOM_PICKUP">
                <div class="box">
                  <div class="box-header">
                    <i class="icon icon-address"></i> {{ $t('cs.front.shipping_address') }}
                  </div>
                  <div v-if="me.shipping_address" class="box-text">
                    {{ me.shipping_address.name }} {{ me.shipping_address.surname }}<br>
                    {{ me.shipping_address.address }}<br>
                    {{ me.shipping_address.zip }} {{ me.shipping_address.city }}<br>
                    {{ me.shipping_address.country }}<br>
                  </div>
                  <div class="box-btns">
                    <router-link
                      v-if="!auth"
                      :to="{ name: 'checkout.anon' }"
                      class="btn btn-small"
                    >
                      {{ $t('cs.front.change') }}
                    </router-link>
                    <button
                      v-else
                      @click="shippingAddressSelectModal=true"
                      class="btn btn-small"
                    >
                      {{ $t('cs.front.change') }}
                    </button>
                  </div>
                </div>
              </template>
            </div>
            <div v-else>
              <template v-if="!customShipping">
                <div class="box">
                  <div class="box-header">
                    <i class="icon icon-address"></i> {{ $t('cs.front.pickup_address') }}
                  </div>
                  <div class="box-text">
                    {{ shop.pickup_address.name_1 }} {{ shop.pickup_address.name_2 }}<br>
                    {{ shop.pickup_address.address }}<br>
                    {{ shop.pickup_address.zip }} {{ shop.pickup_address.city }}<br>
                    {{ shop.pickup_address.country }}
                  </div>
                  <div class="box-btns">

                  </div>
                </div>
              </template>
              <template
                v-else-if="shippingMethod.custom
                  !== shippingMethodTypes.SHIPPING_METHOD_TYPE_CUSTOM_PICKUP"
              >
                <div v-if="!me.common_address" class="box">
                  <div class="box-header">
                    <i class="icon icon-address"></i> {{ $t('cs.front.shipping_address') }}
                  </div>
                  <div v-if="me.shipping_address" class="box-text">
                    {{ me.shipping_address.name }} {{ me.shipping_address.surname }}<br>
                    {{ me.shipping_address.address }}<br>
                    {{ me.shipping_address.zip }} {{ me.shipping_address.city }}<br>
                    {{ me.shipping_address.country }}<br>
                  </div>
                  <div class="box-btns">
                    <router-link
                      :to="{ name: 'checkout.anon' }"
                      class="btn btn-small"
                    >
                      {{ $t('cs.front.change') }}
                    </router-link>
                  </div>
                </div>
                <div v-else class="box">
                  <div class="box-header">
                    <i class="icon icon-address"></i> {{ $t('cs.front.shipping_address') }}
                  </div>
                  <div v-if="me.billing_address" class="box-text">
                    {{ me.billing_address.name }} {{ me.billing_address.surname }}<br>
                    {{ me.billing_address.address }}<br>
                    {{ me.billing_address.zip }} {{ me.billing_address.city }}<br>
                    {{ me.billing_address.country }}<br>
                  </div>
                  <div class="box-btns">
                    <router-link
                      :to="{ name: 'checkout.anon' }"
                      class="btn btn-small"
                    >
                      {{ $t('cs.front.change') }}
                    </router-link>
                  </div>
                </div>
              </template>
            </div>

            <div class="box">
              <div class="box-header">
                <i class="icon icon-address"></i> {{ $t('cs.front.billing_address') }}
              </div>
              <div v-if="me.billing_address" class="box-text">
                {{ me.billing_address.name }} {{ me.billing_address.surname }}<br>
                {{ me.billing_address.address }}<br>
                {{ me.billing_address.zip }} {{ me.billing_address.city }}<br>
                {{ me.billing_address.country }}<br>
              </div>
              <div class="box-btns">
                <router-link
                  v-if="!auth"
                  :to="{ name: 'checkout.anon' }"
                  class="btn btn-small"
                >
                  {{ $t('cs.front.change') }}
                </router-link>
                <button
                  v-else
                  @click="addressSelectModal=true"
                  class="btn btn-small"
                >
                  {{ $t('cs.front.change') }}
                </button>
              </div>
            </div>

            <div v-if="shop" class="box">
              <div class="box-header">
                <i class="icon icon-money"></i> {{ $t('cs.front.select_payment_method') }}
              </div>

              <template v-for="pm in paymentMethods" :key="pm.id">
                <div class="radio" style="height: 34px; margin: 1rem;">
                  <input
                    v-model="paymentMethodId"
                    :id="'pm1' + pm.id"
                    :value="pm.id"
                    type="radio"
                  />
                  <label
                    :for="'pm1' + pm.id"
                    class="mb-2"
                  >
                    <img
                      v-if="pm.logo_url"
                      :src="pm.logo_url"
                      style="height: 34px;"
                      :alt="pm.name"
                    />

                    <template v-else>
                      <b v-if="pm.name_i18n">{{ $t(pm.name_i18n) }}</b>
                      <b v-else>{{ pm.name }}</b>
                    </template>

                    <template
                      v-if="pm.group === paymentMethodTypes.PAYMENT_METHOD_GROUP_MOLLIE"
                    >
                      &nbsp; {{ $t(pm.name_i18n) }}
                    </template>
                  </label>
                </div>
                <p v-if="pm.description" class="pickup">{{ $t(pm.description) }}</p>
              </template>
            </div>
          </div>
          <div v-if="cart.positions.length > 0" class="col-lg-4">
            <div class="box summary">
              <checkout-summary
                :cart="cart"
                :shippingPrice="this.shippingPrice(this.shippingMethodId)"
              />

              <div class="text-center">
                <p>
                  <label>
                    <input type="checkbox" v-model="agreement" />
                    {{ $t('cs.front.i_agree_to') }}
                    <router-link
                      :to="{ name: 'info', params: { type: pageTypes.TYPE_PRIVACY }}"
                      class="small under blue"
                    >
                      {{ $t('cs.front.privacy_policy') }}
                    </router-link>
                  </label>
                </p>
                <a
                  @click="createOrderWrapper()"
                  :class="{btn: true, 'btn-green': true, disabled: orderIsNotOk}"
                  href="javascript:">
                  {{ $t('cs.front.create_order') }}
                </a>
              </div>
            </div>
            <div
              v-if="fileUploadsRequired"
              class="box uploads"
            >
              <div v-if="textToUploadSelected" class="text-center">
                <p>{{ $t('cs.front.order_options_text_uploads_info') }}</p>
                <textarea rows=10 v-model="textToUpload"></textarea>
              </div>
              <div v-if="!textToUploadSelected" class="text-center">
                <p>{{ $t('cs.front.order_options_file_uploads_info') }}</p>
                <label class="file-upload btn btn-green">
                  <input
                    type="file"
                    id="orderFileInput"
                    @change="fileSelected"
                    class="file-upload"
                    ref="fileToUpload"
                  />
                  {{ $t('cs.front.order_options_file_uploads_button') }} *
                </label><br/>
                <template
                  v-if="preUploadedFile && preUploadedFile.fileName && !textToUploadSelected"
                >
                  {{ preUploadedFile.fileName }}
                  <a @click="clearFileToUpload" href="javascript:;" class="ml-2">
                    <i class="fa fa-close"></i>
                  </a>
                </template>
              </div>
              <div v-if="fileOrTextUploadsRequired" class="text-center mb-2">
                <input
                  type="checkbox"
                  id="textToUploadSelectedInput"
                  v-model="textToUploadSelected"
                />
                <label
                  for="textToUploadSelectedInput"
                  style="
                    display: inline;
                    padding-left: 0.2rem;
                  "
                >
                  {{ $t('cs.front.order_options_text_uploads_selection') }}
                </label>
              </div>
            </div>
          </div>
        </div>

    </div>
  </main>
  <div
    v-if="addressSelectModal"
    class="modal show in"
    style="display: block;"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <button
          @click="closeModals()"
          type="button"
          class="close"
        >
          <i class="icon icon-close"></i>
        </button>
        <div class="modal-body px-lg-5">
          <div class="h3">{{ $t('cs.front.checkout_address_select_modal_title') }}</div>
          <addresses-list
             :type="addressTypes.TYPE_BILLING"
             :isCompany="me.type.toUpperCase() === 'C'"
             :deleteButtonDisabled="true"
             :editButtonDisabled="true"
             :addButtonDisabled="true"
             @changed="fetchMe() && closeModals()"
          />
        </div>
        <router-link
          :to="{ name: 'profile.addresses' }"
          class="btn btn-small btn-black px-3"
        >
          {{ $t('cs.front.new_customer_address') }}
        </router-link>
      </div>
    </div>
  </div>
  <div
    v-if="shippingAddressSelectModal"
    class="modal show in"
    style="display: block;"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <button
          @click="closeModals()"
          type="button"
          class="close"
        >
          <i class="icon icon-close"></i>
        </button>
        <div class="modal-body px-lg-5">
          <div class="h3">{{ $t('cs.front.checkout_address_select_modal_title') }}</div>
          <addresses-list
            :type="addressTypes.TYPE_SHIPPING"
            :isCompany="me.type.toUpperCase() === 'C'"
            :deleteButtonDisabled="true"
            :editButtonDisabled="true"
            :addButtonDisabled="true"
            @changed="fetchMe() && closeModals()"
          />
        </div>

        <router-link
          :to="{ name: 'profile.addresses' }"
          class="btn btn-small btn-black"
        >
          {{ $t('cs.front.new_customer_address') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CheckoutPositions from './components/CheckoutPositions.vue';
import CheckoutSteps from './components/CheckoutSteps.vue';
import CheckoutSummary from './components/CheckoutSummary.vue';
import AddressesList from '../profile/components/AddressesList.vue';
import * as pageTypes from '../../../consts/page';
import * as addressTypes from '../../../consts/address';
import * as shippingMethodTypes from '../../../consts/shipping_method';
import * as paymentMethodTypes from '../../../consts/payment_method';

export default {
  components: {
    CheckoutPositions,
    CheckoutSteps,
    CheckoutSummary,
    AddressesList,
  },
  data() {
    return {
      agreement: false,
      pageTypes,
      addressSelectModal: false,
      shippingAddressSelectModal: false,
      fileToUpload: null,
      textToUploadSelected: false,
      textToUpload: '',
      paymentMethodId: 1,
      shippingMethodId: 1,
      addressTypes,
      shippingMethodTypes,
      paymentMethodTypes,
    };
  },
  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      shop: 'shop/selected',
      me: 'profile/me',
      showLoader: 'showLoader',
      apiToken: 'auth/apiToken',
      preUploadedFile: 'orderFile/preUploadedFile',
    }),
    paymentMethods() {
      const paymentMethods = this.shop.payment_methods.filter(
        (pm) => !this.shippingMethod?.excluded_payment_methods.includes(pm.id),
      );

      // if (!paymentMethods.length) {
      // return this.shop.payment_methods.filter((el) => el.default);
      // }

      return paymentMethods;
    },
    shippingMethods() {
      const shippingMethods = this.shop.shipping_methods.filter(
        (pm) => !pm.excluded_payment_methods.includes(this.paymentMethod.id),
      );

      return shippingMethods;
    },
    shippingMethod() {
      if (!this.shippingMethodId) {
        return false;
      }
      return this.shop.shipping_methods.find((el) => el.id === this.shippingMethodId);
    },
    paymentMethod() {
      if (!this.paymentMethodId) {
        return false;
      }

      return this.shop.payment_methods.find((el) => el.id === this.paymentMethodId);
    },
    customShipping() {
      if (!this.shippingMethod) {
        return false;
      }

      return this.shippingMethod?.custom
        !== this.shippingMethodTypes.SHIPPING_METHOD_TYPE_DEFAULT_PICKUP;
    },
    fileUploadsRequired() {
      return 0;
      /*
      return (this.cart && this.cart.positions)
        ? this.cart.positions.filter((a) => a.options
            && a.options.filter((b) => b.fileRequired && !b.additional_info_not_required)
              .length > 0).length
        : 0; */
    },
    auth() {
      return (this.apiToken && this.me && this.me.id);
    },
    orderIsNotOk() {
      return !this.agreement
        || !this.paymentMethods?.length
        || this.showLoader
        || (
          this.fileOrTextUploadsRequired
          && (!this.textToUpload || this.textToUpload.length < 2)
          && !this.preUploadedFile
        );
    },
    fileOrTextUploadsRequired() {
      return 0;
      /*
      const minItems = (this.shop) ? this.shop.max_items_for_text_of_order : 3;

      return this.fileUploadsRequired > 0
        && this.fileUploadsRequired <= minItems; */
    },
  },
  watch: {
    textToUploadSelected(newVal) {
      if (!newVal) {
        this.textToUpload = '';
        localStorage.removeItem('orderText');
      }
      this.fileToUpload = null;
      if (this.$refs.fileToUpload) this.$refs.fileToUpload.value = '';
    },
    shop() {
      if (this.shop) {
        this.paymentMethodId = this.me?.payment_method_id ?? this.shop.default_payment_method;

        this.setDefaultShippingAddress();
      }
    },
    shippingMethodId() {
      if (!this.paymentMethods.find((el) => el.id === this.paymentMethodId)) {
        this.paymentMethodId = this.paymentMethods[0] ? this.paymentMethods[0].id : 1;
      }
    },
  },
  methods: {
    ...mapActions('profile', {
      fetchMe: 'fetchMe',
    }),
    ...mapActions('cart', {
      fetchCart: 'fetch',
      createPaymentTransaction: 'createPaymentTransaction',
    }),
    ...mapActions('order', {
      createOrder: 'create',
    }),
    ...mapActions('orderFile', {
      preUploadFile: 'preUpload',
      clearPreUploadedFile: 'clearPreUploadedFile',
      setPreUploadFile: 'setPreUploadFile',
    }),
    createOrderWrapper() {
      if (!this.me.billing_address) {
        this.$toast.warning('Billing address not set');
        return false;
      }

      if (this.orderIsNotOk) {
        return false;
      }

      this.$store.commit('showLoader');

      let shippingAddressId = this.me.shipping_address.id;
      if (this.me.common_address) {
        shippingAddressId = this.me.billing_address.id;
      }

      const data = {
        payment_method_id: this.paymentMethodId,
        shipping_method_id: this.shippingMethodId,
        billing_address_id: this.me.billing_address.id,
        shipping_address_id: shippingAddressId,
        anon_profile: (this.auth) ? [] : this.me,
      };

      if (this.fileUploadsRequired) {
        if (this.textToUploadSelected) {
          data.text_to_file = this.textToUpload;
          localStorage.setItem('orderText', data.text_to_file);
        } else {
          data.uploaded_file = this.preUploadedFile?.file;
        }
      }

      return this.createOrder(data).then((response) => {
        if (response.data.payment_link) {
          document.location = response.data.payment_link;
        } else {
          this.$router.push({ name: 'checkout.thanks' });
        }
      }).finally(() => {
        this.$store.commit('hideLoader');
      });
    },
    fileSelected(data) {
      const val = data.target;
      this.fileToUpload = null;
      if (val.files) {
        const file = val.files[0];
        if (!['exe', 'sql', 'php'].includes(file.type.split('/')[1]) && file.size <= 1024 * 1024 * 10) {
          this.fileToUpload = file;

          const fileData = {
            fileToUpload: this.fileToUpload,
          };

          this.$store.commit('showLoader');
          this.preUploadFile(fileData).finally(() => {
            this.$store.commit('hideLoader');
          });

          return true;
        }
        this.$toast.warning(this.$t('cs.front.file_disabled'));
      }
      val.value = '';
      return false;
    },
    clearFileToUpload() {
      this.clearPreUploadedFile();
    },
    shippingPrice(shippingMethodId) {
      const { totalPrice } = this.cart;

      const data = this.shop.shipping_methods
        .find((e) => e.id === shippingMethodId)?.shop_price_conditions;

      if (!data) {
        return 0;
      }

      data.sort((a, b) => parseFloat(a.total_from) - parseFloat(b.total_from));

      let price = null;

      for (let i = 0; i < data.length; i += 1) {
        const currentData = data[i];
        if (
          currentData
          && (parseFloat(currentData.total_from) > 0 || parseFloat(currentData.price) > 0)
        ) {
          const totalFrom = parseFloat(currentData.total_from).toFixed(2);

          if (totalPrice >= totalFrom) {
            price = parseFloat(currentData.price).toFixed(2);
          }
        }
      }

      if (!price) {
        return 0;
      }

      return parseFloat(price);
    },
    closeModals() {
      this.shippingAddressSelectModal = false;
      this.addressSelectModal = false;
    },
    setDefaultShippingAddress() {
      if (!this.shop.shipping_methods.find((el) => el.id === this.shop.default_shipping_method)) {
        this.shippingMethodId = this.shop.shipping_methods[0]?.id;
      } else {
        this.shippingMethodId = this.shop.default_shipping_method;
      }
    },
  },
  mounted() {
    if (!this.cart) {
      this.fetchCart();
    }

    if (!this.me) {
      this.fetchMe();
    }

    if (this.shop) {
      this.paymentMethodId = this.me?.payment_method_id ?? this.shop.default_payment_method;
      this.shippingMethodId = this.shop.default_shipping_method;
      this.setDefaultShippingAddress();
    }

    if (localStorage.getItem('preUploadedOrderFile')) {
      this.setPreUploadFile(JSON.parse(localStorage.getItem('preUploadedOrderFile')));
    }

    if (localStorage.getItem('orderText')) {
      this.textToUploadSelected = true;
      this.textToUpload = localStorage.getItem('orderText');
    }

    this.$store.commit('hideSideCart');
  },
};
</script>
<style>
.file-upload input {
  display: none;
}

</style>
