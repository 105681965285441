<template>
  <div class="row">
    <div
      v-for="address in addresses.filter(el => el.type === type)"
      :key="address.id"
      class="col-lg-6 col-xl-4"
    >
      <div class="box">
        <div class="box-header">
          <i class="icon icon-address"></i>
          <template v-if="address.type === addressTypes.TYPE_BILLING">
            {{ $t('cs.front.billing_address') }}
          </template>
          <template  v-if="address.type === addressTypes.TYPE_SHIPPING">
            {{ $t('cs.front.shipping_address') }}
          </template>

          <div
            v-if="!address.is_main && !deleteButtonDisabled"
            style="position: absolute; right: 1rem; top: 1rem;"
          >
            <a @click="deleteAddressWrapper(address.id)" href="javascript:">❌</a>
          </div>
        </div>
        <div class="box-text">
          <template v-if="isCompany">
            {{ address.company_name }}<br>
          </template>
          {{ address.name }} {{ address.surname }}<br>
          {{ address.address }}<br>
          {{ address.zip }} {{ address.city }}<br />
          {{ address.country }}
        </div>
        <div class="box-btns">
          <button
            v-if="!editButtonDisabled"
            @click="selected = address"
            class="btn btn-small"
            type="button"
          >
            {{ $t('cs.front.edit') }}
          </button>
          <button
            v-if="!address.is_main"
            @click="makeDefaultWrapper(address)"
            class="btn btn-small btn-black px-3"
            type="button"
          >
            {{ $t('cs.front.make_default') }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <button
    v-if="!editButtonDisabled"
    @click="newBAOpened=true"
    class="btn btn-small btn-black px-3"
    type="button"
    data-toggle="modal"
    data-target="#modalAddress"
  >
    {{ $t('cs.front.new_customer_address') }}
  </button>

  <div
    class="popups-layout"
  >
    <div v-if="selected" class="modal show in" id="modalAddressEditor" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <button
            @click="selected = false"
            type="button"
            class="close"
            data-dismiss="modal"
          >
            <i class="icon icon-close"></i>
          </button>
          <div class="modal-body px-lg-5">
            <template v-if="isCompany">
                <p class="h5">{{ $t('cs.front.company_name') }}</p>
                <div class="form-group">
                  <input
                    v-model="selected.company_name"
                    type="text"
                  />
                </div>
            </template>
            <p class="h5">{{ $t('cs.front.first_name') }}</p>
            <div class="form-group">
              <input
                v-model="selected.name"
                type="text"
              />
            </div>

            <p class="h5">{{ $t('cs.front.surname') }}</p>
            <div class="form-group">
              <input
                v-model="selected.surname"
                type="text"
              />
            </div>

            <p class="h5">{{ $t('cs.front.street_and_apartment') }}</p>

            <div class="form-group">
              <input
                v-model="selected.address"
                type="text"
              />
            </div>

            <p class="h5">{{ $t('cs.front.zip') }}*</p>
            <div class="form-group">
              <input
                v-model="selected.zip"
                type="text"
              />
            </div>

            <p class="h5">{{ $t('cs.front.city') }}*</p>
            <div class="form-group">
              <input
                v-model="selected.city"
                type="text"
                placeholder="Ort"
              />
            </div>

            <button
              @click="saveAddressWrapper()"
              class="btn btn-small"
              type="button"
            >
              {{ $t('cs.front.save') }}
            </button>
          </div>
        </div>
        <div class="col-lg-7">
        </div>
      </div>
    </div>

    <div v-if="newBAOpened"
         class="modal show in" id="modalNewAddress" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <button
            @click="newBAOpened = false"
            type="button"
            class="close"
            data-dismiss="modal"
          >
            <i class="icon icon-close"></i>
          </button>
          <div class="modal-body px-lg-5">
            <template v-if="isCompany">
                <p class="h5">{{ $t('cs.front.company_name') }}</p>
                <div class="form-group">
                  <input
                    v-model="newAddress.company_name"
                    type="text"
                  />
                </div>
            </template>
            <p class="h5">{{ $t('cs.front.first_name') }}</p>
            <div class="form-group">
              <input
                v-model="newAddress.name"
                type="text"
              />
            </div>
            <p class="h5">{{ $t('cs.front.surname') }}</p>
            <div class="form-group">
              <input
                v-model="newAddress.surname"
                type="text"
              />
            </div>

            <p class="h5">{{ $t('cs.front.street_and_apartment') }}</p>

            <div class="form-group">
              <input
                v-model="newAddress.address"
                type="text"
              />
            </div>

            <p class="h5">{{ $t('cs.front.zip') }}*</p>
            <div class="form-group">
              <input
                v-model="newAddress.zip"
                type="text"
              />
            </div>

            <p class="h5">{{ $t('cs.front.city') }}*</p>
            <div class="form-group">
              <input
                v-model="newAddress.city"
                type="text"
              />
            </div>

            <button
              @click="createAddressWrapper()"
              class="btn btn-small"
              type="button"
            >
              {{ $t('cs.front.save') }}
            </button>
          </div>
        </div>
        <div class="col-lg-7">
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import * as addressTypes from '../../../../consts/address';

export default {
  components: {
  },
  props: {
    isCompany: Boolean,
    deleteButtonDisabled: Boolean,
    editButtonDisabled: Boolean,
    addButtonDisabled: Boolean,
    type: {
      type: String,
      default: addressTypes.TYPE_BILLING,
    },
  },
  data() {
    return {
      newBAOpened: false,
      selected: false,
      newAddress: {
        is_main: false,
      },
      addressTypes,
    };
  },
  computed: {
    ...mapGetters({
      addresses: 'address/list',
    }),
  },
  watch: {

  },
  methods: {
    ...mapActions('profile', {
    }),
    ...mapActions('address', {
      saveAddress: 'save',
      createAddress: 'create',
      loadAddresses: 'fetch',
      deleteAddress: 'delete',
    }),
    saveAddressWrapper() {
      const billingAddress = { ...this.selected };
      billingAddress.type = this.type;

      return this.saveAddress(billingAddress).then(() => {
        this.$toast.success(this.$t('cs.front.saved_alert'));
        this.selected = false;
      });
    },
    createAddressWrapper() {
      this.newAddress.type = this.type;

      return this.createAddress(this.newAddress).then(() => {
        this.$toast.success(this.$t('cs.front.saved_alert'));
        this.newBAOpened = false;
        this.loadAddresses();
      });
    },
    makeDefaultWrapper(address) {
      const mainAddress = { ...address };
      mainAddress.is_main = 1;

      this.saveAddress(mainAddress).then(() => {
        this.$toast.success(this.$t('cs.front.done_alert'));
        this.loadAddresses();
        this.newBAOpened = false;
        this.$emit('changed');
      });
    },
    deleteAddressWrapper(id) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(this.$t('cs.front.are_you_sure'))) {
        this.deleteAddress(id).then(() => {
          this.loadAddresses();
        });
      }
    },
  },
  mounted() {
    this.loadAddresses();
  },
};
</script>
