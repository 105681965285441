<template>
  <main class="content home-page">
    <template v-for="block in blocks" :key="block.id">
      <products-slider
        v-if="block.type === contentTypes.TYPE_SLIDER"
        :products="block.products"
        :header="block.name"
      />

      <banner-block
        v-if="block.type === contentTypes.TYPE_BANNER"
        :name="block.name"
        :text="block.text"
        :image="block.banner"
      />
    </template>

    <sharing-box v-if="shop" :title="shop.name" />
  </main>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { mapGetters, mapActions } from 'vuex';
import SharingBox from '@/views/common/SharingBox.vue';
import BannerBlock from '../../common/content_blocks/BannerBlock.vue';
import ProductsSlider from '../../common/content_blocks/ProductsSlider.vue';
import * as contentTypes from '../../../consts/content_blocks';

export default {
  components: {
    SharingBox,
    BannerBlock,
    ProductsSlider,
  },
  data() {
    return {
      contentTypes,
    };
  },
  computed: {
    ...mapGetters({
      blocks: 'contentBlock/list',
      shop: 'shop/selected',
    }),
  },
  watch: {
    shop(shop) {
      if (shop) {
        document.title = shop.root_category_seo_title
          ?? `${shop.club.name} / ${shop.name}`;
      }
    },
  },
  methods: {
    ...mapActions('contentBlock', {
      fetchBlocks: 'fetch',
    }),
  },
  mounted() {
    this.$store.commit('showLoader');
    this.fetchBlocks().then(() => {
      this.$store.commit('hideLoader');
    });

    if (this.shop) {
      document.title = this.shop.root_category_seo_title
        ?? `${this.shop.club.name} / ${this.shop.name}`;
    }
  },
};
</script>
