export default {
  me: (state) => {
    if (state.me) {
      return state.me;
    }

    if (state.anonProfile) {
      return state.anonProfile;
    }

    if (localStorage.getItem('anonProfile')) {
      return JSON.parse(localStorage.getItem('anonProfile'));
    }

    return [];
  },
  orders: (state) => state.orders,
};
