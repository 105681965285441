import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueSocialSharing from 'vue-social-sharing';
import axios from 'axios';
import translations from '@/i18n/phrases';
import App from './App.vue';
// import './registerServiceWorker';
import router from './router';
import store from './store';
import 'bootstrap';

import './handlers/axios_interceptors';
import './dom_helpers/jquery_scripts';
import './assets/sass/styles.scss';

let shopUrl;

if (window.shopUrl) {
  shopUrl = window.shopUrl;
} else {
  shopUrl = localStorage.getItem('shop-id');
}

if (!shopUrl) {
  shopUrl = 'test';
}

let randomId = localStorage.getItem('randomId');

if (!randomId) {
  randomId = `cs-${shopUrl.slice(0, 10)}-${Math.random().toString(36).slice(2, 12)}-${Math.random().toString(36).slice(2, 12)}-${new Date().getTime().toString().slice(-5)}`;
  localStorage.setItem('randomId', randomId);
}

axios.defaults.headers.common['X-Front-User-Id'] = randomId;

const apiToken = localStorage.getItem('api-token');
if (apiToken) {
  store.commit('auth/setApiToken', apiToken);
  // store.dispatch('profile/fetchMe');
}

const i18n = createI18n(translations);

const app = createApp(App);

store.dispatch('shop/select', shopUrl).then(() => {
  const locale = store.getters['shop/selected'].locale.name;
  store.dispatch('profile/fetchMe', shopUrl);
  i18n.global.locale = locale;
  axios.defaults.headers.common.Locale = locale;
});

app.config.globalProperties.$filters = {
  priceFormat(value, currency = 'EUR') {
    const options = { style: 'currency', currency };
    const numberFormat = new Intl.NumberFormat('de-DE', options);

    return numberFormat.format(value);
  },
};

app.use(store)
  .use(router)
  .use(i18n)
  .use(VueSocialSharing)
  .use(VueToast, {
    position: 'bottom-right',
    duration: 5000,
    theme: 'primary',
  })
  .mount('#app');
