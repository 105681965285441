<template>
  <main class="content checkout-page">
    <div v-if="cart && cart.positions && me && shop" class="container">
      <a class="back-link d-lg-none" href="javascript:history.back();">
        <i class="icon icon-arrow-left"></i> {{ $t('cs.front.back') }}
      </a>

      <checkout-steps />

      <div class="row">
        <div class="col-lg-8 checkout-address">
          <div class="box">
            <div class="box-header">
              <i class="icon icon-address"></i> {{ $t('cs.front.pickup_address') }}
            </div>
            <div class="box-text">
              {{ shop.name }}<br>
              {{ shop.pickup_address.name_1 }} {{ shop.pickup_address.name_2 }}
              {{ shop.pickup_address.name_2 }}<br>
              {{ shop.pickup_address.street }}<br>
              {{ shop.pickup_address.zip }} {{ shop.pickup_address.city }}<br>
            </div>
            <div class="box-btns">

            </div>
          </div>
          <div class="box">
            <div class="box-header">
              <i class="icon icon-address"></i> {{ $t('cs.front.billing_address') }}
            </div>
            <div class="box-text" v-if="me && me.billing_address">
              <template v-if="me.type.toUpperCase() === 'C'">
                {{ me.billing_address.company_name }}<br/>
              </template>
              {{ me.billing_address.name }} {{ me.billing_address.surname }}<br/>
              {{ me.billing_address.address }}<br>
              {{ me.billing_address.zip }} {{ me.billing_address.city }}<br/>
              {{ me.billing_address.country }}<br>
            </div>
            <div class="box-btns">
              <router-link
                v-if="!isLoggedIn"
                :to="{ name: 'checkout.anon' }"
                class="btn btn-small"
              >
                {{ $t('cs.front.change') }}
              </router-link>
              <button v-else
                @click="addressSelectModal=true"
                class="btn btn-small"
              >
                {{ $t('cs.front.change') }}
              </button>
            </div>
          </div>
          <div class="box">
            <div class="box-header">
              <i class="icon icon-address"></i> {{ $t('cs.front.select_payment_method') }}
            </div>
            <div class="radio">
              <input id="a1" type="radio" name="a1" checked="">
              <label class="mb-2" for="a1"><b>{{ $t('cs.front.payment_method_cash') }}</b></label>
            </div>
            <p class="pickup">{{ $t('cs.front.payment_on_pickup_note') }}</p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box summary">
            <checkout-summary :cart="cart" />
            <div class="text-center">
              <!-- <button class="btn btn-green" type="button">Zur kasse</button> -->
              <!--a @click="createOrderWrapper()" class="btn btn-green" href="#">
                {{ $t('cs.front.create_order') }}
              </a-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <div v-if="addressSelectModal"
       class="modal show in" style="display: block;">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <button
          @click="addressSelectModal = false"
          type="button"
          class="close"
        >
          <i class="icon icon-close"></i>
        </button>
        <div class="modal-body px-lg-5">
          <div class="h3">{{ $t('cs.front.checkout_address_select_modal_title') }}</div>
          <addresses-list
            :isCompany="me.type.toUpperCase() === 'C'"
            deleteButtonDisabled="true"
            editButtonDisabled="true"
            addButtonDisabled="true"
            @changed="fetchMe()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CheckoutSteps from './components/CheckoutSteps.vue';
import CheckoutSummary from './components/CheckoutSummary.vue';
import AddressesList from '../profile/components/AddressesList.vue';

export default {
  components: {
    CheckoutSteps,
    CheckoutSummary,
    AddressesList,
  },
  data() {
    return {
      addressSelectModal: false,

    };
  },
  computed: {
    ...mapGetters({
      cart: 'cart/cart',
    }),
    ...mapGetters({
      shop: 'shop/selected',
    }),
    ...mapGetters({
      me: 'profile/me',
    }),
    ...mapGetters({
      apiToken: 'auth/apiToken',
    }),
    isLoggedIn() {
      return this.apiToken;
    },
  },
  watch: {

  },
  methods: {
    ...mapActions('profile', {
      fetchMe: 'fetchMe',
    }),
    ...mapActions('cart', {
      fetchCart: 'fetch',
      createOrder: 'createOrder',
    }),
    createOrderWrapper() {
      const data = {
        payment_method_id: 1,
        billing_address_id: this.me.billing_address.id,
      };

      this.createOrder(data);
    },
  },
  mounted() {
    if (!this.cart) {
      this.fetchCart();
    }

    if (!this.me) {
      this.fetchMe();
    }

    this.$store.commit('hideSideCart');
  },
};
</script>
