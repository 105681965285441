<template>
  <main class="content category-page">
    <div class="container">
      <router-link
        :to="{ name: 'home' }"
        class="back-link"
      >
        <i class="icon icon-arrow-left"></i> {{ $t('cs.front.back') }}
      </router-link>
      <div class="row">

        <p v-if="!productsList || productsList.length === 0">
          {{ $t('cs.front.no_products_found') }}
        </p>
        <main v-if="productsList && productsList.length > 0" class="col-md-12 col-lg-12">
          <h1 class="h2">{{ filter.q }}</h1>

          <div class="category-list">
            <router-link
              :to="productUrl(product)"
              v-for="product in productsList"
              :key="product.id"
              class="category-item"
            >
              <div class="img">
                <img :src="product.image" :alt="product.number">
              </div>
              <div class="desc">
                <div class="label" v-if="product.discount && product.discount !== '0%'">
                  {{ product.discount }}
                </div>
                <div class="name">{{ product.name }}</div>
                <div class="price">
                  <template v-if="product.price_prefix">
                    {{ $t('cs.front.at') }} &nbsp;
                  </template>
                  {{ $filters.priceFormat(product.price, product.currency) }}
                </div>
                <div
                  v-if="product.colors_count"
                  class="in-colors"
                >
                  <img src="../../../assets/img/colors.png" alt="Farben">

                  {{ $t('cs.front.in') }}
                  {{ product.colors_count }}

                  <template v-if="product.colors_count === 1">
                    {{ $t('cs.front.color') }}
                  </template>
                  <template v-else>
                    {{ $t('cs.front.colors') }}
                  </template>
                </div>
              </div>
            </router-link>
          </div>
          <div class="loader" style="display: none;">
            <img src="../../../assets/img/loader.gif" alt="alt">
          </div>
        </main>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { useRoute } from 'vue-router';

export default {
  components: {

  },
  data() {
    return {
      id: null,
      isMobile: false,
      searchArea: 'cs',
      filter: {
        q: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      products: 'product/list',
      eoProducts: 'eoProduct/list',
    }),
    productsList() {
      if (this.searchArea === 'cs') {
        return this.products.data;
      }

      return this.eoProducts.data;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.params.q': function (newQ) {
      this.filter.q = newQ;
      this.loadProducts();
    },
    // eslint-disable-next-line func-names
    '$route.params.searchArea': function (newSearchArea) {
      this.searchArea = newSearchArea;
      this.loadProducts();
    },
  },
  methods: {
    ...mapActions('product', {
      searchProducts: 'fetch',
    }),
    ...mapActions('eoProduct', {
      searchEOProducts: 'fetch',
    }),
    loadProducts(page = 1) {
      this.$store.commit('showLoader');

      let result = null;

      if (this.searchArea === 'cs') {
        result = this.searchProducts({
          page,
          q: this.filter.q,
        }).finally(() => this.$store.commit('hideLoader'));
      } else {
        result = this.searchEOProducts({
          page,
          q: this.filter.q,
        }).finally(() => this.$store.commit('hideLoader'));
      }

      return result;
    },
    setAdaptive() {
      // eslint-disable-next-line no-restricted-globals
      this.isMobile = (window.innerWidth < 992);
    },
    productUrl(product) {
      if (this.searchArea === 'cs') {
        return { name: 'product', params: { productId: product.id } };
      }

      return { name: 'eo-product', params: { eoProductId: product.id } };
    },
  },
  mounted() {
    const route = useRoute();
    if (route.params.q) {
      this.filter.q = route.params.q;
    }

    this.searchArea = route.params.searchArea;

    this.loadProducts();

    this.setAdaptive();

    window.addEventListener('resize', () => {
      this.setAdaptive();
    });
  },
};
</script>

<style>
  .vue-slider-process, .vue-slider-process:hover {
    background: #76b935;
  }
  .vue-slider:hover .vue-slider-dot-handle {
    background-color: green;
  }
</style>
