<template>
  <main class="content-product product-page">
    <div v-if="selectedProduct" class="container">
      <!--router-link
        v-if="productType === 'cs' && selectedProduct.category_id"
        :to="{ name: 'category', params: { categoryId: selectedProduct.category.slug }}"
        class="back-link"
      >
        <i class="icon icon-sm icon-arrow-left"></i>
        {{ $t('cs.front.back') }}
      </router-link>
      <router-link
        v-else-if="selectedProduct.category_id"
        :to="{ name: 'eo-products', params: { eoCategoryId: selectedProduct.category_id }}"
        class="back-link"
      >
        <i class="icon icon-sm icon-arrow-left"></i>
        {{ $t('cs.front.back') }}
      </router-link-->
      <a class="back-link" href="javascript:history.back();">
        <i class="icon icon-sm icon-arrow-left"></i> {{ $t('cs.front.back') }}
      </a>
      <div class="row mb-5">
        <div class="col-lg-6">
          <div class="product-gallery-thumbs owl-thumbs" data-slider-id="1">
            <template v-if="selectedProduct.images">
              <div
                v-for="image in selectedProduct.images"
                :key="image"
                class="owl-thumb-item"
              >
                <img
                  :src="image.url"
                  @click="selectedProduct.image = image.url"
                  alt=""
                >
              </div>
            </template>
          </div>
          <div class="product-gallery" data-slider-id="1">
            <div>
              <img :src="selectedProduct.image" alt="">
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="label" v-if="selectedProduct.discount && selectedProduct.discount !== '0%'">
            {{ selectedProduct.discount }}
          </div>
          <h1 class="h3">{{ selectedProduct.name }}</h1>
          <div class="row align-items-center">
            <div class="col-6">
              <div class="product-id">

              </div>
            </div>
            <div class="col-6">
              <div class="product-price">
                <template v-if="selectedProduct.display_rrp">
                  <s>
                    <small style="color: black;">
                      {{
                        $filters.priceFormat(
                          priceRRP,
                          selectedProduct.currency
                        )
                      }}
                    </small>
                  </s>

                  &nbsp;

                  <strong style="color: red;">
                    {{ $filters.priceFormat(price, selectedProduct.currency) }} *
                  </strong>
                </template>
                <template v-else>
                  {{ $filters.priceFormat(price, selectedProduct.currency) }} *
                </template>
              </div>
            </div>
          </div>
          <ul class="nav nav-tabs tabs">
            <li class="nav-item">
              <a href="#" class="nav-link tab active" data-toggle="tab" data-target="#tab1">
                {{ $t('cs.front.for_individuals') }}
              </a>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link tab " data-toggle="tab" data-target="#tab2">
                {{ $t('cs.front.for_teams') }}
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane active show" id="tab1">
              <p class="product-label">
                {{ $t('cs.front.product_number') }}:
                <strong>{{ selectedProduct.number }}</strong>
              </p>
              <p class="product-label">{{ $t('cs.front.color') }}:</p>
              <template
                v-if="modelProductsList && modelProductsList.data
                      && modelProductsList.data.length > 0"
              >
                <div
                  v-for="modelItem in modelProductsList.data"
                  :key="modelItem.id"
                  class="radio-img"
                >
                  <router-link
                    v-if="productType === 'cs'"
                    :to="productRoute(modelItem.id)"
                  >
                    <input
                      :id="'mi' + modelItem.id"
                      type="radio"
                      name="radio-img"
                      :checked="modelItem.id === selectedProduct.id"
                    />
                    <label :for="'mi' + modelItem.id">
                      <img :src="modelItem.image" :alt="modelItem.number" :title="modelItem.number">
                    </label>
                  </router-link>

                  <router-link
                    v-if="productType === 'eo'"
                    :to="productRoute(modelItem.id)"
                  >
                    <input
                      :id="'mi' + modelItem.id"
                      type="radio"
                      name="radio-img"
                      :checked="modelItem.id === selectedProduct.id"
                    />
                    <label :for="'mi' + modelItem.id">
                      <img :src="modelItem.image" :alt="modelItem.number" :title="modelItem.number">
                    </label>
                  </router-link>
                </div>
              </template>
              <template v-else>
                <div
                  class="radio-img"
                >
                  <router-link
                    :to="{ name: 'product', params: { productId: selectedProduct.id }}">
                    <input
                      :id="'mi' + selectedProduct.id"
                      type="radio"
                      name="radio-img"
                      checked="1"
                    />
                    <label :for="'mi' + this.id">
                      <img :src="selectedProduct.image" alt="">
                    </label>
                  </router-link>
                </div>
              </template>

              <div class="sizes-header">
                <p class="product-label">
                  {{ $t('cs.front.size') }}
                  <a
                    v-if="shop && shop.locale.sizes_table"
                    @click="showSizesTable = 1"
                    href="javascript:"
                    :title="$t('cs.front.open_sizes_table')"
                  >
                    <i class="icon icon-info" style="width: 19px;height: 19px;"></i>
                  </a>
                </p>

                <div
                  v-if="showSizesTable"
                  class="modal show in"
                  style="display: block;"
                >
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                      <button
                        @click="showSizesTable = false"
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        style="top: 0rem; right: 0px;"
                      >
                        <i class="icon icon-close"></i>
                      </button>
                      <div class="modal-body">
                        <div v-html="shop.locale.sizes_table" class="sizes-table"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <template v-if="selectedSizeInStockStatus && selectedSizeInStockStatus > 1">
                  <div
                    :class="`product-size-in-stock-status
                    in_stock_status_${selectedSizeInStockStatus}`"
                  >
                    <div v-if="selectedSizeInStockStatus === productSizeInStockTypes.FEW">
                      {{
                        $t(
                          'cs.front.product_size_in_stock_status_few',
                          {'size': selectedSizeItem.name}
                        )
                      }}
                    </div>
                    <div v-if="selectedSizeInStockStatus === productSizeInStockTypes.MANY_SOON">
                      {{
                        $t(
                          'cs.front.product_size_in_stock_status_many_soon',
                          {
                            'size': selectedSizeItem.name,
                            'next_delivery_date': selectedSizeItem.next_delivery_date
                          }
                        )
                      }}
                    </div>
                    <div v-if="selectedSizeInStockStatus === productSizeInStockTypes.NOT_AVAILABLE">
                      {{
                        $t(
                          'cs.front.product_size_in_stock_status_not_available_now',
                          {'size': selectedSizeItem.name}
                        )
                      }}
                    </div>
                  </div>
                </template>
              </div>

              <form>
                <div class="sizes">
                  <div
                    v-for="size in selectedProduct.sizes"
                    :key="size.id"
                    class="size"
                    :title="(size.in_stock_status === productSizeInStockTypes.MANY_SOON
                      && size.next_delivery_date)
                      ? $t(
                          'cs.front.product_size_in_stock_status_many_soon',
                          {
                            'size': size.name,
                            'next_delivery_date': size.next_delivery_date
                          }
                        ) : ''"
                  >
                    <input
                      :id="'ps' + size.id"
                      v-model="selectedSize"
                      :value="size.id"
                      type="radio"
                      name="selectedSize"
                      :disabled="
                      size.in_stock_status === productSizeInStockTypes.NOT_AVAILABLE
                      || size.in_stock_status === productSizeInStockTypes.MANY_SOON
                    "
                    />
                    <label
                      :for="'ps' + size.id"
                      :class='`size_label size_label_in_stock_status_${size.in_stock_status}`'
                    >
                      {{ size.name }}
                    </label>
                  </div>

                  <div
                    v-if="selectedProduct.sizes_warning_message"
                    class="alert alert-warning"
                    style="font-weight: normal;"
                  >
                    {{ selectedProduct.sizes_warning_message }}
                  </div>
                </div>

                <template
                  v-if="selectedSize && selectedProduct.options
                    && selectedProduct.options.length > 0">
                  <div style="clear: both"></div>
                  <a
                    @click="openSelectOptionsModal()"
                    href="javascript:;"
                    class="btn btn-default btn-small mb-2"
                  >
                    ⚙ {{ $t('cs.front.productoptions_link_button') }}
                  </a>
                  <div
                    v-for="imageType in shop.active_image_types"
                    :key="imageType.id"
                  >
                    <div
                      v-if="selectedOptions.filter(o => o.product_image_type_id === imageType.id)
                          .length > 0"
                    >
                      <a
                        @click="openSelectOptionsModal()"
                        href="javascript:;"
                      >
                        <strong>
                          {{ $t('cs.labels.product_customization') }}
                          {{ $t('cs.labels.product_image_type_' + imageType.name) }}:
                        </strong>

                        <span
                          v-for="(option, idx) in
                        selectedOptions.filter(o => o.product_image_type_id === imageType.id)"
                          :key="option.id"
                        >
                        {{ option.name }}<span v-if="idx < selectedOptions.length-1">, </span>
                      </span>
                        ({{ $t('cs.front.productoptions_selected_price_total') }}
                        {{ $filters.priceFormat(selectedOptionsTotalOfImageType(imageType.id),
                          selectedProduct.currency) }})

                      </a>
                    </div>
                  </div>
                </template>
              </form>

              <p
                class="product-label pt-2"
              >{{ $t('cs.front.order_quantity') }}:</p>

              <div class="qty-input qty-input-inline">
                <button
                  class="qty-minus"
                  type="button"
                  @click="selectedSizeQuantity--"
                >
                  <i class="icon icon-minus"></i>
                </button>
                <input
                  type="number"
                  v-model="selectedSizeQuantity"
                  @change="correctQuantity"
                >

                <button
                  v-if="selectedSizeQuantity < selectedSizeItem?.max_quantity"
                  class="qty-plus"
                  type="button"
                  @click="selectedSizeQuantity++"
                >
                  <i class="icon icon-plus"></i>
                </button>
              </div>

              <div
                v-if="!allCustomTextsSet"
                class="alert alert-warning"
              >
                {{ $t('cs.front.warnings.options_text_required') }}
              </div>

              <button
                @click="addSizesToCart()"
                class="btn btn-green"
                type="button"
                :disabled="!selectedSize
                  || selectedSizeInStockStatus === productSizeInStockTypes.NOT_AVAILABLE
                  || selectedSizeInStockStatus === productSizeInStockTypes.MANY_SOON
                  || !allCustomTextsSet
                "
              >
                {{ $t('cs.front.add_to_cart') }}
              </button>
            </div>
            <div class="tab-pane fade" id="tab2">
              <table v-if="modelProductsList.data" class="for-team-table">
                <tbody>
                  <tr>
                    <th>{{ $t('cs.front.color') }}</th>
                    <th>{{ $t('cs.front.size') }}</th>
                    <th>{{ $t('cs.front.price') }}</th>
                    <th>{{ $t('cs.front.add_to_cart') }}</th>
                    <th>{{ $t('cs.front.add_options') }}</th>
                  </tr>
                  <template
                    v-for="modelProduct in modelProductsList.data"
                  >
                    <template
                        v-for="size in modelProduct.sizes"
                        :key="size.id"
                      >
                      <tr>
                        <td>
                          <div
                            class="color"
                            :style="'border: 1px solid #ccc; background: ' + modelProduct.color_hex"
                          >
                            <br />
                            <small>#{{ modelProduct.number }}</small>
                          </div>
                        </td>
                        <td>
                          <div
                            :class='`team_size_label
                                      team_size_label_in_stock_status_${size.in_stock_status}`'
                          >
                            <span>{{ size.name }}</span>
                          </div>
                        </td>
                        <td>{{ $filters.priceFormat(size.price, selectedProduct.currency) }}</td>
                        <td
                          :title="(size.in_stock_status === productSizeInStockTypes.MANY_SOON
                            && size.next_delivery_date)
                                ? $t(
                                    'cs.front.product_size_in_stock_status_many_soon',
                                    {
                                      'size': size.name,
                                      'next_delivery_date': size.next_delivery_date
                                    }
                                  ) : ''"
                        >
                          <div class="qty-input qty-input-inline">
                            <button
                              class="qty-minus"
                              type="button"
                              @click="size.to_cart_default_count =
                                  (size.to_cart_default_count <= 0)
                                  ? 0
                                  : size.to_cart_default_count - 1"
                            >
                              <i class="icon icon-minus"></i>
                            </button>
                            <input
                              type="number"
                              v-model="size.to_cart_default_count"
                            >
                            <button
                              class="qty-plus"
                              type="button"
                              @click="size.to_cart_default_count++"
                              v-if="
                                size.to_cart_default_count < size?.max_quantity
                                && size.in_stock_status !== productSizeInStockTypes.NOT_AVAILABLE
                                && size.in_stock_status !== productSizeInStockTypes.MANY_SOON
                              "
                            >
                              <i class="icon icon-plus"></i>
                            </button>
                          </div>
                        </td>
                        <td>
                          <div
                            v-if="size.to_cart_default_count>0"
                            v-for="imageType in shop.active_image_types"
                            :key="imageType.id"
                          >
                            <template
                              v-if="size.options
                                .filter(o => o.product_image_type_id === imageType.id).length"
                            >
                              <b>
                                {{ $t('cs.labels.available') }}
                                {{ $t('cs.labels.product_image_type_' + imageType.name) }}
                              </b><br />
                            </template>

                            <table
                              class="options-table">
                              <tr
                                v-for="option in size.options
                                  .filter(o => o.product_image_type_id === imageType.id)"
                                :key="option.id"
                                :class="
                                 (option.active?'active ':'')+(option.required?'disabled':'')
                               "
                              >
                                <td>
                                  <label
                                    style="padding: 1px; display: inline; line-height: inherit;"
                                  >
                                    <input
                                      type="checkbox"
                                      v-model="option.active"
                                      :disabled="option.required"
                                    >
                                    {{ option.name }}
                                  </label>

                                  <br />

                                  <div
                                    v-if="option.active && option.type === optionTypes.TYPE_FREE_TEXT"
                                    style="position:relative"
                                  >
                                    <input
                                      v-if="size.to_cart_default_count === 1"
                                      v-model="option.custom_texts[0]"
                                      type="text"
                                      maxlength="80"
                                      style="height: auto; width: 110px;"
                                    />
                                    <div v-else>
                                      <input
                                        @click="customTextModalFor = option;
                                          countToCart = size.to_cart_default_count;
                                          colorToCart = modelProduct.color;
                                          sizeToCart = size.name;"
                                        type="text"
                                        style="height: auto; width: 110px;"
                                        :value="option.custom_texts[0] ? option.custom_texts[0] + '...' : ''"
                                        disabled
                                      />
                                      <div
                                        @click="customTextModalFor = option;
                                          countToCart = size.to_cart_default_count;
                                          colorToCart = modelProduct.color;
                                          sizeToCart = size.name;"
                                        style="
                                          position: absolute;
                                          top: 2px;
                                          right: 17px;
                                          background-color: green;
                                          width: 21px;
                                          height: 17px;
                                          text-align: center;
                                          color: white;
                                          line-height: 12px;
                                          cursor: pointer;
                                        "
                                      >...</div>
                                    </div>
                                  </div>
                                </td>
                                <td class="price">
                                  {{ $filters.priceFormat(
                                  option.price, selectedProduct.currency) }}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>

              <div v-if="selectedProduct.sizes_warning_message" class="alert alert-warning">
                {{ selectedProduct.sizes_warning_message }}
              </div>

              <div v-if="teamOrderContainsEmptyFreeText" class="alert alert-warning">
                {{ $t('cs.front.warnings.options_text_required') }}
              </div>
              <div
                v-if="teamsTotal !== 0 && !teamOrderContainsEmptyFreeText"
                class="for-team-total"
              >
                <div>{{ $t('cs.front.total_price') }}:
                  <span class="green">{{ teamsTotalPrice }}</span>
                </div>
                <div v-if="teamsTotalOptionsPrice > 0">{{ $t('cs.front.total_options_price') }}:
                  <span class="green">
                    {{ $filters.priceFormat(
                      teamsTotalOptionsPrice, selectedProduct.currency) }}
                  </span>
                </div>
                <div>
                  {{ $t('cs.front.total_quantity') }}:
                  <span class="green">{{ teamsTotal }}</span>
                </div>

                <button
                  @click="addGroupsToCart()"
                  class="btn btn-green mr-md-3 mb-3 mb-md-0"
                  type="button"
                >
                  {{ $t('cs.front.add_to_cart') }}
                </button>
                <button
                  @click="resetTeamsCart()"
                  class="btn"
                  type="button"
                >
                  {{ $t('cs.front.cancel') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="mb-4">
        <div class="row">
          <div class="col-lg-6">
            <p class="product-subtitle">{{ $t('cs.front.material') }}</p>
            <div class="mb-4" v-html="selectedProduct.material"></div>

            <p class="product-subtitle">{{ $t('cs.front.features') }}</p>

            <ul class="bullet-list">
              <li
                v-for="feature in selectedProduct.features"
                :key="feature"
              >
                <span v-html="feature"></span>
              </li>
            </ul>
          </div>
          <div class="col-lg-6">
            <p class="product-subtitle">{{ $t('cs.front.description') }}</p>
            <p v-html="selectedProduct.description"></p>
          </div>
        </div>
      </section>

      <section v-if="moreProducts && moreProducts.data">
        <products-slider
          :products="moreProducts.data"
          :header="$t('cs.front.with_this_product_are_often_taken')"
        />
      </section>
    </div>
    <sharing-box
      v-if="selectedProduct && selectedProduct.name && shop"
      :title="`${selectedProduct.name} | ${shop.name}`"
    />

    <div
      v-if="customTextModalFor"
      class="modal show in"
      style="
        display: block;
        z-index: 1100;
      "
    >
      <div class="modal-dialog modal-lg">
        <div
          class="modal-content"
          style="
            min-height: 60vh;
            border: 2px solid #ccc;
          "
        >
          <button
            @click="customTextModalFor = false"
            type="button"
            class="close"
          >
            <i class="icon icon-close"></i>
          </button>
          <div class="modal-body px-lg-5">
            <div class="row">
              <div class="col-md-6">
                <label>
                  <input
                    v-model="customTextModalFor.custom_text_input_type"
                    type="radio"
                    name="batchCustomtextsType"
                    :value="2"
                  />
                  <strong>
                    &nbsp;
                    {{
                      $t(
                        'cs.front.different_text_for_all_positions',
                        {
                          amount_of_products: countToCart,
                          size: sizeToCart,
                          color: colorToCart
                        }
                      )
                    }}
                  </strong>
                </label>

                <hr />
                <div
                  v-for="index in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]"
                  :key="index"
                >
                  <input
                    v-if="(customTextModalFor.custom_text_input_type === 2 || index === 0) && countToCart >= index+1"
                    v-model="customTextModalFor.custom_texts[index]"
                    type="text"
                    :placeholder="customTextModalFor.custom_text_input_type === 2
                      ? index+1 + '.' : ''"
                    style="margin-bottom: 4px;"
                    maxlength="80"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <label>
                  <input
                    v-model="customTextModalFor.custom_text_input_type"
                    type="radio"
                    name="batchCustomtextsType"
                    :value="1"
                  />
                  <strong>&nbsp;
                      {{
                        $t(
                          'cs.front.only_text_for_all_positions',
                          {
                            amount_of_products: countToCart,
                            size: sizeToCart,
                            color: colorToCart
                          }
                        )
                      }}
                    </strong>
                </label>

                <hr />
                <div
                  v-for="index in [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]"
                  :key="index"
                >
                  <input
                    v-if="customTextModalFor.custom_text_input_type === 2 && countToCart >= index+1"
                    v-model="customTextModalFor.custom_texts[index]"
                    type="text"
                    :placeholder="index+1 + '.'"
                    maxlength="80"
                  />
                </div>
              </div>
            </div>

            <br />
            <br />

            <button
              @click="customTextModalFor = null"
              class="btn btn-small"
              type="button"
            >
              {{ $t('cs.front.save') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="product && selectOptionModal && selectedSizeItem"
         class="modal show in" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <button
            @click="selectOptionModal = false"
            :disabled="!allCustomTextsSet"
            type="button"
            class="close"
          >
            <i class="icon icon-close"></i>
          </button>
          <div class="modal-body px-lg-5">
            <h3>{{ product.name }} {{ selectedSizeItem.name }}</h3>
            <div
              v-for="imageType in shop.active_image_types"
              :key="imageType.id"
            >
              <template
                v-if="allOptions.filter(o => o.product_image_type_id === imageType.id).length"
              >
                <h4>
                  {{ $t('cs.labels.available') }}
                  {{ $t('cs.labels.product_image_type_' + imageType.name) }}
                </h4>
                <div
                  v-for="option in allOptions.filter(o => o.product_image_type_id === imageType.id)"
                  :key="option.id"
                  class="row"
                >
                  <div class="col-1">
                    <input
                      v-model="option.active"
                      :disabled="option.required"
                      type="checkbox"
                      :id="'option' + option.id"
                    />
                  </div>
                  <div class="col-3">
                    <label :for="'option' + option.id">{{ option.name }}</label>
                  </div>
                  <div class="col-4">
                    <template v-if="option.active && option.type === optionTypes.TYPE_FREE_TEXT">
                      <input
                        v-if="selectedSizeQuantity === 1"
                        v-model="option.custom_texts[0]"
                        type="text"
                        style="width: 100%; height: auto;"
                        maxlength="80"
                      />
                      <div v-else>
                        <input
                          @click="customTextModalFor = option;
                            countToCart = selectedSizeQuantity;
                            colorToCart = selectedProduct.color;
                            sizeToCart = selectedSizeItem.name;
                          "
                          type="text"
                          style="cursor: pointer; height: auto;"
                          :value="option.custom_texts[0] ? option.custom_texts[0] + '...' : ''"
                          disabled
                        />
                        <div
                          @click="customTextModalFor = option;
                            countToCart = selectedSizeQuantity;
                            colorToCart = selectedProduct.color;
                            sizeToCart = selectedSizeItem.name;
                          "
                          style="
                            position: absolute;
                            top: 0px;
                            right: 1px;
                            background-color: green;
                            width: 44px;
                            height: 26px;
                            text-align: center;
                            color: white;
                            line-height: 19px;
                            cursor: pointer;
                          "
                        >...</div>
                      </div>
                    </template>
                  </div>
                  <div class="col-2 text-right">
                    <!-- {{ $filters.priceFormat(option.price, selectedProduct.currency) }} -->

                    <s v-if="option.old_price" style="color: grey;">
                      <small>
                        {{ $filters.priceFormat(option.old_price, selectedProduct.currency) }}
                      </small>
                    </s>
                    <span :style="{color: option.old_price ? 'red' : black}">
                      &nbsp;
                    {{ $filters.priceFormat(option.price, selectedProduct.currency) }}
                  </span>
                  </div>
                  <div class="col-2 text-right">
                    <template v-if="option.active">
                      {{ $filters.priceFormat(
                      option.price * selectedSizeQuantity,
                      selectedProduct.currency) }}
                    </template>
                  </div>
                </div>
              </template>
            </div>

            <div class="row">
              <div class="col-10 text-right">
                {{ $t('cs.front.productoptions_modal_total') }}
              </div>
              <div class="col-2 text-right">
                {{ $filters.priceFormat(
                allOptions.reduce((a, b) => a + 1 * b.price * b.active, 0)
                * selectedSizeQuantity,
                selectedProduct.currency) }}
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div
                  v-if="!allCustomTextsSet"
                  class="alert alert-warning"
                >
                  {{ $t('cs.front.warnings.options_text_required') }}
                </div>
                <button
                  @click="saveProductOptions()"
                  :disabled="!allCustomTextsSet"
                  class="btn btn-small"
                  type="button"
                >
                  {{ $t('cs.front.save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { mapGetters, mapActions } from 'vuex';
import { useRoute } from 'vue-router';
import SharingBox from '@/views/common/SharingBox.vue';
import ProductsSlider from '../../common/content_blocks/ProductsSlider.vue';
import * as contentTypes from '../../../consts/content_blocks';
import * as optionTypes from '../../../consts/product_option';
import * as productSizeInStockTypes from '../../../consts/product_size_in_stock';

export default {
  components: {
    ProductsSlider,
    SharingBox,
  },
  data() {
    return {
      id: 1,
      price: null,
      priceRRP: null,
      selectedSize: null,
      selectedSizeQuantity: 1,
      selectOptionModal: false,
      contentTypes,
      productSizeInStockTypes,
      optionTypes,
      productType: 'cs',
      selectedOptions: [],
      allOptions: [],
      showSizesTable: false,
      customTextModalFor: false,
      countToCart: null,
      colorToCart: null,
      sizeToCart: null,
    };
  },
  computed: {
    ...mapGetters({
      blocks: 'contentBlock/list',
      product: 'product/selected',
      eoProduct: 'eoProduct/selected',
      shop: 'shop/selected',
      moreProducts: 'product/list',
      modelProducts: 'product/modelList',
      modelEOProducts: 'eoProduct/modelList',
      categories: 'category/list',
    }),
    allCustomTextsSet() {
      return !this.allOptions
        .find(
          (o) => o.active && o.type === optionTypes.TYPE_FREE_TEXT
            && (
              (o.custom_text_input_type === 1 && !o.custom_texts[0])
                || (o.custom_text_input_type !== 1 && o.custom_texts.filter((ct) => ct).length < this.selectedSizeQuantity)
            ),
        );
    },
    customTextOptionsCount() {
      return this.allOptions.filter((o) => o.active && o.type === optionTypes.TYPE_FREE_TEXT).length;
    },
    teamOrderContainsEmptyFreeText() {
      if (!this.modelProductsList || !this.modelProductsList.data) {
        return false;
      }

      return this.modelProductsList.data
        .find((p) => p.sizes.filter((s) => s.to_cart_default_count >= 1)
          .find((s) => s.options
            .find((so) => so.active
                && so.type === optionTypes.TYPE_FREE_TEXT
                && (
                  (so.custom_text_input_type === 1 && !so.custom_texts[0])
                  || (so.custom_text_input_type === 2 && so.custom_texts.filter((ct) => ct).length < s.to_cart_default_count)
                ))));
    },
    selectedSizeItem() {
      if (!this.selectedProduct || !this.selectedProduct.sizes) {
        return {};
      }
      return this.selectedProduct.sizes.find((el) => el.id === this.selectedSize);
    },
    selectedSizeInStockStatus() {
      const selectedSize = this.selectedSizeItem;

      if (selectedSize) {
        return selectedSize.in_stock_status;
      }

      return false;
    },
    teamsTotalPrice() {
      let price = 0;

      if (this.modelProductsList && this.modelProductsList.data) {
        // eslint-disable-next-line max-len
        price = this.modelProductsList.data.reduce((productsSum, product) => {
          // eslint-disable-next-line max-len
          const sizesSum = product.sizes.reduce((sum, size) => sum + (size.to_cart_default_count * size.price), 0);
          return productsSum + sizesSum;
        }, 0).toFixed(2);
      }

      return this.$filters.priceFormat(price, this.shop.currency);
    },
    teamsTotalOptionsPrice() {
      let price = 0;

      if (this.modelProductsList && this.modelProductsList.data) {
        // eslint-disable-next-line max-len
        price = this.modelProductsList.data.reduce((productsSum, product) => {
          // eslint-disable-next-line max-len
          const sizesSum = product.sizes.reduce((sum, size) => (size.options ? sum + (size.to_cart_default_count * size.options.reduce((sm, option) => (sm + (option.active ? 1 * option.price : 0)), 0)) : sum), 0);
          return productsSum + sizesSum;
        }, 0).toFixed(2);
      }

      return price;
    },
    teamsTotal() {
      let total = 0;

      if (this.modelProductsList && this.modelProductsList.data) {
        // eslint-disable-next-line max-len
        total = this.modelProductsList.data.reduce((productsSum, product) => {
          const sizesSum = product.sizes.reduce((sum, size) => sum + size.to_cart_default_count, 0);
          return productsSum + sizesSum;
        }, 0);
      }

      return total;
    },
    selectedProduct() {
      if (this.productType === 'cs') {
        return this.product;
      }

      return this.eoProduct;
    },
    modelProductsList() {
      if (this.productType === 'cs') {
        return this.modelProducts;
      }

      return this.modelEOProducts;
    },
    selectedOptionsTotal() {
      return this.selectedSizeQuantity * this.selectedOptions.reduce((a, b) => a + 1 * b.price, 0);
    },
    fileUpladRequired() {
      return this.allOptions
        && this.allOptions.filter(
          (a) => a.active && a.type === optionTypes.TYPE_CLUB_LOGO,
        ).length > 0;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.params.productId': function (newId) {
      window.scrollTo(0, 0);

      this.$store.commit('showLoader');
      this.productType = 'cs';
      this.id = parseInt(newId, 10);
      this.selectedSize = false;

      this.selectProduct(this.id).then(() => {
        // from the same category so far
        if (this.selectedProduct) {
          this.price = this.selectedProduct.price;
          this.priceRRP = this.selectedProduct.price_rrp;
        }

        if (this.selectedProduct && this.selectedProduct.category_id) {
          this.fetchMoreProducts({ category_id: this.selectedProduct.category_id });
        }
      }).finally(() => this.$store.commit('hideLoader'));
    },
    // eslint-disable-next-line func-names
    '$route.params.eoProductId': function (newId) {
      if (!newId) {
        return null;
      }
      window.scrollTo(0, 0);

      this.$store.commit('showLoader');
      this.productType = 'eo';
      this.id = parseInt(newId, 10);
      this.selectedSize = false;

      return this.selectEOProduct(this.id).then(() => {
        this.price = this.selectedProduct.price;
      }).finally(() => this.$store.commit('hideLoader'));
    },
    selectedSizeQuantity(newVal) {
      if (newVal <= 0) {
        this.selectedSizeQuantity = 1;
      }
    },
    selectedSize(newVal) {
      if (newVal) {
        const size = this.selectedProduct.sizes.find((el) => el.id === parseInt(newVal, 10));

        if (size) {
          this.price = size.price;
          this.priceRRP = size.price_rrp;
        }
      }
    },
    product() {
      this.initSelectedOptions();
    },
  },
  methods: {
    ...mapActions('contentBlock', {
      fetchBlocks: 'fetch',
    }),
    ...mapActions('product', {
      selectProduct: 'select',
      fetchMoreProducts: 'fetch',
    }),
    ...mapActions('eoProduct', {
      selectEOProduct: 'select',
    }),
    ...mapActions('category', {
      fetchCategories: 'fetch',
    }),
    ...mapActions('cart', {
      addCartPositions: 'addPositions',
    }),
    resetTeamsCart() {
      this.selectProductWrapper();
    },
    addGroupsToCart() {
      const toCartList = [];

      // eslint-disable-next-line array-callback-return
      this.modelProductsList.data.forEach((product) => {
        product.sizes.filter((size) => size.to_cart_default_count > 0).forEach((size) => {
          for (let index = 0; index <= size.to_cart_default_count - 1; index += 1) {
            toCartList.push({
              type: (this.productType === 'cs') ? 'CS' : 'EO',
              size_id: size.id,
              quantity: 1,
              options: size.options
                ? size.options.filter((a) => (a.active || a.required)).map((so) => {
                  if (so.type === optionTypes.TYPE_FREE_TEXT) {
                    const customText = (so.custom_text_input_type === 1) ? so.custom_texts[0] : so.custom_texts[index];

                    return { ...so, custom_text: customText };
                  }

                  return so;
                })
                : [],
            });
          }
        });
      });

      this.$store.commit('showLoader');

      this.addCartPositions(toCartList).finally(() => {
        this.selectProductWrapper();
        this.$store.commit('hideLoader');
      });

      this.$store.commit('showSideCart');
    },
    addSizesToCart() {
      if (this.selectedSize) {
        const sizesData = [];

        for (let index = 0; index <= this.selectedSizeQuantity - 1; index += 1) {
          sizesData.push({
            size_id: parseInt(this.selectedSize, 10),
            quantity: 1,
            type: (this.productType === 'cs') ? 'CS' : 'EO',
            options: this.selectedOptions.map((so) => {
              if (so.type === optionTypes.TYPE_FREE_TEXT) {
                const customText = (so.custom_text_input_type === 1) ? so.custom_texts[0] : so.custom_texts[index];

                return { ...so, custom_text: customText };
              }

              return so;
            }),
          });
        }

        this.$store.commit('showLoader');
        this.addCartPositions(sizesData)
          .then(() => {
            this.selectedSize = false;
            this.selectedSizeQuantity = 1;
            this.initSelectedOptions();
          })
          .finally(() => this.$store.commit('hideLoader'));
      }

      this.$store.commit('showSideCart');
    },
    productRoute(id) {
      if (this.productType === 'cs') {
        return {
          name: 'product',
          params: { productId: id },
        };
      }

      return {
        name: 'eo-product',
        params: { eoProductId: id },
      };
    },
    selectProductWrapper() {
      if (this.productType === 'cs') {
        this.$store.commit('showLoader');
        this.selectProduct(this.id).then(() => {
          // from the same category so far
          this.fetchMoreProducts({ category_id: this.selectedProduct.category_id });
          this.price = this.selectedProduct.price;
          this.priceRRP = this.selectedProduct.price_rrp;

          if (this.selectedProduct.sizes.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            this.selectedSize = this.selectedProduct.sizes[0].id;
          }

          document.title = `${this.shop.club.name} / ${this.shop.name} - ${this.selectedProduct.name} ${this.selectedProduct.number}`;
        }).finally(() => this.$store.commit('hideLoader'));
      } else if (this.productType === 'eo') {
        this.$store.commit('showLoader');
        this.selectEOProduct(this.id).then(() => {
          this.price = this.selectedProduct.price;
          this.priceRRP = this.selectedProduct.price_rrp;

          if (this.selectedProduct.sizes.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            this.selectedSize = this.selectedProduct.sizes[0].id;
          }

          document.title = `${this.shop.club.name} / ${this.shop.name} - ${this.selectedProduct.name} ${this.selectedProduct.number}`;
        }).finally(() => this.$store.commit('hideLoader'));
      }
    },
    openSelectOptionsModal() {
      if (this.selectedProduct && this.selectedProduct.options) {
        this.selectOptionModal = true;
      }
    },
    saveProductOptions() {
      this.selectedOptions = this.allOptions
        .filter((a) => a.active || a.required);

      this.selectOptionModal = false;
    },
    initSelectedOptions() {
      if (this.selectedProduct && this.selectedProduct.options) {
        this.allOptions = this.selectedProduct.options.map(
          (a) => ({ ...a, ...{ active: this.selectedOptions.includes(a.id) || a.required } }),
        );

        this.selectedOptions = this.allOptions
          .filter((a) => a.active || a.required);
      }
    },
    correctQuantity() {
      if (this.selectedSizeQuantity > this.selectedSizeItem?.max_quantity) {
        this.selectedSizeQuantity = parseFloat(this.selectedSizeItem?.max_quantity, 10);
      }
    },
    selectedOptionsTotalOfImageType(imageType) {
      return this.selectedSizeQuantity * this.selectedOptions
        .filter((o) => o.product_image_type_id === imageType).reduce((a, b) => a + 1 * b.price, 0);
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    const route = useRoute();

    if (route.params.productId) {
      this.productType = 'cs';
      this.id = parseInt(route.params.productId, 10);
    } else if (route.params.eoProductId) {
      this.productType = 'eo';
      this.id = parseInt(route.params.eoProductId, 10);
    }

    this.selectProductWrapper();
    this.fetchCategories();
  },
  beforeUnmount() {
    document.title = `${this.shop.club.name}/ ${this.shop.name}`;
  },
};
</script>

<style>
.sizes-header {
  position: relative;
}
.product-size-in-stock-status > div {
  position: absolute;
  left: 90px;
  top: -4px;
  font-size: 14px;
}

.in_stock_status_2 {
  color: orange;
}

.in_stock_status_3 {
  color: red;
}

.in_stock_status_4 {
  color: red;
}

.size_label {
  height: 32px !important;
}

.size_label_in_stock_status_1 {
  border-bottom: 7px solid #059800;
}

.size_label_in_stock_status_2 {
  border-bottom: 7px solid orange;
}

.size_label_in_stock_status_3 {
  border-bottom: 7px solid red;
}

.size_label_in_stock_status_3 span {
  opacity: 0.3;
}

.size_label_in_stock_status_4 {
  opacity: 0.3;
}

.team_size_label {
  height: 42px !important;
  width: 37px !important;
  padding-top: 5px;
}

.team_size_label_in_stock_status_1 {
  border-right: 7px solid #059800;
}

.team_size_label_in_stock_status_2 {
  border-right: 7px solid orange;
}

.team_size_label_in_stock_status_3 {
  border-right: 7px solid #ff0000;
}

.team_size_label_in_stock_status_3 span {
  opacity: 0.3;
}

.team_size_label_in_stock_status_4 {
  opacity: 0.3;
}

.options-table tr {
  border: none!important;
}

.options-table tr.disabled {
  cursor: initial;
}

.options-table tr:hover td {
  color: #76B935!important;
}

.options-table td {
  padding: 0 4px 0 0!important;
  font-weight: normal!important;
  font-size: 14px!important;
  color: #6e6e6e!important;
  line-height: 1.2;
}

.options-table tr.active td {
  color: #141414!important;
}

.options-table td.price {
  text-align: right;
}

.options-table td input {
  width: 10px;
  margin-right: 4px;
  position: relative;
  top: 2px;
}

.sizes-table {
  max-height: 90vh;
  overflow-y: scroll;
}

.sizes-table td, .sizes-table th {
  padding: 0.25rem !important;
  text-align: center !important;
}

@media(max-width: 1200px) {
  .product-gallery-thumbs {
    display: block !important;
  }
  .product-gallery-thumbs > div {
    width: 75px;
    float: left;
    margin: 5px;
  }
}
</style>
