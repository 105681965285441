import axios from 'axios';

export default {
  create(context, data) {
    if (data.fileToUpload) {
      const formData = new FormData();
      Object.keys(data).forEach((a) => a === 'fileToUpload'
        || (data[a] && formData.append(a, JSON.stringify(data[a]))));
      formData.append('file', data.fileToUpload);
      return axios.post(
        `${context.rootState.apiHost}/club-shop/app/cart/orders/`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      ).then((response) => response);
    }
    return axios.post(
      `${context.rootState.apiHost}/club-shop/app/cart/orders/`,
      data,
    ).then((response) => response);
  },
};
