<template>
  <ul class="footer-menu">
    <li v-if="shop">
      <img
        v-if="shop.reseller_logo && shop.reseller_logo.id"
        :src="`${staticHost}/${shop.reseller_logo.url}`"
        :alt="shop.name"
        style="
          display: block;
          max-width:125px;
          max-height:125px;
          width: auto;
          height: auto;
       "
      />
      <br />
      {{ shop.reseller_name }} {{ shop.reseller_surname }} <br />

      {{ shop.pickup_address.address }} <br />
      {{ shop.pickup_address.zip }} {{ shop.pickup_address.city }}<br />
      {{ $t('cs.front.phone') }}: {{ shop.contact_phone }}<br />
      <a :href="`mailto:${shop.contact_email}`">{{ shop.contact_email }}</a><br />
    </li>
    <li
      v-for="block in blocks"
      :key="block.id"
    >
      <div class="footer-title">{{ block.name }}</div>
      <ul class="footer-submenu">
        <li
          v-for="link in block.links"
          :key="link.id"
        >
          <a :href="link.url">
            <i v-if="link.icon" :class="`fa-all fa-${link.icon}`"></i>
            &nbsp;
            {{ link.name }}
          </a>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {

  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters({
      blocks: 'footerBlock/list',
      shop: 'shop/selected',
      staticHost: 'staticHost',
    }),
  },
  watch: {

  },
  methods: {
    ...mapActions('footerBlock', {
      fetchBlocks: 'fetch',
    }),
  },
  mounted() {
    this.fetchBlocks();
  },
};
</script>
