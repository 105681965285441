<template>
  <div class="profile-sidebar">
    <a class="back-link" href="javascript:history.back();">
      <i class="icon icon-arrow-left"></i> {{ $t('cs.front.back') }}
    </a>
    <p class="profile-name">{{ userFullName }}</p>
    <p class="profile-email">{{ userEmail }}</p>
    <ul class="profile-menu">
      <li class="profile-menu-toggler">
        <i class="icon icon-arrow-right"></i> {{ $t('cs.front.profile_menu') }}
      </li>
      <li class="profile-menu-item">
        <router-link
          :to="{ name: 'profile.index' }"
          class="menu-link"
        >
          <i class="icon icon-overview"></i> {{ $t('cs.front.overview') }}
        </router-link>
      </li>
      <li class="profile-menu-item">
        <router-link
          :to="{ name: 'profile.orders' }"
          class="menu-link"
        >
          <i class="icon icon-orders"></i> {{ $t('cs.front.orders') }}
        </router-link>
      </li>
      <li class="profile-menu-item">
        <router-link
          :to="{ name: 'profile.addresses' }"
          class="menu-link"
        >
          <i class="icon icon-address"></i> {{ $t('cs.front.addresses') }}
        </router-link>
      </li>
      <li class="profile-menu-item">
        <router-link
          :to="{ name: 'profile.settings' }"
          class="menu-link"
        >
          <i class="icon icon-profile-edit"></i> {{ $t('cs.front.personal_data') }}
        </router-link>
      </li>
    </ul>
    <!-- <button class="btn btn-small" type="button">ABMELDEN</button> -->
    <a class="btn btn-small" @click="logout()" href="javascript:">
      {{ $t('cs.front.log_out') }}
    </a>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  components: {

  },
  props: {
    userFullName: String,
    userEmail: String,
  },
  data() {
    return {

    };
  },
  computed: {

  },
  watch: {

  },
  methods: {
    ...mapActions('profile', {
      resetMe: 'resetMe',
    }),
    logout() {
      this.$store.commit('auth/setApiToken', '');
      localStorage.removeItem('api-token');

      this.resetMe();

      this.$toast.success(this.$t('cs.front.bye'));

      this.$router.push({ name: 'home' });

      return true;
    },
  },
  mounted() {

  },
};
</script>
