<template>
  <ul v-if="shop">
    <li v-if="shop.active_page_types.includes(pageTypes.TYPE_AGREEMENT)">
      <router-link
        :to="{ name: 'info', params: { type: pageTypes.TYPE_AGREEMENT }}"
      >
        {{ shop.active_page_names[pageTypes.TYPE_AGREEMENT] }}
      </router-link>
    </li>

    <li v-if="shop.active_page_types.includes(pageTypes.TYPE_PRIVACY)">
      <router-link
        :to="{ name: 'info', params: { type: pageTypes.TYPE_PRIVACY }}"
      >
        {{ shop.active_page_names[pageTypes.TYPE_PRIVACY] }}
      </router-link>
    </li>
    <li v-if="shop.active_page_types.includes(pageTypes.TYPE_CONTACTS)">
      <router-link
        :to="{ name: 'info', params: { type: pageTypes.TYPE_CONTACTS }}"
      >
        {{ shop.active_page_names[pageTypes.TYPE_CONTACTS] }}
      </router-link>
    </li>
    <li v-if="shop.active_page_types.includes(pageTypes.TYPE_RETURN)">
      <router-link
        :to="{ name: 'info', params: { type: pageTypes.TYPE_RETURN }}"
      >
        {{ shop.active_page_names[pageTypes.TYPE_RETURN] }}
      </router-link>
    </li>
    <li v-if="shop.active_page_types.includes(pageTypes.TYPE_PAYMENTS)">
      <router-link
        :to="{ name: 'info', params: { type: pageTypes.TYPE_PAYMENTS }}"
      >
        {{ shop.active_page_names[pageTypes.TYPE_PAYMENTS] }}
      </router-link>
    </li>
    <li v-if="shop.active_page_types.includes(pageTypes.TYPE_DELIVERY)">
      <router-link
        :to="{ name: 'info', params: { type: pageTypes.TYPE_DELIVERY }}"
      >
        {{ shop.active_page_names[pageTypes.TYPE_DELIVERY] }}
      </router-link>
    </li>
    <!--li v-if="shop.active_page_types.includes(pageTypes.TYPE_COOKIES)">
      <router-link
        :to="{ name: 'info', params: { type: pageTypes.TYPE_COOKIES }}"
      >
        Cookie-Einstellungen
      </router-link>
    </li-->
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import * as pageTypes from '../../../consts/page';

export default {
  components: {

  },
  data() {
    return {
      pageTypes,
    };
  },
  computed: {
    ...mapGetters({
      shop: 'shop/selected',
    }),
  },
  watch: {

  },
  methods: {

  },
  mounted() {

  },
};
</script>
