<template>
  <div class="checkout-steps">
    <router-link
      :class="isLoggedIn?'disabled-link':''"
      :to="{ name: 'checkout.anon' }"
    >
      <span class="num">1</span>
      <div class="text">{{ $t('cs.front.checkout_address') }}</div>
    </router-link>
    <i class="icon icon-arrow-right"></i>

    <!--router-link
      :to="{ name: 'checkout.address' }"
    >
      <span class="num">2</span>
      <div class="text">{{ $t('cs.front.payment_and_shipping') }}</div>
    </router-link-->

    <a href="#">
      <span class="num">2</span>
      <div class="text">{{ $t('cs.front.payment_and_shipping') }}</div>
    </a>

    <i class="icon icon-arrow-right"></i>

    <router-link
      :to="{ name: 'checkout.final-step' }"
    >
      <span class="num">3</span>
      <div class="text">{{ $t('cs.front.proof_and_order') }}</div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  props: {

  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      apiToken: 'auth/apiToken',
      me: 'profile/me',
    }),
    isLoggedIn() {
      return (this.apiToken && this.me && this.me.id);
    },
  },
  watch: {

  },
  methods: {

  },
  mounted() {

  },
};
</script>
